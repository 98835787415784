import React, { useContext } from 'react';
import ThemeContext from '../context/Theme';
import DataTable, { TableProps, createTheme } from 'react-data-table-component';
import { ActivityIndicator, View } from 'react-native';
import { translate } from '../services/translate';
import MyAppText from './MyAppText';
import getThemedColor from '../services/get-themed-color';

export default function MyDataTable<T>(props: TableProps<T> & { shortPadding?: boolean; }) {
    const { theme } = useContext(ThemeContext);
    createTheme('dark', {
        text: {
            primary: '#D3CFC9',
        },
        background: {
            default: '#181A1B',
        },
        context: {
            color: '#D3CFC9',
        }
    }, 'dark');

    return <DataTable
        {...props}
        fixedHeader={true}
        noDataComponent={<MyAppText style={{ color: getThemedColor(theme, '#58595B'), paddingTop: 10 }}>
            {translate('noResults')}.
        </MyAppText>}
        customStyles={{
            rows: {
                style: {
                    fontFamily: 'Open Sans',
                    fontSize: 14,
                },
            },
            headCells: {
                style: {
                    fontFamily: 'Open Sans',
                    fontSize: 13,
                    fontWeight: 'normal'
                },
            },
            cells: {
                style: {
                    paddingLeft: props.shortPadding ? 5 : 16,
                    paddingRight: props.shortPadding ? 5 : 16,
                }
            }
        }}
        progressComponent={<View style={{ paddingTop: 20 }}><ActivityIndicator size='small' color={getThemedColor(theme, '#000000')} /></View>}
        theme={theme}
    />;
}
