import React, { useContext, useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import { translate } from '../../services/translate';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import MyAppText from '../../components/MyAppText';
import FormInput from '../../components/formInput';
import MyPhoneInput from '../../components/MyPhoneInput';
import Toast from 'react-native-toast-message';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { centralAPI } from '../../services/central-api';
import { ClientError } from '../../services/central-api/base-service';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyCpfInput from '../../components/MyCpfInput';


export default function Account({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const { styles } = useStyles(stylesheet);
    const [isFormValid, setIsFormValid] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [registry, setRegistry] = useState('');
    const [phone, setPhone] = useState('');
    const [warName, setWarName] = useState('');
    const [document, setDocument] = useState('');
    const [isDocumentInvalid, setIsDocumentInvalid] = useState(false);

    async function getOwnUser() {
        try {
            const user = await centralAPI.getOwnUser({ includeRole: false });
            setName(user.name);
            setEmail(user.email);
            setRegistry(user.registry);
            setPhone(user.phone);
            setWarName(user.warName || '');
            setDocument(user.document || '');
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getOwnUser();
    }, []);

    useEffect(() => {
        if (name === '') {
            return setIsFormValid(false);
        } else if (registry === '') {
            return setIsFormValid(false);
        } else if (phone === '' || phone.length < 10) {
            return setIsFormValid(false);
        } else if (isDocumentInvalid || document === '') {
            return setIsFormValid(false);
        }
        return setIsFormValid(true);
    }, [name, registry, phone, document, isDocumentInvalid]);

    async function save() {
        try {
            await centralAPI.updateOwnUser({
                phone,
                warName,
                document
            });

            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='account' lateralMenu='user' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <ScrollView contentContainerStyle={styles.container}>
                    <View style={styles.header}>
                        <TouchableOpacity
                            onPress={save}
                            disabled={!isFormValid}
                            style={[{
                                borderWidth: 0,
                                borderRadius: 2,
                                backgroundColor: getThemedColor(theme, '#000028'),
                                width: 128,
                                height: 40,
                                justifyContent: 'center',
                            }, !isFormValid ? styles.buttonDisabled : null]}
                        >
                            <MyAppText style={[{ color: getThemedColor(theme, '#FFFFFF'), alignSelf: 'center', fontSize: 16 }, !isFormValid ? styles.textDisabled : null]}>
                                {translate('save')}
                            </MyAppText>
                        </TouchableOpacity>
                    </View>

                    <View style={styles.cardList}>
                        <View style={[styles.child, { rowGap: 15 }]}>
                            <FormInput
                                label={translate('name')}
                                placeholder={translate('name')}
                                value={name}
                                onChangeText={setName}
                                invalid={(value: string) => {
                                    return value === '';
                                }}
                                disabled={true}
                            />
                            <FormInput
                                label={translate('warName')}
                                placeholder={translate('warName')}
                                value={warName}
                                onChangeText={setWarName}
                                invalid={() => false}
                            />
                            <FormInput
                                label={translate('email')}
                                placeholder={translate('email')}
                                value={email}
                                onChangeText={setEmail}
                                invalid={() => false}
                                disabled={true}
                            />
                            <FormInput
                                label={translate('registration')}
                                placeholder={translate('registration')}
                                value={registry}
                                onChangeText={setRegistry}
                                invalid={(value: string) => {
                                    return value === '';
                                }}
                                disabled={true}
                            />
                            <MyCpfInput
                                value={document}
                                onChangeText={setDocument}
                                isInvalid={isDocumentInvalid}
                                setIsInvalid={setIsDocumentInvalid} />
                            <MyPhoneInput
                                label={translate('phone')}
                                placeholder={translate('phone')}
                                value={phone}
                                onChangeText={setPhone}
                                invalid={(value: string) => {
                                    return value === '' || value.length < 10;
                                }}
                            />
                        </View>

                    </View>
                </ScrollView>
            </DefaultPageLayout>
        </DefaultPageContainer>
    );
}

const stylesheet = createStyleSheet(theme => ({
    container: {
        flex: 1,
        rowGap: 15
    },

    header: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        columnGap: 10,
        minHeight: 65,
        justifyContent: 'flex-end',
    },

    cardList: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.borderColor,
        padding: 24,
        backgroundColor: theme.colors.backgroundColor,
        flexGrow: 1,
        flexDirection: {
            xs: 'column-reverse',
            md: 'row'
        },
    },

    child: {
        flex: {
            xs: undefined,
            md: 1
        }
    },

    buttonDisabled: {
        borderWidth: 0,
        backgroundColor: theme.colors.disabledButtonBackground
    },

    textDisabled: {
        color: theme.colors.disabledText
    },
}));

