import React, { useContext, useEffect, useRef, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, TextInput, ActivityIndicator, Modal, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { translate } from '../../services/translate';
import { Pagination } from '../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faDownload, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import moment from 'moment';
import MyDataTable from '../../components/MyDataTable';
import MyAppText from '../../components/MyAppText';
import Toast from 'react-native-toast-message';
import FormActions from '../../components/formActions';
import { crmService } from '../../services/central-api/crm';
import { WindowInformation } from '../../services/window-information';
import FormInput from '../../components/formInput';
import { licensePlateRecognitionService } from '../../services/central-api/license-plate-recognition';
import { RouteProp, useRoute } from '@react-navigation/native';
import { LPRParamList } from '../../typings/Params';
import MyDateTimeInput from '../../components/MyDatetimeInput';
import MyDropDownPicker from '../../components/MyDropDownPicker';


export default function Reports({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const route = useRoute<RouteProp<LPRParamList, 'Reports'>>();

    // filters
    const [filters, setFilters] = useState<{ page: number; textFilter?: string, limit: number; }>({
        page: Math.max(+route.params.page - 1, 0),
        textFilter: route.params.textFilter ?? '',
        limit: 25
    });

    const [pageCount, setPageCount] = useState(1);
    const [reports, setReports] = useState<Report[]>([]);
    const [page, setPage] = useState(filters.page);
    const [textFilter, setTextFilter] = useState(filters.textFilter);
    const [isDownloading, setIsDownloading] = useState(false);
    const loaders = useRef({ didPageLoad: false, didTextFilterLoad: false });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const windowInfo = WindowInformation();

    const [name, setName] = useState<string>('');
    const [plate, setPlate] = useState<string>('');
    const [startInDateFilter, setStartInDateFilter] = useState(moment(new Date().getTime()).format('DD/MM/YYYY'));
    const [startInHourFilter, setStartInHourFilter] = useState(moment(new Date().getTime()).format('HH:mm:ss'));
    const [endInDateFilter, setEndInDateFilter] = useState(moment(new Date().getTime()).format('DD/MM/YYYY'));
    const [endInHourFilter, setEndInHourFilter] = useState(moment(new Date().getTime()).format('HH:mm:ss'));
    const [isCameraPickerOpen, setIsCameraPickerOpen] = useState(false);
    const [selectedCameraFilter, setSelectedCameraFilter] = useState<string[]>([]);
    const [selectableCameraFilter, setSelectableCameraFilter] = useState<{ label: string, value: string; }[]>([]);

    useEffect(() => {
        if (!loaders.current.didPageLoad) {
            loaders.current.didPageLoad = true;
            return;
        }
        setFilters({ ...filters, page });
    }, [page]);

    async function getReports() {
        try {
            const response = await licensePlateRecognitionService.getReports(filters);
            setReports(response.rows);
            setPageCount(Math.ceil(response.count / filters.limit));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    async function getCameras() {
        try {
            const cameras = await licensePlateRecognitionService.getCameras();
            setSelectableCameraFilter(cameras.map((camera) => {
                return {
                    value: camera.id,
                    label: camera.title
                };
            }));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getCameras();
    }, []);

    useEffect(() => {
        getReports();
        const interval = setInterval(async () => {
            // idk why but this effect is being called even when accessing others pages
            // this is a guarantee that the interval will work only when the page is active
            if (!window.location.href.endsWith('LPR/reports')) {
                return;
            }
            getReports();
        }, 15000);

        return () => clearInterval(interval);
    }, [filters]);

    function isFormValid() {
        return name !== '' &&
            moment(`${startInDateFilter} ${startInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').valueOf() <= moment(`${endInDateFilter} ${endInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').valueOf()
            && selectedCameraFilter.length > 0;
    }

    function resetReportForm() {
        setName('');
        setPlate('');
        setSelectedCameraFilter([]);
    }

    function onCloseNewReportModal() {
        setModalVisible(false);
        resetReportForm();
    }

    function handleFilters() {
        setFilters({
            ...filters,
            page: 0,
            textFilter,
        });

        navigation.setParams({
            page: 1,
            textFilter,
        });
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            handleFilters();
        }
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='reports' lateralMenu='lpr' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ flexGrow: 1, minWidth: 350, maxWidth: 500 }}>
                            <MyAppText style={styles.filterText}>{translate('searchReport')}</MyAppText>
                            <TextInput
                                style={[styles.filterInput, {
                                    color: textFilter ? getThemedColor(theme, '#222222') : getThemedColor(theme, '#888888')
                                }]}
                                value={textFilter}
                                onChangeText={setTextFilter}
                                placeholder={translate('typeToSearch')}
                                onKeyPress={handleKeyDown}
                            />
                        </View>
                        <View style={styles.buttonsContainer}>
                            <TouchableOpacity style={styles.new} onPress={handleFilters}>
                                <FontAwesomeIcon icon={faSearch} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('toSearch')}</MyAppText>
                            </TouchableOpacity>

                            <TouchableOpacity onPress={() => setModalVisible(true)} style={styles.new}>
                                <FontAwesomeIcon icon={faDownload} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('detectionsReport')}</MyAppText>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.cardList}>
                        <MyDataTable
                            columns={[
                                {
                                    name: translate('name'),
                                    selector: row => row.name,
                                    wrap: true
                                },
                                {
                                    name: translate('user'),
                                    selector: row => row.user?.name || '',
                                    wrap: true
                                },
                                {
                                    name: 'Token',
                                    selector: row => row.token,
                                    wrap: true
                                },
                                {
                                    name: translate('status'),
                                    cell: row =>
                                        <View style={{ flexDirection: 'row', columnGap: 5, alignItems: 'center', width: '100%' }}>
                                            {row.status == 'pending' ?
                                                <ActivityIndicator size={16} color={getThemedColor(theme, '#000000')} /> :
                                                <FontAwesomeIcon style={row.status == 'success' ? styles.success : styles.failed} fontSize={16} icon={row.status == 'success' ? faCheckCircle : faTimesCircle}></FontAwesomeIcon>
                                            }

                                            <MyAppText style={row.status == 'success' ? styles.success : row.status == 'failed' ? styles.failed : {}}>{translate(row.status)}</MyAppText>
                                        </View>
                                },
                                {
                                    name: translate('filters'),
                                    wrap: true,
                                    cell: row => <View style={{ width: '100%' }}>
                                        {row.type == 'plate_detections' ?
                                            <View style={{ flexDirection: 'column', rowGap: 5 }}>
                                                <MyAppText>{translate('start')}: {moment(row.filters.startDate).format('DD/MM/YYYY HH:mm')}</MyAppText>
                                                <MyAppText>{translate('end')}: {moment(row.filters.endDate).format('DD/MM/YYYY  HH:mm')}</MyAppText>
                                                {row.filters.plate ? <MyAppText>{translate('plate')}: {row.filters.plate}</MyAppText> : undefined}
                                                {row.filters.camerasIds ? <MyAppText>{translate('cameras')}: {row.filters.camerasIds}</MyAppText> : undefined}
                                            </View> : <></>}
                                    </View>
                                },
                                {
                                    name: translate('createdAt'),
                                    selector: row => moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss'),
                                },
                                {
                                    name: translate('action'),
                                    button: true,
                                    cell: row =>
                                        <TouchableOpacity disabled={isDownloading} onPress={async () => {
                                            try {
                                                setIsDownloading(true);
                                                const video = await crmService.getReport(row.id);
                                                window.open(video.resultUrl);
                                            } catch (error) {
                                                Toast.show({
                                                    type: 'sentinelxError',
                                                    text1: translate('unexpectedError'),
                                                });
                                            } finally {
                                                setIsDownloading(false);
                                            }
                                        }}>
                                            {row.status === 'success' ? <FontAwesomeIcon fontSize={16} color={getThemedColor(theme, '#58595B')} icon={faDownload}></FontAwesomeIcon> : null}
                                        </TouchableOpacity>
                                }
                            ]}
                            data={reports}
                            progressPending={isLoading}
                        />
                    </View>
                    <Pagination currentPage={page} totalPages={pageCount} setPage={page => {
                        setPage(page);

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }} />
                </View>
                <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => onCloseNewReportModal()} >
                    <View style={styles.modalContainer}>
                        <View style={styles.centeredView}>
                            <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : {}]}>
                                <MyAppText style={{ fontSize: 22, fontWeight: 'bold', color: getThemedColor(theme, '#58595B') }}>{translate('occurrenceReport')}</MyAppText>
                                <MyAppText style={{ color: getThemedColor(theme, '#58595B'), fontSize: 12 }}>{translate('occurrenceReportInformation')}</MyAppText>
                                <FormInput label={translate('name')} invalid={() => false} value={name} onChangeText={setName} />
                                <FormInput label={translate('plate')} invalid={() => false} value={plate} onChangeText={setPlate} />
                                <View style={{ minHeight: 65, zIndex: 2 }}>
                                    <MyAppText style={styles.filterText}>{translate('cameras')}</MyAppText>
                                    <MyDropDownPicker
                                        open={isCameraPickerOpen}
                                        value={selectedCameraFilter}
                                        items={selectableCameraFilter}
                                        setOpen={setIsCameraPickerOpen}
                                        setValue={setSelectedCameraFilter}
                                        multiple={true}
                                        mode='BADGE'
                                        searchable={true}
                                        borderColor={getThemedColor(theme, '#888888')}
                                    />
                                </View>
                                <MyDateTimeInput
                                    label={translate('beginIn')}
                                    date={startInDateFilter}
                                    onChangeDate={setStartInDateFilter}
                                    time={startInHourFilter}
                                    onChangeTime={setStartInHourFilter} />
                                <MyDateTimeInput
                                    label={translate('endIn')}
                                    date={endInDateFilter}
                                    onChangeDate={setEndInDateFilter}
                                    time={endInHourFilter}
                                    onChangeTime={setEndInHourFilter} />
                                <FormActions
                                    onSubmit={async () => {
                                        try {
                                            await licensePlateRecognitionService.createReport({
                                                name: name,
                                                startDate: moment(`${startInDateFilter} ${startInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').toISOString(),
                                                endDate: moment(`${endInDateFilter} ${endInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').toISOString(),
                                                plate: plate,
                                                camerasIds: selectedCameraFilter
                                            });

                                            onCloseNewReportModal();

                                            Toast.show({
                                                type: 'sentinelxSuccess',
                                                text1: translate('ActionSuccessfully'),
                                            });
                                            await getReports();
                                        } catch (error) {
                                            Toast.show({
                                                type: 'sentinelxError',
                                                text1: translate('unexpectedError'),
                                            });
                                        }
                                    }}
                                    onClose={() => onCloseNewReportModal()}
                                    isLoading={isDownloading}
                                    disabled={!isFormValid()}
                                    confirmText={'download'} />
                            </View>
                        </View>
                    </View>
                </Modal>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            minHeight: 65,
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: 20
        },
        headerCell: {
            fontSize: 13,
            color: getThemedColor(theme, '#58595B')
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1
        },
        success: {
            color: '#017B27'
        },
        failed: {
            color: '#B40119'
        },
        filterText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
        },
        filterInput: {
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderWidth: 1,
            borderRadius: 4,
            borderColor: getThemedColor(theme, '#888888'),
            height: 40,
            minHeight: 40,
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            padding: 10
        },
        checkbox: {
            paddingHorizontal: 8,
            height: 40
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        formContainer: {
            minWidth: 350,
            maxWidth: 400,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 2,
            padding: 20,
            minHeight: 200,
            rowGap: 10
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            minHeight: 40,
            paddingHorizontal: 30,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            columnGap: 10
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
        },
        buttonsContainer: {
            justifyContent: 'flex-end',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
            marginTop: 20
        }
    });
}
