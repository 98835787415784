import OccurrenceTypesModal from './OccurrenceTypesModal';
import React, { useState, useEffect, useRef } from 'react';
import { View, TouchableOpacity, Modal, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { translate } from '../../../services/translate';
import DefaultPageLayout from '../../../components/DefaultPageLayout';
import DefaultPageContainer from '../../../components/DefaultPageContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan, faEdit } from '@fortawesome/free-solid-svg-icons';
import Toast from 'react-native-toast-message';
import DeleteModal from '../../../components/DeleteModal';
import { Pagination } from '../../../components/Pagination';
import { dispatchService } from '../../../services/central-api/dispatch';
import { ClientError } from '../../../services/central-api/base-service';
import { RouteProp, useRoute } from '@react-navigation/native';
import { DispatchParamList } from '../../../typings/Params';
import Filter from './Filters';
import useFilters from '../../../components/Filter/hooks/useFilters';
import FilterInput from '../../../components/Filter/components/FilterInput';
import MyDataTable from '../../../components/MyDataTable';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import CustomButton from '../../../components/CustomButton';

const limit = 25;

export default function OccurrenceTypes({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { styles, theme } = useStyles(styleSheet);
    const route = useRoute<RouteProp<DispatchParamList, 'OccurrenceTypes'>>();

    const [filters, setFilters] = useFilters<DispatchParamList['OccurrenceTypes']>({ ...route.params, page: Math.max(+route.params.page - 1, 0) });
    const [page, setPage] = useState(filters.page);
    const [textFilter, setTextFilter] = useState(filters.textFilter ?? '');

    const loaders = useRef({ didPageLoad: false, didTextFilterLoad: false });

    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [selectedOccurrenceType, setSelectedOccurrenceType] = useState<OccurrenceType>();
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [occurrenceTypes, setOccurrenceTypes] = useState<OccurrenceType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageCount, setPageCount] = useState(1);

    async function getOccurrenceTypes(): Promise<void> {
        try {
            setIsLoading(true);
            const response = await dispatchService.getOccurrenceTypes({
                ...filters, limit
            });
            setOccurrenceTypes(response.rows);
            setPageCount(Math.ceil(response.count / limit));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getOccurrenceTypes();
    }, [filters]);

    async function confirmDelete(occurrenceType: OccurrenceType) {
        try {
            if (occurrenceType?.id) {
                await dispatchService.deleteOccurrenceType(occurrenceType.id);
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });
                setDeleteModalVisible(false);
                getOccurrenceTypes();

            }
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    function handleFormClose() {
        setSelectedOccurrenceType(undefined);
        setModalVisible(false);
        getOccurrenceTypes();
    }

    useEffect(() => {
        if (!loaders.current.didPageLoad) {
            loaders.current.didPageLoad = true;
            return;
        }
        setFilters({ ...filters, page });
    }, [page]);

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            navigation.setParams({
                ...filters,
                textFilter
            });
            setFilters(old => ({
                ...old,
                textFilter
            }));
        }
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} lateralMenu={'dispatch'} selectedMenu='occurrence_types' contentContainerStyle={{ padding: 10 }} authenticatedUser={authenticatedUser}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ width: 500 }}>
                            <FilterInput
                                label={translate('searchOccurrenceType')}
                                value={textFilter}
                                placeholder={translate('typeToSearch')}
                                onChange={text => {
                                    setTextFilter(text);
                                }}
                                onKeyPress={handleKeyDown}
                            />
                        </View>
                        <View style={{ flex: 1 }}></View>
                        <View style={styles.buttonsContainer}>
                            <Filter
                                authenticatedUser={authenticatedUser}
                                filters={filters}
                                setFilters={setFilters}
                                textFilter={textFilter}
                                setTextFilter={setTextFilter} />

                            <CustomButton icon={faPlus} text={translate('create')} onPress={() => {
                                setSelectedOccurrenceType(undefined);
                                setModalVisible(true);
                            }} />
                        </View>
                    </View>
                    <View style={styles.cardList}>
                        <MyDataTable
                            columns={[
                                {
                                    name: 'ID',
                                    selector: row => row.id,
                                    grow: 0
                                },
                                {
                                    name: translate('name'),
                                    selector: row => row.name,
                                    wrap: true,
                                    grow: 2
                                },
                                {
                                    name: translate('actingBody'),
                                    selector: row => row.actingBody.name,
                                    wrap: true,
                                },
                                {
                                    name: translate('actions'),
                                    cell: row => (
                                        <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                            <TouchableOpacity onPress={() => {
                                                setSelectedOccurrenceType(row);
                                                setModalVisible(true);
                                            }}>
                                                <FontAwesomeIcon icon={faEdit} fontSize={16} color={theme.colors.iconColor} />
                                            </TouchableOpacity>
                                            <TouchableOpacity onPress={() => {
                                                setSelectedOccurrenceType(row);
                                                setDeleteModalVisible(true);
                                            }}>
                                                <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={theme.colors.iconColor} />
                                            </TouchableOpacity>
                                        </View>
                                    ),
                                    wrap: true,
                                    button: true
                                }
                            ]}
                            data={occurrenceTypes}
                            progressPending={isLoading}
                        />
                    </View>
                    <Pagination currentPage={page} totalPages={pageCount} setPage={page => {
                        setPage(page);

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }} />

                    <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)}>
                        <View style={styles.modalContainer}>
                            <View style={styles.centeredView}>
                                <OccurrenceTypesModal
                                    onClose={handleFormClose}
                                    occurrenceType={selectedOccurrenceType}
                                    authenticatedUser={authenticatedUser}
                                />
                            </View>
                        </View>
                    </Modal>

                    <DeleteModal
                        setModalVisible={setDeleteModalVisible}
                        isModalVisible={isDeleteModalVisible}
                        itemName={String(selectedOccurrenceType?.name) || ''}
                        onSubmit={async () => {
                            if (selectedOccurrenceType) {
                                await confirmDelete(selectedOccurrenceType);
                            }
                        }}
                    />

                </View>
            </DefaultPageLayout>
        </DefaultPageContainer>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'space-between'
    },
    cardList: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.borderColor,
        backgroundColor: theme.colors.backgroundColor,
        paddingHorizontal: 10,
        flex: 1
    },
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199'
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
}));
