import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import DefaultPageContainer from '../../../components/DefaultPageContainer';
import DefaultPageLayout from '../../../components/DefaultPageLayout';
import { useEffect, useState } from 'react';
import MyDataTable from '../../../components/MyDataTable';
import { PaginatedResource } from '../../../typings/Paginated';
import { translate } from '../../../services/translate';
import { Pagination } from '../../../components/Pagination';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import Filters from './Filters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { RouteProp, useRoute } from '@react-navigation/native';
import { GuardianAppParamList } from '../../../typings/Params';
import { guardianAppService } from '../../../services/central-api/guardian-app';
import EditModal from './EditModal';
import DeleteModal from '../../../components/DeleteModal';
import Toast from 'react-native-toast-message';
import { ClientError, GenericPaginatedParameters } from '../../../services/central-api/base-service';
import { TextMask } from 'react-native-masked-text';

interface Props {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}

const limit = 25;

function GuardianAppUsers({ navigation, authenticatedUser }: Props) {
    const { styles, theme } = useStyles(styleSheet);

    const [data, setData] = useState<PaginatedResource<GuardianWoman>>({
        count: 0,
        rows: []
    });

    const [page, setPage] = useState(0);

    const [isLoading, setIsLoading] = useState(true);
    const [pageTotal, setPageTotal] = useState(0);

    const [selectedGuardianWoman, setSelectedGuardianWoman] = useState<number | null>(null);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [selectedGuardianWomanToDelete, setSelectedGuardianWomanToDelete] = useState<GuardianWoman | null>(null);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

    const route = useRoute<RouteProp<GuardianAppParamList, 'Users'>>();

    const [filterValues, setFilterValues] = useState<GenericPaginatedParameters>({
        textFilter: route.params?.textFilter,
        limit,
        page
    });

    const getGuardianWoman = async (filters: GenericPaginatedParameters) => {
        try {
            setIsLoading(true);
            const data = await guardianAppService.getPaginatedGuardianWoman(filters);
            setPageTotal(Math.ceil(data.count / limit));
            setData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    async function confirmDelete(guardianWoman: GuardianWoman) {
        try {
            await guardianAppService.deleteGuardianWoman(guardianWoman.id);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            setDeleteModalVisible(false);
            getGuardianWoman(filterValues);
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setSelectedGuardianWoman(null);
        }
    }

    const handleOnFilter = (filters: Omit<GenericPaginatedParameters, 'limit' | 'page'>) => {
        setFilterValues({ ...filters, limit, page });
        navigation.setParams({
            ...filters, page
        });
    };

    useEffect(() => {
        getGuardianWoman(filterValues);
    }, [filterValues]);

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='users' lateralMenu='guardianApp' contentContainerStyle={{ padding: 10, rowGap: 15 }} authenticatedUser={authenticatedUser}>
                <View style={styles.header}>
                    <Filters onCreate={() => setModalVisible(true)} onFilter={handleOnFilter} filterValues={filterValues} />
                </View>

                <View style={styles.cardList}>
                    <MyDataTable
                        progressPending={isLoading}
                        columns={[
                            {
                                name: 'ID',
                                selector: row => row.id,
                                grow: 0
                            },
                            {
                                name: translate('phone'),
                                cell: row => <View style={{ width: '100%' }}>
                                    <TextMask
                                        style={{ color: theme.colors.textColor, fontFamily: 'Open Sans' }}
                                        type={'cel-phone'}
                                        value={row.phone} />
                                </View>,
                                wrap: true,
                                minWidth: '150px',
                                maxWidth: '150px'
                            },
                            {
                                name: translate('name'),
                                selector: row => row.name,
                                wrap: true,
                            },
                            {
                                name: translate('address'),
                                selector: row => row.address,
                                wrap: true,
                            },
                            {
                                name: translate('actions'),
                                cell: row => (
                                    <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                        <TouchableOpacity onPress={() => {
                                            setSelectedGuardianWoman(row.id);
                                            setModalVisible(true);
                                        }}>
                                            <FontAwesomeIcon icon={faEdit} fontSize={16} color={theme.colors.iconColor} />
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => {
                                            setSelectedGuardianWomanToDelete(row);
                                            setDeleteModalVisible(true);
                                        }}>
                                            <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={theme.colors.iconColor} />
                                        </TouchableOpacity>
                                    </View>
                                ),
                                wrap: true,
                                button: true
                            }
                        ]}
                        data={data.rows}
                    />
                </View>

                <EditModal
                    guardianWomanId={selectedGuardianWoman}
                    isModalVisible={isModalVisible}
                    setModalVisible={setModalVisible}
                    onSubmit={async () => {
                        await getGuardianWoman(filterValues);
                    }}
                    onClose={() => {
                        setSelectedGuardianWoman(null);
                    }}
                />

                <DeleteModal
                    setModalVisible={setDeleteModalVisible}
                    isModalVisible={isDeleteModalVisible}
                    itemName={selectedGuardianWomanToDelete?.name || ''}
                    onSubmit={async () => {
                        if (selectedGuardianWomanToDelete) {
                            await confirmDelete(selectedGuardianWomanToDelete);
                        }
                    }}
                />

                <Pagination currentPage={page} totalPages={pageTotal} setPage={page => {
                    setPage(page);

                    navigation.setParams({
                        ...route.params,
                        page: page + 1
                    });
                }}
                />
            </DefaultPageLayout>
        </DefaultPageContainer>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    header: {
        zIndex: 2,
        minHeight: 65,
    },
    cardList: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.borderColor,
        backgroundColor: theme.colors.backgroundColor,
        paddingHorizontal: 10,
        flex: 1
    },
}));

export default GuardianAppUsers;
