import React from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { DispatchParamList } from '../../../typings/Params';
import Filters from '../../../components/Filter';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { translate } from '../../../services/translate';
import { useState } from 'react';
import TypeDropdown from './TypeDropdown';
import PriorityDropdown from './PriorityDropdown';
import StatusDropdown from './StatusDropdown';
import OccurrenceTypeDropdown from './OccurrenceTypeDropdown';
import UnitsDropdown from './UnitsDropdown';
import TagsDropdown from './TagsDropdown';
import ActingBodiesDropdown from './ActingBodiesDropdown';
import GenericUserDropdown from '../../../components/Dropdowns/GenericUserDropdown';

interface Props {
    filters: DispatchParamList['Occurrences'];
    setFilters: React.Dispatch<React.SetStateAction<DispatchParamList['Occurrences']>>;
    textFilter: string;
    setTextFilter: React.Dispatch<React.SetStateAction<string>>;
    authenticatedUser?: AuthenticatedUser;
}

function OccurrencesFilter({ filters, setFilters, textFilter, setTextFilter, authenticatedUser }: Props) {
    const navigation = useNavigation<NavigationProp<DispatchParamList, 'Occurrences'>>();

    const [selectedTriggerTypeFilter, setSelectedTriggerTypeFilter] = useState<TriggerType | 'all' | 'manual'>(filters.triggerType ?? 'all');
    const [selectedPriorityFilter, setSelectedPriorityFilter] = useState<OccurrencePriorities | 'all'>(filters.priority ?? 'all');
    const [selectedSituationFilter, setSelectedSituationFilter] = useState<OccurrenceSituation | 'all'>(filters.situation ?? 'all');
    const [selectedOccurrenceTypeFilter, setSelectedOccurrenceTypeFilter] = useState<number>(filters.occurrenceTypeId ?? 0);
    const [selectedUnitFilter, setSelectedUnitFilter] = useState<number>(filters.unitId ?? 0);
    const [selectedTagsIds, setSelectedTagsIds] = useState<number[]>(filters.tags ?? []);
    const [selectedActingBodyId, setSelectedActingBodyId] = useState<number>(filters.actingBodyId ?? 0);
    const [selectedAssumedBy, setSelectedAssumedBy] = useState<number>(filters.assumedBy ?? 0);
    const [selectedTransferredBy, setSelectedTransferredBy] = useState<number>(filters.transferredBy ?? 0);
    const [selectedReceivedBy, setSelectedReceivedBy] = useState<number>(filters.receivedBy ?? 0);
    const [selectedFinishedBy, setSelectedFinishedBy] = useState<number>(filters.finishedBy ?? 0);

    const handleCleanFilter = () => {
        setTextFilter('');
        setSelectedOccurrenceTypeFilter(0);
        setSelectedActingBodyId(0);
        setSelectedAssumedBy(0);
        setSelectedTransferredBy(0);
        setSelectedReceivedBy(0);
        setSelectedFinishedBy(0);
        setSelectedPriorityFilter('all');
        setSelectedSituationFilter('all');
        setSelectedTriggerTypeFilter('all');
        setSelectedUnitFilter(0);
        setSelectedTagsIds([]);
    };

    const handleFilter = () => {
        setFilters({
            ...filters,
            situation: selectedSituationFilter !== 'all' ? selectedSituationFilter : undefined,
            priority: selectedPriorityFilter !== 'all' ? selectedPriorityFilter : undefined,
            triggerType: selectedTriggerTypeFilter !== 'all' ? selectedTriggerTypeFilter : undefined,
            occurrenceTypeId: selectedOccurrenceTypeFilter !== 0 ? selectedOccurrenceTypeFilter : undefined,
            unitId: selectedUnitFilter !== 0 ? selectedUnitFilter : undefined,
            textFilter: textFilter,
            page: 0,
            tags: selectedTagsIds,
            actingBodyId: selectedActingBodyId !== 0 ? selectedActingBodyId : undefined,
            assumedBy: selectedAssumedBy !== 0 ? selectedAssumedBy : undefined,
            transferredBy: selectedTransferredBy !== 0 ? selectedTransferredBy : undefined,
            receivedBy: selectedReceivedBy !== 0 ? selectedReceivedBy : undefined,
            finishedBy: selectedFinishedBy !== 0 ? selectedFinishedBy : undefined
        });

        navigation.setParams({
            situation: selectedSituationFilter !== 'all' ? selectedSituationFilter : undefined,
            priority: selectedPriorityFilter !== 'all' ? selectedPriorityFilter : undefined,
            triggerType: selectedTriggerTypeFilter !== 'all' ? selectedTriggerTypeFilter : undefined,
            occurrenceTypeId: selectedOccurrenceTypeFilter !== 0 ? selectedOccurrenceTypeFilter : undefined,
            unitId: selectedUnitFilter !== 0 ? selectedUnitFilter : undefined,
            textFilter: textFilter,
            page: 1,
            tags: selectedTagsIds,
            actingBodyId: selectedActingBodyId !== 0 ? selectedActingBodyId : undefined,
            assumedBy: selectedAssumedBy !== 0 ? selectedAssumedBy : undefined,
            transferredBy: selectedTransferredBy !== 0 ? selectedTransferredBy : undefined,
            receivedBy: selectedReceivedBy !== 0 ? selectedReceivedBy : undefined,
            finishedBy: selectedFinishedBy !== 0 ? selectedFinishedBy : undefined
        });

    };

    function getActingBodyId() {
        if (!authenticatedUser || !authenticatedUser.actingBodyUnit.actingBodyId) {
            return 0;
        }

        if (authenticatedUser.isAdmin || authenticatedUser.permissions.dispatch_manage_all_acting_bodies_occurrences) {
            return selectedActingBodyId;
        } else {
            return authenticatedUser.actingBodyUnit.actingBodyId;
        }
    }

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <FilterInput
                label={translate('searchOccurrence')}
                placeholder={translate('typeToSearch')}
                value={textFilter}
                onChange={text => {
                    setTextFilter(text);
                }}
            />

            <TypeDropdown value={selectedTriggerTypeFilter} setValue={setSelectedTriggerTypeFilter} zIndex={12} />

            <PriorityDropdown value={selectedPriorityFilter} setValue={setSelectedPriorityFilter} zIndex={11} />

            <StatusDropdown value={selectedSituationFilter} setValue={setSelectedSituationFilter} zIndex={10} />

            <OccurrenceTypeDropdown value={selectedOccurrenceTypeFilter} setValue={setSelectedOccurrenceTypeFilter} zIndex={9} />

            <UnitsDropdown value={selectedUnitFilter} setValue={setSelectedUnitFilter} zIndex={8} />

            <TagsDropdown value={selectedTagsIds} setValue={setSelectedTagsIds} zIndex={7} />

            {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_occurrences) &&
                <ActingBodiesDropdown value={selectedActingBodyId} setValue={setSelectedActingBodyId} zIndex={6} />
            }
            <GenericUserDropdown
                value={selectedAssumedBy}
                setValue={setSelectedAssumedBy}
                actingBodyId={getActingBodyId()}
                zIndex={5}
                label='assumedBy' />
            <GenericUserDropdown
                value={selectedTransferredBy}
                setValue={setSelectedTransferredBy}
                actingBodyId={getActingBodyId()}
                zIndex={4}
                label='transferredBy' />
            <GenericUserDropdown
                value={selectedReceivedBy}
                setValue={setSelectedReceivedBy}
                actingBodyId={getActingBodyId()}
                zIndex={3}
                label='receivedBy' />
            <GenericUserDropdown
                value={selectedFinishedBy}
                setValue={setSelectedFinishedBy}
                actingBodyId={getActingBodyId()}
                zIndex={2}
                label='finishedBy' />
        </Filters>
    );
}

export default OccurrencesFilter;
