import React from 'react';
import LateralMenu from '../LateralMenu';
import { faHomeLg } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';
import logo from '../../../assets/logo_dashboard.png';
import collapsedLogo from '../../../assets/dashboard_icon.png';

export type SelectedMenu = 'dashboard';

interface DashboardLateralMenuProps extends LateralMenuProps {
    selectedMenu: SelectedMenu;
}

export default function DashboardLateralMenu({ navigation, selectedMenu, open, authenticatedUser, setOpen }: DashboardLateralMenuProps) {
    return (
        <LateralMenu
            topImage={logo}
            collapsedTopImage={collapsedLogo}
            open={open}
            setOpen={setOpen}
            authenticatedUser={authenticatedUser}
            items={[{
                icon: faHomeLg,
                text: translate('general_vision'),
                action: () => navigation.navigate('Dashboard', { screen: 'Dashboard' }),
                selected: selectedMenu == 'dashboard'
            }]}
            goBack={() => navigation.navigate('Menu')} />
    );
}
