import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Modal } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import MyAppText from '../../../components/MyAppText';
import { dispatchService } from '../../../services/central-api/dispatch';
import { WindowInformation } from '../../../services/window-information';
import Toast from 'react-native-toast-message';
import { Tabs } from '../../../components/Tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import MyDataTable from '../../../components/MyDataTable';
import { centralAPI } from '../../../services/central-api';
import MyDateTimeInput from '../../../components/MyDatetimeInput';
import moment from 'moment';
import { ScrollView } from 'react-native-web-hover';
import UnitNamesDropdown from './UnitNamesDropdown';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import ActingBodiesDropdown from './ActingBodiesDropdown';
import EquipmentsDropdown from './EquipmentDropDown';
import AgentsDropdown from './AgentsDropDown';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';
import ActingBodiesUnitDropdown from './ActingBodyUnitDropdown';
import ActingBodiesCommandDropdown from './ActingBodiesCommandDropdown';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onClose: () => void;
    unitId?: number;
    authenticatedUser?: AuthenticatedUser;
}

export default function UnitsModal({ onClose, unitId, authenticatedUser, isModalVisible, setModalVisible }: ModalProps) {
    const { styles, theme } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [unitNameId, setUnitNameId] = useState<number>(0);
    const [description, setDescription] = useState<string>('');
    const [actingBodyId, setActingBodyId] = useState<number>(0);

    const [unit, setUnit] = useState<Unit>();
    const [agentsSelected, setAgentsSelected] = useState<number[]>([]);
    const [selectableAgents, setSelectableAgents] = useState<{ label: string, value: number; }[]>([]);

    const [equipmentsSelected, setEquipmentsSelected] = useState<number[]>([]);

    const [isAssignmentModalVisible, setIsAssignmentModalVisible] = useState(false);
    const [selectableAssignments, setSelectableAssignments] = useState<{ label: string, value: number; }[]>([]);

    const [currentAgentAssignment, setCurrentAgentAssignment] = useState<number>(0);
    const [modifiedAgentAssignments, setModifiedAgentAssignments] = useState<{ [agentId: number]: number; }>({});
    const [modalSaveDisabled, setModalSaveDisabled] = useState(true);
    const [selectedAssignment, setSelectedAssignment] = useState<number>(0);

    const [startInDateFilter, setStartInDateFilter] = useState(moment(new Date().getTime()).format('DD/MM/YYYY'));
    const [startInHourFilter, setStartInHourFilter] = useState(moment(new Date().getTime()).format('HH:mm'));
    const [finishInDateFilter, setFinishInDateFilter] = useState(moment(new Date().getTime()).add(12, 'hours').format('DD/MM/YYYY'));
    const [finishInHourFilter, setFinishInHourFilter] = useState(moment(new Date().getTime()).add(12, 'hours').format('HH:mm'));

    const [actingBodyUnitId, setActingBodyUnitId] = useState<number>(0);
    const [actingBodyCommandId, setActingBodyCommandId] = useState<number>(0);

    function cleanValues() {
        setUnit(undefined);
        setStartInDateFilter(moment(new Date().getTime()).format('DD/MM/YYYY'));
        setStartInHourFilter(moment(new Date().getTime()).format('HH:mm'));
        setFinishInDateFilter(moment(new Date().getTime()).add(12, 'hours').format('DD/MM/YYYY'));
        setFinishInHourFilter(moment(new Date().getTime()).add(12, 'hours').format('HH:mm'));
        setUnitNameId(0);
        setDescription('');
        setActingBodyId(0);
        setActingBodyUnitId(0);
        setAgentsSelected([]);
        setEquipmentsSelected([]);
        setCurrentAgentAssignment(0);
        setModifiedAgentAssignments([]);
        setSelectedAssignment(0);
        setSelectableAssignments([]);
    }

    useEffect(() => {
        setSelectedAssignment(0);
        setModalSaveDisabled(true);
    }, [isAssignmentModalVisible]);

    useEffect(() => {
        if (selectedAssignment) {
            setModalSaveDisabled(false);
        }
    }, [selectedAssignment]);

    async function getAssignments(actingBodyId: number) {
        try {
            const assignments = await dispatchService.getSimplifiedAssignments(actingBodyId);
            setSelectableAssignments([
                ...assignments.map((assignment) => {
                    return {
                        value: assignment.id,
                        label: assignment.name
                    };
                })
            ]);
        } catch (err) {
            console.error(err);
        }
    }

    function isFormValid(): boolean {

        if (!unitId && (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_acting_body_units) && !actingBodyUnitId) {
            return false;
        }
        const start = moment(`${startInDateFilter} ${startInHourFilter}`, 'DD/MM/YYYY HH:mm');
        const finish = moment(`${finishInDateFilter} ${finishInHourFilter}`, 'DD/MM/YYYY HH:mm');
        return actingBodyCommandId !== 0 && unitNameId !== 0 && start.valueOf() <= finish.valueOf() && moment.duration(finish.diff(start)).asHours() >= 8;
    }

    async function formSubmit() {
        try {
            if (unitId) {
                await dispatchService.updateUnit({
                    unitId: unitId,
                    unitNameId: unitNameId,
                    actingBodyCommandId,
                    description,
                    usersToSet: agentsSelected,
                    equipmentsToSet: equipmentsSelected,
                    vehiclesToSet: [],
                    modifiedAgentAssignments,
                    estimatedStart: moment(`${startInDateFilter} ${startInHourFilter}`, 'DD/MM/YYYY HH:mm').toLocaleString(),
                    estimatedFinish: moment(`${finishInDateFilter} ${finishInHourFilter}`, 'DD/MM/YYYY HH:mm').toLocaleString()
                });
            } else {
                await dispatchService.createUnit({
                    unitNameId,
                    actingBodyUnitId,
                    actingBodyCommandId,
                    description,
                    usersToSet: agentsSelected,
                    equipmentsToSet: equipmentsSelected,
                    vehiclesToSet: [],
                    modifiedAgentAssignments,
                    estimatedStart: moment(`${startInDateFilter} ${startInHourFilter}`, 'DD/MM/YYYY HH:mm').toLocaleString(),
                    estimatedFinish: moment(`${finishInDateFilter} ${finishInHourFilter}`, 'DD/MM/YYYY HH:mm').toLocaleString()
                });
            }
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            onClose();
            cleanValues();
        } catch (err) {
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    async function getOwnUser() {
        try {
            const ownUser = await centralAPI.getOwnUser({ includeRole: true });
            return ownUser;
        } catch (err) {
            console.error(err);
        }
    }

    async function loadPageInfo() {
        try {
            cleanValues();
            const ownUser = await getOwnUser();
            if (unitId) {
                const unit = await dispatchService.getUnit(unitId);
                setUnit(unit);
                setStartInDateFilter(moment(unit.estimatedStart).format('DD/MM/YYYY'));
                setStartInHourFilter(moment(unit.estimatedStart).format('HH:mm'));
                setFinishInDateFilter(moment(unit.estimatedFinish).format('DD/MM/YYYY'));
                setFinishInHourFilter(moment(unit.estimatedFinish).format('HH:mm'));
                setUnitNameId(unit.unitNameId);
                setDescription(unit.description || '');
                setActingBodyId(unit.actingBodyUnit?.actingBodyId || 0);
                setActingBodyUnitId(unit.actingBodyUnitId || 0);
                setActingBodyCommandId(unit.actingBodyCommandId || 0);
                setEquipmentsSelected(unit.equipments?.map(e => e.id) || []);
                setAgentsSelected(unit.UnitUsers?.map(uu => uu.id) || []);
            } else if (!ownUser?.isAdmin && ownUser?.role?.actingBody?.id) {
                setActingBodyId(ownUser.role.actingBody.id);
            }

        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (actingBodyId) {
            getAssignments(actingBodyId);
        }
    }, [actingBodyId]);

    useEffect(() => {
        if (actingBodyId) {
            getAssignments(actingBodyId);
        }
    }, [actingBodyId]);

    useEffect(() => {
        if (isModalVisible) {
            loadPageInfo();
        }
    }, [isModalVisible]);

    return (
        <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)}>
            <View style={styles.container}>
                <View style={[styles.formContainer, { maxHeight: 690 }, windowInfo.isMobile ? { width: '95%' } : { width: '50%' }]}>
                    <ScrollView>
                        <Tabs tabs={[{
                            key: 'details',
                            label: translate('details')
                        }, {
                            key: 'agents',
                            label: translate('agents')
                        }]}>
                            {({ selectedTab }) => {
                                if (selectedTab === 'details') {
                                    return (
                                        <View style={{ rowGap: 10, flex: 1 }}>
                                            {authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_acting_body_units ?
                                                <View style={{ flexDirection: 'row', gap: 16, zIndex: 4 }}>
                                                    <View style={{ flex: 1, zIndex: 5 }}>
                                                        <ActingBodiesDropdown
                                                            value={actingBodyId}
                                                            setValue={setActingBodyId}
                                                            includeAllOption={false}
                                                            disabled={!!unitId || !authenticatedUser?.isAdmin}
                                                            zIndex={5} />
                                                    </View>
                                                    <View style={{ flex: 1, zIndex: 4 }}>
                                                        <ActingBodiesUnitDropdown
                                                            value={actingBodyUnitId}
                                                            actingBodyId={actingBodyId}
                                                            setValue={setActingBodyUnitId}
                                                            disabled={!!unitId}
                                                            zIndex={4}
                                                        />
                                                    </View>
                                                </View>
                                                : null
                                            }
                                            <ActingBodiesCommandDropdown
                                                value={actingBodyCommandId}
                                                actingBodyId={actingBodyId}
                                                setValue={setActingBodyCommandId}
                                                disabled={false}
                                                zIndex={3}
                                            />
                                            <UnitNamesDropdown
                                                authenticatedUser={authenticatedUser}
                                                value={unitNameId}
                                                setValue={setUnitNameId}
                                                actingBodyId={actingBodyId}
                                                disabled={!!unitId}
                                                zIndex={2}
                                            />
                                            <View style={{ minWidth: 250, flexDirection: 'row', gap: 20, flexWrap: 'wrap' }}>
                                                <MyDateTimeInput
                                                    label={translate('estimatedStart')}
                                                    date={startInDateFilter}
                                                    onChangeDate={setStartInDateFilter}
                                                    time={startInHourFilter}
                                                    onChangeTime={setStartInHourFilter} />
                                                <MyDateTimeInput
                                                    label={translate('estimatedFinish')}
                                                    date={finishInDateFilter}
                                                    onChangeDate={setFinishInDateFilter}
                                                    time={finishInHourFilter}
                                                    onChangeTime={setFinishInHourFilter} />
                                            </View>
                                            <FormInput
                                                label={translate('description')}
                                                placeholder={translate('description')}
                                                value={description}
                                                onChangeText={setDescription}
                                                multiline={true}
                                                numberOfLines={5}
                                                invalid={() => false}
                                                viewStyle={{ flex: 1 }}
                                            />
                                            <EquipmentsDropdown
                                                value={equipmentsSelected}
                                                setValue={setEquipmentsSelected}
                                                actingBodyId={actingBodyId}
                                                disabled={false}
                                                zIndex={1}
                                            />
                                        </View>
                                    );
                                } else if (selectedTab === 'agents') {
                                    return (
                                        <View style={{ rowGap: 5, flex: 1, minWidth: 250 }}>
                                            <AgentsDropdown
                                                value={agentsSelected}
                                                setValue={setAgentsSelected}
                                                actingBodyId={actingBodyId}
                                                disabled={false}
                                                zIndex={2}
                                                selectableAgents={selectableAgents}
                                                setSelectableAgents={setSelectableAgents}
                                            />
                                            <MyDataTable
                                                columns={[
                                                    {
                                                        name: translate('name'),
                                                        cell: row => {
                                                            const agent = selectableAgents.find(agent => agent.value == row);
                                                            return <View style={{ width: '100%' }}>
                                                                <MyAppText >{agent?.label}</MyAppText>
                                                            </View>;
                                                        },
                                                        grow: 2,
                                                        wrap: true
                                                    },
                                                    {
                                                        grow: 2,
                                                        name: translate('assignment'),
                                                        cell: row => {
                                                            const defaultButton = <TouchableOpacity onPress={() => {
                                                                setCurrentAgentAssignment(row);
                                                                setIsAssignmentModalVisible(true);
                                                            }} style={{ width: '100%' }}>
                                                                <MyAppText style={{
                                                                    textDecorationLine: 'underline',
                                                                    color: theme.colors.labelColor,
                                                                    textDecorationColor: theme.colors.labelColor,
                                                                }}>{translate('selectAssignment')}</MyAppText>
                                                            </TouchableOpacity>;

                                                            // first of all check if there's already a modification on user assignment on the page
                                                            if (row in modifiedAgentAssignments) {
                                                                const assignment = selectableAssignments.find((assignment) => assignment.value == modifiedAgentAssignments[row]);

                                                                if (!assignment?.label) {
                                                                    return defaultButton;
                                                                }

                                                                return <TouchableOpacity onPress={() => {
                                                                    setCurrentAgentAssignment(row);
                                                                    setIsAssignmentModalVisible(true);
                                                                }}>
                                                                    <MyAppText style={{ textDecorationLine: 'underline' }}>{assignment.label}</MyAppText>
                                                                </TouchableOpacity>;
                                                            }

                                                            // then try to get the current database information
                                                            if (!unit?.UnitUsers) {
                                                                return defaultButton;
                                                            }
                                                            const unitUser = unit.UnitUsers.find((unitUser) => unitUser.id == row);

                                                            if (!unitUser) {
                                                                return defaultButton;
                                                            }

                                                            const assignment = selectableAssignments.find((assignment) => assignment.value == unitUser.UnitUser.assignmentId);

                                                            if (!assignment?.label) {
                                                                return defaultButton;
                                                            }

                                                            return <TouchableOpacity onPress={() => {
                                                                setCurrentAgentAssignment(row);
                                                                setModalVisible(true);
                                                            }} style={{ width: '100%' }}>
                                                                <MyAppText style={{ textDecorationLine: 'underline' }}>{assignment.label}</MyAppText>
                                                            </TouchableOpacity>;
                                                        },
                                                    },
                                                    {
                                                        name: translate('action'),
                                                        button: true,
                                                        grow: 0,
                                                        cell: row =>
                                                            <View>
                                                                <TouchableOpacity onPress={() => {
                                                                    const idx = agentsSelected.findIndex((el) => el == row);
                                                                    agentsSelected.splice(idx, 1);
                                                                    setAgentsSelected([...agentsSelected]);
                                                                }}>
                                                                    <FontAwesomeIcon fontSize={20} icon={faTrashCan} color={theme.colors.iconColor} />
                                                                </TouchableOpacity>
                                                            </View>
                                                    }
                                                ]}
                                                data={agentsSelected}
                                            />
                                        </View>
                                    );
                                }
                            }}
                        </Tabs>
                    </ScrollView>
                    <FormActions
                        onSubmit={formSubmit}
                        onClose={() => {
                            onClose();
                            cleanValues();
                        }}
                        disabled={!isFormValid()} />

                    <Modal transparent={true} visible={isAssignmentModalVisible} animationType='fade' onRequestClose={() => setIsAssignmentModalVisible(false)}>
                        <View style={styles.container}>
                            <View style={[styles.formContainer, { maxHeight: 160 }]}>
                                <View style={{ rowGap: 5, flex: 1 }}>
                                    <FilterDropdown
                                        label={translate('assignments')}
                                        items={selectableAssignments}
                                        multiple={false}
                                        setValue={setSelectedAssignment}
                                        value={selectedAssignment}
                                        disabled={false}
                                        zIndex={2}
                                    />
                                </View>
                                <FormActions
                                    onSubmit={() => {
                                        if (selectedAssignment) {
                                            setModifiedAgentAssignments({ ...modifiedAgentAssignments, [currentAgentAssignment]: selectedAssignment });
                                        }
                                        setIsAssignmentModalVisible(false);
                                    }}
                                    onClose={() => setIsAssignmentModalVisible(false)}
                                    disabled={modalSaveDisabled}
                                    confirmText='select' />
                            </View>
                        </View>
                    </Modal>
                </View >
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        backgroundColor: theme.colors.backgroundColor,
        borderRadius: 4,
        padding: 20,
        flex: 1,
        height: 690
    },
    label: {
        color: theme.colors.labelColor
    },
    button: {
        borderRadius: 4,
        borderWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 120,
        height: 40,
        backgroundColor: theme.colors.buttonBackground,
    },
    buttonClose: {
        borderColor: theme.colors.borderColor,
        backgroundColor: theme.colors.backgroundColor,
    },
    buttonTextClose: {
        fontSize: 16,
        color: theme.colors.labelColor,
    },
    buttonSave: {
        backgroundColor: theme.colors.buttonBackground,
    },
    buttonTextSave: {
        fontSize: 16,
        color: theme.colors.backgroundColor,
    },
    disabled: {
        borderWidth: 0,
        backgroundColor: theme.colors.fieldBorderColor
    },
    textDisabled: {
        color: theme.colors.disabledText
    },
}));
