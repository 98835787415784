import React from 'react';
import LateralMenu from '../LateralMenu';
import { faVideo, faTag, faMap, faDownload, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';
import { faWindows } from '@fortawesome/free-brands-svg-icons';
import logo from '../../../assets/logo_cameras.png';
import collapsedLogo from '../../../assets/cameras_icon.png';

export type SelectedMenu = 'cameras' | 'map' | 'tags' | 'mosaics' | 'downloads' | 'download_tags' | 'video_analysis';

interface CamerasLateralMenuProps extends LateralMenuProps {
    selectedMenu?: SelectedMenu;
}

export default function CamerasLateralMenu({ navigation, selectedMenu, authenticatedUser, open, setOpen }: CamerasLateralMenuProps) {
    const items = [];

    if (authenticatedUser) {
        items.push({
            icon: faMap,
            text: translate('map'),
            action: () => navigation.navigate('Cameras', { screen: 'CamerasMap' }),
            selected: selectedMenu == 'map'
        }, {
            icon: faVideo,
            text: translate('cameras'),
            action: () => navigation.navigate('Cameras', { screen: 'CamerasList' }),
            selected: selectedMenu == 'cameras'
        }, {
            icon: faWindows,
            text: translate('mosaics'),
            action: () => navigation.navigate('Cameras', { screen: 'Mosaic' }),
            selected: selectedMenu == 'mosaics'
        }, {
            icon: faTag,
            text: translate('tags'),
            action: () => navigation.navigate('Cameras', { screen: 'Tags' }),
            selected: selectedMenu == 'tags'
        }, {
            icon: faTag,
            text: translate('downloadsTags'),
            action: () => navigation.navigate('Cameras', { screen: 'DownloadTags' }),
            selected: selectedMenu == 'download_tags'
        });
    }

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.camera_download_video || authenticatedUser?.permissions.camera_watch_download) {
        items.push({
            icon: faDownload,
            text: 'Downloads',
            action: () => navigation.navigate('Cameras', { screen: 'Downloads' }),
            selected: selectedMenu == 'downloads'
        });
    }

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.camera_video_analysis || authenticatedUser?.permissions.camera_watch_video_analysis) {
        items.push({
            icon: faMagnifyingGlass,
            text: translate('zeusAI'),
            action: () => navigation.navigate('Cameras', { screen: 'VideoAnalysis' }),
            selected: selectedMenu === 'video_analysis'
        });
    }

    return (
        <LateralMenu
            topImage={logo}
            collapsedTopImage={collapsedLogo}
            setOpen={setOpen}
            items={items}
            open={open}
            authenticatedUser={authenticatedUser}
            goBack={() => navigation.navigate('Menu')} />
    );
}
