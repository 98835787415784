import React from 'react';
import { View } from 'react-native';
import { useStyles, createStyleSheet } from 'react-native-unistyles';
import { translate } from '../../../services/translate';
import MyAppText from '../../../components/MyAppText';
import { ScrollView } from 'react-native-web-hover';
import moment from 'moment';

interface Params {
    searchType: 'document' | 'motherName' | 'birthDate';
    isLoading: boolean;
    personFromDocument?: PersonFromDocument;
    personFromMotherName: PersonFromMotherName[];
    personFromBirthDate: PersonFromBirthDate[];
}

export default function PersonContext({ searchType, isLoading, personFromDocument, personFromMotherName, personFromBirthDate }: Params) {
    const { styles } = useStyles(styleSheet);

    return (
        <ScrollView contentContainerStyle={{ gap: 5 }}>
            {!isLoading && searchType == 'motherName' && personFromMotherName.map(person => (
                <View key={person.id} style={styles.cardBody}>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('name')}:</MyAppText> {person.nomeCompleto}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('motherName')}:</MyAppText> {person.nomeMae}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('document')}:</MyAppText> {person.numeroCPF}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('birth')}:</MyAppText> {person.dataNascimento}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('situation')}:</MyAppText> {person.situacaoCadastral}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('location')}:</MyAppText> {person.municipio}/{person.uf}</MyAppText>
                </View>
            ))}
            {!isLoading && searchType == 'birthDate' && personFromBirthDate.map(person => (
                <View key={person.id} style={styles.cardBody}>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('name')}:</MyAppText> {person.nomeCompleto}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('motherName')}:</MyAppText> {person.nomeMae}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('document')}:</MyAppText> {person.numeroCPF}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('birth')}:</MyAppText> {person.dataNascimento}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('situation')}:</MyAppText> {person.situacaoCadastral}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('location')}:</MyAppText> {person.municipio}/{person.uf}</MyAppText>
                </View>
            ))}
            {!isLoading && searchType == 'document' && personFromDocument &&
                <View key={personFromDocument.id} style={styles.cardBody}>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('name')}:</MyAppText> {personFromDocument.nomeCompleto}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('motherName')}:</MyAppText> {personFromDocument.nomeMae}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('document')}:</MyAppText> {personFromDocument.numeroCPF}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('socialName')}:</MyAppText> {personFromDocument.nomeSocial}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('occupationExerciseYear')}:</MyAppText> {moment(personFromDocument.anoExercicioOcupacao).format('DD/MM/YYYY')}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('yearOfDeath')}:</MyAppText> {personFromDocument.anoObito}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('neighborhood')}:</MyAppText> {personFromDocument.bairro}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('postalCode')}:</MyAppText> {personFromDocument.cep}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('addressComplement')}:</MyAppText> {personFromDocument.complementoLogradouro}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('updateDate')}:</MyAppText> {moment(personFromDocument.dataAtualizacao).format('DD/MM/YYYY')}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('dateOfBirth')}:</MyAppText> {moment(personFromDocument.dataNascimento).format('DD/MM/YYYY')}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('areaCode')}:</MyAppText> {personFromDocument.ddd}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('foreignResidentIdentifier')}:</MyAppText> {personFromDocument.identificadorResidenteExterior}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('foreignerIndicator')}:</MyAppText> {personFromDocument.indicadorEstrangeiro}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('foreignResidentIndicator')}:</MyAppText> {personFromDocument.indicadorMoradorEstrangeiro}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('latitude')}:</MyAppText> {personFromDocument.latitudeAproximadaLocal}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('address')}:</MyAppText> {personFromDocument.logradouro}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('longitude')}:</MyAppText> {personFromDocument.longitudeAproximadaLocal}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('city')}:</MyAppText> {personFromDocument.municipio}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('birthCity')}:</MyAppText> {personFromDocument.municipioNaturalidade}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('occupationNature')}:</MyAppText> {personFromDocument.naturezaOcupacao}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('addressNumber')}:</MyAppText> {personFromDocument.numeroLogradouro}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('mainOccupation')}:</MyAppText> {personFromDocument.ocupacaoPrincipal}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('countryOfBirth')}:</MyAppText> {personFromDocument.paisNascimento}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('countryOfResidence')}:</MyAppText> {personFromDocument.paisResidencia}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('fiscalRegion')}:</MyAppText> {personFromDocument.regiaoFiscal}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('gender')}:</MyAppText> {personFromDocument.sexo}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('registrationStatus')}:</MyAppText> {personFromDocument.situacaoCadastral}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('phone')}:</MyAppText> {personFromDocument.telefone}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('addressType')}:</MyAppText> {personFromDocument.tipoLogradouro}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('voterId')}:</MyAppText> {personFromDocument.tituloEleitor}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>UF:</MyAppText> {personFromDocument.uf}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('birthState')}:</MyAppText> {personFromDocument.ufNaturalidade}</MyAppText>
                </View>
            }
        </ScrollView>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    cardBody: {
        gap: 10,
        backgroundColor: theme.colors.backgroundColor,
        padding: 10,
        borderColor: theme.colors.disabledButtonBackground,
        borderWidth: 1,
        borderRadius: 4,
    }
}));
