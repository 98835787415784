import React, { useState, useEffect, useContext } from 'react';
import { FlatList, View, StyleSheet, TouchableOpacity, TextInput, Image, Modal } from 'react-native';
import { translate } from '../../services/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Map from '../../components/Map';
import { faArrowLeft, faBookOpen, faCar, faCube, faMapMarkedAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { ImageStyle } from 'react-native';
import FileUploader from '../../components/FileUploader';
import MyAppText from '../../components/MyAppText';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import OccurrenceFormDefault from '../../components/dispatch/occurrenceForm/default';
import OccurrenceFormFaceDetected from '../../components/dispatch/occurrenceForm/faceDetected';
import SceneChangeDetection from '../../components/dispatch/occurrenceForm/scenechangedetection';
import moment from 'moment';
import Toast from 'react-native-toast-message';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { AlreadyWithThisUser, dispatchService } from '../../services/central-api/dispatch';
import { Tabs } from '../../components/Tabs';
import DeleteModal from '../../components/DeleteModal';
import OccurrenceFormLprDetected from '../../components/dispatch/occurrenceForm/OccurrenceFormLprDetected';
import OccurrenceFormIrregularVehicle from '../../components/dispatch/occurrenceForm/OccurrenceFormIrregularVehicleDetected';
import OccurrenceAreaInvasion from '../../components/dispatch/occurrenceForm/OccurrenceAreaInvasion';
import { ClientError } from '../../services/central-api/base-service';
import FormActions from '../../components/formActions';
import OccurrenceDetailsDispatchTab from './OccurrenceDetailsDispatchTab';
import FinishReasonModal from '../dispatch/occurrenceForm/FinishReason';
import OccurrenceFormMpPlateDetected from '../dispatch/occurrenceForm/OccurrenceFormMpPlateDetected';
import OccurrenceFormMpFaceDetected from '../dispatch/occurrenceForm/OccurrenceFormMpFaceDetected';
import InvolvedVehicles from '../dispatch/occurrenceForm/InvolvedVehicles';
import InvolvedPeople from '../dispatch/occurrenceForm/InvolvedPeople';
import InvolvedObjects from '../dispatch/occurrenceForm/InvolvedObjects';
import OccurrenceFormAlarmCentral from '../dispatch/occurrenceForm/OccurrenceFormAlarmCenterDetected';
import ExportPDFOccurrenceDetails from './ExportPDFOccurrenceDetails';
import OccurrenceFormGuardianApp from '../dispatch/occurrenceForm/OccurrenceFormGuardianAppDetected';

const imagePreviewStyle: ImageStyle = {
    width: 150,
    height: 150,
    resizeMode: 'cover',
};

interface OccurrenceDetailParams {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
    occurrenceId?: string;
    page: 'dispatch' | 'events';
}

export function OccurrenceDetail({ navigation, occurrenceId, authenticatedUser, page }: OccurrenceDetailParams) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    const [involvedPeople, setInvolvedPeople] = useState<InvolvedPerson[]>([]);
    const [involvedObjects, setInvolvedObjects] = useState<InvolvedObject[]>([]);
    const [involvedVehicles, setInvolvedVehicles] = useState<InvolvedVehicle[]>([]);

    const [title, setTitle] = useState('');
    const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
    const [isTransferModalVisible, setTransferModalVisible] = useState(false);
    const [eventOperators, setEventOperators] = useState<{ id: number; name: string; warName: string; }[]>([]);
    const [selectedEventOperator, setSelectedEventOperator] = useState<number>(0);
    const [isEventOperatorDropdownOpen, setEventOperatorDropdownOpen] = useState(false);
    const [occurrence, setOccurrence] = useState<Occurrence>();
    const [requester, setRequester] = useState<string>(occurrence && occurrence.triggerType == null ? occurrence.requester : '');
    const [address, setAddress] = useState<string>(occurrence?.address || '');
    const [phone, setPhone] = useState<string>(occurrence && occurrence.triggerType == null ? occurrence.phone : '');
    const [occurrenceTypeId, setOccurrenceTypeId] = useState<number | undefined>(occurrence && occurrence.triggerType == null ? occurrence.occurrenceTypeId : undefined);
    const [occurrencePriorityList, setOccurrencePriorityList] = useState<string[]>([]);
    const [occurrencePriorityPickerOpen, setOccurrencePriorityPickerOpen] = useState(false);
    const [occurrencePriorityId, setOccurrencePriority] = useState<OccurrencePriorities | null>(occurrence?.priority || 'low');
    const [narrative, setNarrative] = useState({ dirty: false, value: occurrence?.narrative || '' });
    const [renderFlatList, setRenderFlatList] = useState<boolean>(false);
    const [historicNarrative, setHistoricNarrative] = useState({ dirty: false, value: '' });
    const [pin, setPin] = useState<LatLng>();
    const [historicList, setHistoricList] = useState<OccurrenceReport[]>([]);
    const [agentPositions, setAgentPositions] = useState<UnitMapPosition[]>([]);
    const [dispatchedUnits, setDispatchedUnits] = useState<Unit[]>([]);
    const [isOpenTags, setIsOpenTags] = useState<boolean>(false);
    const [selectedTagsIds, setSelectedTagsIds] = useState<number[]>([]);
    const [selectableTags, setSelectableTags] = useState<{ label: string, value: number; }[]>([]);
    const [actingBodyId, setActingBodyId] = useState<number>(occurrence ? occurrence.actingBodyId : 0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // finish occurrence
    const [finishCategory, setFinishCategory] = useState<number>(0);
    const [finishSubCategory, setFinishSubCategory] = useState<number>(0);
    const [isFinishModalVisible, setFinishModalVisible] = useState(false);

    function isPhoneInvalid(value: string) {
        return value == '' || value.length < 10;
    }

    function isTypeInvalid(value: number) {
        return isNaN(value) || value <= 0;
    }

    function isDefaultFormValid() {
        return requester !== '' && !isPhoneInvalid(phone) && !isTypeInvalid(occurrenceTypeId || 0);
    }

    function isFormValid() {
        const condition = narrative.value !== '' && occurrencePriorityId !== null && selectedTagsIds.length > 0;
        if (occurrence?.triggerType) {
            return condition;
        }
        if (occurrence && occurrence.id && dispatchedUnits.length > 0 && (dispatchedUnits as (Unit & { isStarter: boolean; })[]).findIndex(unit => unit.isStarter) == -1) {
            return false;
        }

        return condition && isDefaultFormValid();
    }

    async function getEventOperators() {
        try {
            const eventOperators = await dispatchService.getEventOperators({ actingBodyId: occurrence?.actingBodyId });
            setEventOperators(eventOperators);
        } catch (err) {
            console.error(err);
        }
    }

    async function transferOccurrence() {
        try {
            const occurrenceReport = await dispatchService.transferOccurrence(occurrence?.id || 0, {
                eventOperatorId: selectedEventOperator
            });

            setHistoricList([occurrenceReport, ...historicList]);

            setSelectedEventOperator(0);
            setTransferModalVisible(false);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            if (err instanceof AlreadyWithThisUser) {
                return Toast.show({
                    type: 'sentinelxWarning',
                    text1: translate('AlreadyWithThisUser'),
                });
            }

            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    async function getOccurrence(occurrenceId: string) {
        try {
            const occurrence = await dispatchService.getOccurrence(Number(occurrenceId));
            setOccurrence(occurrence);
            setHistoricList(occurrence.occurrenceReports || []);
            setRenderFlatList(!renderFlatList);
            setInvolvedVehicles(occurrence.involvedVehicles);
            setInvolvedPeople(occurrence.involvedPeople);
            setInvolvedObjects(occurrence.involvedObjects);
            setActingBodyId(occurrence.actingBodyId);

            if (occurrence.tags?.length) {
                setSelectedTagsIds(occurrence.tags.map(tag => tag.id || 0));
            }

            if (occurrence.triggerType == 'face_detected') {
                setTitle(translate('faceDetectedAlert'));
            } else if (occurrence.triggerType == 'mp_face_detected') {
                setTitle(translate('mpFaceDetectedAlert'));
            } else if (['scenechangedetection', 'defocus'].includes(occurrence.triggerType || '')) {
                setTitle(translate('cameraDepredationSuspect'));
            } else if (occurrence.triggerType == 'plate_detected') {
                setTitle(translate('plateDetectedAlert'));
            } else if (occurrence.triggerType == 'mp_plate_detected') {
                setTitle(translate('mpPlateDetectedAlert'));
            } else if (occurrence.triggerType == 'irregular_vehicle') {
                setTitle(translate('irregular_vehicle'));
            } else if (occurrence.triggerType == 'area_invasion') {
                setTitle(translate('areaInvasionAlert'));
            } else if (occurrence.triggerType == 'alarm_center') {
                setTitle(translate('alarmCenterAlert'));
            } else if (occurrence.triggerType == 'guardian_app') {
                setTitle(translate('guardianAppAlert'));
            } else if (occurrence.triggerType == null) {
                setTitle(occurrence.occurrenceType.name);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    async function getOccurrenceTags(actingBodyId?: number) {
        try {
            const tags = await dispatchService.getDropDownOccurrenceTags(actingBodyId);
            setSelectableTags(tags.map((tag) => {
                return {
                    value: tag.id,
                    label: tag.name
                };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (!authenticatedUser) {
            return;
        }

        if ((authenticatedUser.isAdmin || authenticatedUser.permissions.dispatch_manage_all_acting_bodies_occurrence_tags) && actingBodyId) {
            getOccurrenceTags(actingBodyId);
            if (!occurrence?.id) {
                // On creating occurrence ensure clean all tags when actingBodyId changed value
                setSelectedTagsIds([]);
            }
        }

    }, [actingBodyId, authenticatedUser]);

    useEffect(() => {
        if (occurrenceId) {
            getOccurrence(occurrenceId);
        } else {
            setTitle(translate('newOccurrence'));
        }
    }, [occurrenceId]);

    function navigateBack() {
        if (navigation.canGoBack()) {
            return navigation.goBack();
        } else if (page === 'events') {
            return navigation.navigate('Events', { screen: 'EventsBoard' });
        }
        return navigation.navigate('Dispatch', { screen: 'Occurrences' });
    }

    function renderAttachment(data: { url: string; type: string; }) {
        if (data.type.includes('image/')) {
            return <>
                <Image source={{ uri: data.url }} style={imagePreviewStyle} />
            </>;
        }
        return <View>
            <TouchableOpacity
                style={{
                    height: 40,
                    borderRadius: 4,
                    backgroundColor: getThemedColor(theme, '#000028'),
                    paddingHorizontal: 30,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                onPress={() => window.open(data.url)}
            >
                <MyAppText style={{
                    color: getThemedColor(theme, '#FFFFFF'),
                    fontSize: 16
                }}>
                    {translate('downloadAttachment')}
                </MyAppText>
            </TouchableOpacity >
            <View style={{
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <MyAppText style={{ fontSize: 11 }}>{translate('type')}: {data.type}</MyAppText>
            </View>
        </View>;
    }

    function renderHistoric({ item }: { item: OccurrenceReport; }) {
        const content = item.occurrenceReportType != 'report' ? JSON.parse(item.content) : item.content;
        return (
            <View style={styles.card}>

                {item.occurrenceReportType == 'attachment' ?
                    <View style={{ gap: 10, flexDirection: 'row' }}>
                        <View>
                            <MyAppText style={styles.cardHeader}>{translate('addedAttachment')}</MyAppText>
                            <MyAppText style={styles.cardSubtitle}>
                                {item.user ? (item.user.warName || item.user.name) + ' • ' : ''}
                                {moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                            </MyAppText>
                        </View>
                        <View style={{ flex: 1, alignItems: 'flex-end' }}>
                            {renderAttachment(content)}
                        </View>
                    </View>
                    : null
                }

                {item.occurrenceReportType == 'report' ?
                    <View style={{ gap: 10 }}>
                        <View>
                            <MyAppText style={styles.cardHeader}>{translate('addedComment')}</MyAppText>
                            <MyAppText style={styles.cardSubtitle}>
                                {item.user ? (item.user.warName || item.user.name) + ' • ' : ''}
                                {moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                            </MyAppText>
                        </View>
                        <MyAppText style={styles.cardContent}>{item.content}</MyAppText>
                    </View>
                    : null
                }

                {item.occurrenceReportType == 'historic' ?
                    <View style={{ gap: 10 }}>
                        <View>
                            <MyAppText style={styles.cardHeader}>{translate(content.title)}</MyAppText>
                            <MyAppText style={styles.cardSubtitle}>
                                {item.user ? (item.user.warName || item.user.name) + ' • ' : ''}
                                {moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                            </MyAppText>
                        </View>
                        <MyAppText style={styles.cardContent}>
                            {
                                Object.keys(content).filter((key) => !['title', 'userId', 'actingBodyId'].includes(key)).map((key) => {
                                    if (['occurrence_updated_involved_object', 'occurrence_updated_involved_person', 'occurrence_updated_involved_vehicle'].includes(content.title)) {
                                        const [oldValue, newValue] = JSON.parse(content[key]);
                                        if (['gender', 'stolen', 'currency', 'arrested'].includes(key) && content[key]) {
                                            return `${translate(key)}: ${translate(String(oldValue))} => ${translate(String(newValue))}`;
                                        }
                                        return `${translate(key)}: ${oldValue} => ${newValue}`;
                                    }
                                    return `${translate(key)}: ${(['priority', 'situation', 'gender', 'stolen', 'currency', 'arrested'].includes(key) || (key == 'type' && occurrence?.triggerType)) && content[key] !== null ? translate(String(content[key])) : content[key]}`;
                                }
                                ).join('\n')
                            }
                        </MyAppText>
                    </View>
                    : null
                }
            </View >
        );
    }

    function castToInt(value: string | number): number {
        if (typeof value === 'number') {
            return value;
        }
        if (value) {
            value = value.replace(',', '.');
        }
        return Number(value);
    }

    async function sendHistoricNarrative(content: string) {
        try {
            if (!occurrence?.id) {
                return;
            }
            const occurrenceReport = await dispatchService.createOccurrenceReport(occurrence.id, content);
            setHistoricList([occurrenceReport, ...historicList]);
            setHistoricNarrative({ dirty: false, value: '' });
        } catch (err) {
            console.error(err);
        }
    }

    async function getOccurrencePriorities() {
        try {
            const occurrencePriorities = await dispatchService.getOccurrencePriorities();
            setOccurrencePriorityList(occurrencePriorities);
        } catch (err) {
            console.error(err);
        }
    }

    function getPageContent() {
        getOccurrencePriorities();

        if (occurrence?.latitude && occurrence?.longitude) {
            setPin({ lat: castToInt(occurrence.latitude), lng: castToInt(occurrence.longitude) });
            setAddress(occurrence.address || '');
        }
        if (occurrence?.id) {
            setRequester(occurrence.triggerType == null ? occurrence.requester : '');
            setPhone(occurrence.triggerType == null ? occurrence.phone : '');
            setOccurrenceTypeId(occurrence.triggerType == null ? occurrence.occurrenceTypeId : undefined);
            setOccurrencePriority(occurrence.priority);
            setNarrative({ dirty: false, value: occurrence?.narrative || '' });
        }
    }

    useEffect(() => {
        if (!authenticatedUser) {
            return;
        }
        if (!(authenticatedUser.isAdmin || authenticatedUser.permissions.dispatch_manage_all_acting_bodies_occurrence_tags)) {
            getOccurrenceTags();
        }
        getPageContent();

    }, [occurrence, authenticatedUser]);

    async function finishEvent() {
        try {
            if (occurrence?.id) {
                await dispatchService.finishOccurrence(occurrence.id, finishCategory, finishSubCategory);
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });
                setFinishCategory(0);
                setFinishSubCategory(0);
                navigateBack();
            }
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    async function reopenEvent() {
        try {
            if (occurrence?.id) {
                await dispatchService.reopenOccurrence(occurrence.id);
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });
                if (page == 'events') {
                    return navigation.navigate('Events', { screen: 'EventsOccurrenceDetail', params: { occurrenceId, page } });
                }
                return navigation.navigate('DispatchOccurrenceDetail', { occurrenceId, page });
            }
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    async function formSubmit() {
        try {
            if (!isFormValid() || isLoading) {
                return;
            }

            if (!occurrencePriorityId) {
                return;
            }
            setIsLoading(true);

            if (occurrence?.id && occurrenceId) {
                await dispatchService.updateOccurrence({
                    id: occurrence.id,
                    requester: requester,
                    phone: phone,
                    narrative: narrative?.value,
                    occurrenceTypeId: occurrenceTypeId,
                    priority: occurrencePriorityId,
                    latitude: pin?.lat,
                    longitude: pin?.lng,
                    unitsToSet: (dispatchedUnits as (Unit & { isStarter: boolean; displaced: boolean; displaceReasonId?: number; })[]).map(unit => {
                        return { id: unit.id, isStarter: unit.isStarter, displaced: unit.displaced, displaceReasonId: unit.displaceReasonId };
                    }),
                    tags: selectedTagsIds,
                    involvedVehicles: involvedVehicles.map(involvedVehicle => involvedVehicle.id && involvedVehicle.id < 0 ? { ...involvedVehicle, id: undefined } : involvedVehicle),
                    involvedPeople: involvedPeople.map(involvedPerson => involvedPerson.id && involvedPerson.id < 0 ? { ...involvedPerson, id: undefined } : involvedPerson),
                    involvedObjects: involvedObjects.map(involvedObject => involvedObject.id && involvedObject.id < 0 ? { ...involvedObject, id: undefined } : involvedObject),
                });
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });
                if (page == 'events') {
                    return navigation.navigate('Events', { screen: 'EventsOccurrenceDetail', params: { occurrenceId, page } });
                }
                return navigation.navigate('DispatchOccurrenceDetail', { occurrenceId, page });
            }

            if (!pin || !occurrenceTypeId) {
                return;
            }

            const createdOccurrence = await dispatchService.createOccurrence({
                requester: requester,
                phone: phone,
                narrative: narrative?.value,
                occurrenceTypeId: occurrenceTypeId,
                priority: occurrencePriorityId,
                latitude: pin.lat,
                longitude: pin.lng,
                tags: selectedTagsIds,
                involvedVehicles: involvedVehicles.map(involvedVehicle => involvedVehicle.id && involvedVehicle.id < 0 ? { ...involvedVehicle, id: undefined } : involvedVehicle),
                involvedPeople: involvedPeople.map(involvedPerson => involvedPerson.id && involvedPerson.id < 0 ? { ...involvedPerson, id: undefined } : involvedPerson),
                involvedObjects: involvedObjects.map(involvedObject => involvedObject.id && involvedObject.id < 0 ? { ...involvedObject, id: undefined } : involvedObject),
                actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined
            });
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            return navigation.navigate('DispatchOccurrenceDetail', { occurrenceId: createdOccurrence.id, page });
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });

        }
    }

    async function getAgentPositions() {
        try {
            const agentPositions = await dispatchService.getAgentPositions();
            setAgentPositions(agentPositions);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getAgentPositions();

        const interval = setInterval(() => {
            // idk why but this effect is being called even when accessing others pages
            // this is a guarantee that the interval will work only when the page is active
            if (!window.location.href.includes('/occurrences/detail')) {
                return;
            }

            getAgentPositions();
        }, 30000);
        return () => clearInterval(interval);
    }, []);


    return (<View style={styles.container}>
        <View style={styles.mainHeader}>
            <View style={{ alignItems: 'center', flexDirection: 'row', columnGap: 10, flexGrow: 1 }}>
                <TouchableOpacity onPress={navigateBack}>
                    <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={getThemedColor(theme, '#000028')} />
                </TouchableOpacity>
                <MyAppText style={{ maxWidth: 500, fontSize: 24, color: getThemedColor(theme, '#58595B'), fontWeight: 'bold' }}>
                    {title}
                </MyAppText>
                {
                    occurrence && occurrence.id ?
                        <MyAppText style={{ fontSize: 20, color: getThemedColor(theme, '#58595B'), fontWeight: 'bold' }}>
                            ({translate('nProtocol')}: {occurrence?.id})
                        </MyAppText>
                        : undefined
                }
            </View>
            {occurrence &&
                <ExportPDFOccurrenceDetails occurrence={occurrence} />
            }
            {(occurrence?.id && !occurrence.finishedAt && (authenticatedUser?.id == occurrence.responsibleUserId || authenticatedUser?.isAdmin || authenticatedUser?.permissions.transfer_occurrence)) ? <>
                <TouchableOpacity
                    style={{
                        borderWidth: 1,
                        borderRadius: 2,
                        borderColor: getThemedColor(theme, '#CCCCCC'),
                        backgroundColor: getThemedColor(theme, '#FFFFFF'),
                        width: 128,
                        height: 40,
                        justifyContent: 'center',
                    }}
                    onPress={() => {
                        getEventOperators();
                        setTransferModalVisible(true);
                    }}
                >
                    <MyAppText style={styles.buttonTextTransfer}>{translate('transfer')}</MyAppText>
                </TouchableOpacity>
                <TouchableOpacity
                    style={{
                        borderWidth: 0,
                        borderRadius: 2,
                        backgroundColor: getThemedColor(theme, '#000028'),
                        width: 128,
                        height: 40,
                        justifyContent: 'center',
                    }}
                    onPress={() => setFinishModalVisible(true)}
                >
                    <MyAppText style={styles.buttonTextSave}>{translate('finish')}</MyAppText>
                </TouchableOpacity>
            </> : null}
            {(occurrence?.id && occurrence.finishedAt && (authenticatedUser?.isAdmin || authenticatedUser?.permissions.reopen_occurrence)) ?
                <TouchableOpacity
                    style={{
                        borderWidth: 0,
                        borderRadius: 2,
                        backgroundColor: getThemedColor(theme, '#000028'),
                        width: 128,
                        height: 40,
                        justifyContent: 'center',
                    }}
                    onPress={() => setConfirmModalVisible(true)}
                >
                    <MyAppText style={styles.buttonTextSave}>{translate('reopen')}</MyAppText>
                </TouchableOpacity>
                : null}
            <TouchableOpacity
                onPress={formSubmit}
                disabled={!isFormValid}
                style={[{
                    borderWidth: 0,
                    borderRadius: 2,
                    backgroundColor: getThemedColor(theme, '#000028'),
                    width: 128,
                    height: 40,
                    justifyContent: 'center',
                }, !isFormValid() ? styles.buttonDisabled : null]}
            >
                <MyAppText style={[{
                    color: getThemedColor(theme, '#FFFFFF'),
                    alignSelf: 'center',
                    fontSize: 16
                }, !isFormValid() ? styles.textDisabled : null]}>
                    {translate('save')}
                </MyAppText>
            </TouchableOpacity>
        </View>
        <View style={{
            flex: 1,
            flexDirection: 'row',
            backgroundColor: getThemedColor(theme, '#F7F7F7'),
            gap: 16
        }}>
            <View style={styles.leftArea}>
                {(occurrence?.id && occurrence.triggerType == 'face_detected' && occurrence.externalReference) ?
                    <OccurrenceFormFaceDetected navigation={navigation}
                        event={occurrence.externalReference}
                        imageUrl={occurrence.resume.imageUrl}
                        occurrenceId={occurrence.id} />
                    : null}
                {(occurrence?.id && occurrence.triggerType == 'scenechangedetection') ?
                    <SceneChangeDetection navigation={navigation} occurrence={occurrence} />
                    : null}
                {(occurrence?.id && occurrence.triggerType == 'defocus') ?
                    <SceneChangeDetection navigation={navigation} occurrence={occurrence} />
                    : null}
                {(occurrence?.id && occurrence.triggerType == 'plate_detected' && occurrence.externalReference) ?
                    <OccurrenceFormLprDetected
                        navigation={navigation}
                        event={occurrence.externalReference}
                        imageUrl={occurrence.resume.imageUrl}
                        authenticatedUser={authenticatedUser}
                        setEvent={(lprDetection) => {
                            occurrence.externalReference = lprDetection;
                            setOccurrence({ ...occurrence });
                        }} />
                    : null}
                {(occurrence?.id && occurrence.triggerType == 'irregular_vehicle' && occurrence.externalReference) ?
                    <OccurrenceFormIrregularVehicle navigation={navigation} event={occurrence.externalReference} imageUrl={occurrence.resume.imageUrl} />
                    : null}
                {(occurrence?.id && occurrence.triggerType == 'area_invasion' && occurrence.externalReference) ?
                    <OccurrenceAreaInvasion navigation={navigation} occurrence={occurrence} />
                    : null}
                {(occurrence?.id && occurrence.triggerType == 'mp_plate_detected') ?
                    <OccurrenceFormMpPlateDetected resume={occurrence.resume} />
                    : null}
                {(occurrence?.id && occurrence.triggerType == 'mp_face_detected') ?
                    <OccurrenceFormMpFaceDetected resume={occurrence.resume} />
                    : null}
                {(occurrence?.id && occurrence.triggerType == 'alarm_center' && occurrence.externalReference) ?
                    <OccurrenceFormAlarmCentral occurrence={occurrence} />
                    : null}
                {(occurrence?.id && occurrence.triggerType == 'guardian_app' && occurrence.externalReference) ?
                    <OccurrenceFormGuardianApp occurrence={occurrence} />
                    : null}
                {(!occurrence?.triggerType) ?
                    <View style={{ zIndex: 4 }}>
                        <OccurrenceFormDefault
                            occurrenceId={occurrence?.id}
                            setPin={setPin}
                            phone={phone}
                            currentAddress={address}
                            setCurrentAddress={setAddress}
                            requester={requester}
                            occurrenceTypeId={occurrenceTypeId || null}
                            setRequester={setRequester}
                            setPhone={setPhone}
                            setOccurrenceTypeId={setOccurrenceTypeId}
                            setActingBodyId={setActingBodyId}
                            actingBodyId={actingBodyId}
                            authenticatedUser={authenticatedUser}
                        />
                    </View>
                    : null}

                <View style={{ rowGap: 5, zIndex: 3 }}>
                    <MyAppText style={styles.label}>{translate('priority')}</MyAppText>
                    <MyDropDownPicker
                        open={occurrencePriorityPickerOpen}
                        setOpen={setOccurrencePriorityPickerOpen}
                        value={occurrencePriorityId}
                        setValue={setOccurrencePriority}
                        items={occurrencePriorityList.map((item: string) => {
                            return {
                                label: translate(item), value: item
                            };
                        })}
                        searchable={true}
                        dropDownDirection='BOTTOM'
                        zIndex={3}
                    />
                </View>
                <View style={{ zIndex: 2 }}>
                    <View style={{ flex: 1, gap: 5 }}>
                        <MyAppText style={styles.label}>{translate('tags')}</MyAppText>
                        <MyDropDownPicker
                            items={selectableTags}
                            multiple={true}
                            mode='BADGE'
                            value={selectedTagsIds}
                            setValue={setSelectedTagsIds}
                            setOpen={setIsOpenTags}
                            open={isOpenTags}
                            dropDownDirection='BOTTOM'
                            zIndex={2}
                        />
                    </View>
                </View>
                <View style={{ rowGap: 5, zIndex: -3 }}>
                    <MyAppText style={styles.label}>{translate('narrative')}</MyAppText>
                    <TextInput
                        placeholder={translate('narrative')}
                        multiline={true}
                        style={[
                            styles.input,
                            styles.multiline,
                            narrative.value == '' && narrative.dirty ? styles.invalid : null,
                            { color: narrative.value ? getThemedColor(theme, '#222222') : getThemedColor(theme, '#CCCCCC') }
                        ]}
                        value={narrative.value}
                        onBlur={() => {
                            setNarrative({ dirty: true, value: narrative.value });
                        }}
                        onChangeText={(value) => {
                            setNarrative({ dirty: true, value });
                        }}
                    />
                </View>
            </View>
            <View style={styles.rightArea}>
                {occurrence?.id ?
                    <Tabs tabs={[{
                        key: 'dispatch',
                        label: translate('dispatch'),
                        icon: faMapMarkedAlt
                    }, {
                        key: 'involvedVehicles',
                        label: translate('involvedVehicles'),
                        icon: faCar
                    },
                    {
                        key: 'involvedPeople',
                        label: translate('involvedPeople'),
                        icon: faUser
                    },
                    {
                        key: 'involvedObjects',
                        label: translate('involvedObjects'),
                        icon: faCube
                    }, {
                        key: 'historic',
                        label: translate('historic'),
                        icon: faBookOpen
                    }]}>
                        {({ selectedTab }) => {
                            if (!occurrence?.id) {
                                return <></>;
                            }
                            if (selectedTab == 'dispatch') {
                                return <OccurrenceDetailsDispatchTab
                                    occurrence={occurrence}
                                    agentPositions={agentPositions}
                                    pin={pin}
                                    dispatchedUnits={dispatchedUnits}
                                    setDispatchedUnits={setDispatchedUnits} />;
                            }

                            if (selectedTab == 'historic') {
                                return <View style={{
                                    flex: 1,
                                }}>
                                    <View style={styles.historicList}>
                                        <FlatList
                                            extraData={renderFlatList}
                                            data={historicList}
                                            keyExtractor={(item) => item?.id?.toString() || '0'}
                                            renderItem={renderHistoric}
                                            contentContainerStyle={{ gap: 10 }}
                                        />
                                    </View>
                                    <View style={styles.uploadContent}>
                                        <View style={styles.uploadContentInput}>
                                            <TextInput
                                                placeholder={translate('historic')}
                                                multiline={true}
                                                style={[
                                                    styles.input,
                                                    styles.multiline,
                                                    historicNarrative.value == '' && historicNarrative.dirty ? styles.invalid : null,
                                                    { color: historicNarrative.value ? getThemedColor(theme, '#222222') : getThemedColor(theme, '#CCCCCC') }
                                                ]}
                                                value={historicNarrative.value}
                                                onBlur={() => {
                                                    setHistoricNarrative({ dirty: true, value: historicNarrative.value });
                                                }}
                                                onChangeText={(value) => {
                                                    setHistoricNarrative({ dirty: true, value });
                                                }}
                                            />
                                        </View>
                                        <View style={{
                                            flex: 1,
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'flex-end',
                                        }}>
                                            <TouchableOpacity style={[styles.button, styles.buttonSave, historicNarrative.value == '' ? styles.disabled : null]}
                                                disabled={historicNarrative.value == ''}
                                                onPress={() => {
                                                    sendHistoricNarrative(historicNarrative.value);
                                                }}
                                            >
                                                <MyAppText style={styles.buttonTextSave}>{translate('send')}</MyAppText>
                                            </TouchableOpacity>
                                            <FileUploader occurrenceId={occurrence.id} callback={(occurrenceReport) => {
                                                setHistoricList([...historicList, occurrenceReport]);
                                            }} />
                                        </View>
                                    </View>
                                </View>;
                            }

                            if (selectedTab === 'involvedVehicles') {
                                return <InvolvedVehicles
                                    involvedVehicles={involvedVehicles}
                                    setInvolvedVehicles={setInvolvedVehicles}
                                    actingBodyId={occurrence.actingBodyId} />;
                            }

                            if (selectedTab === 'involvedPeople') {
                                return <InvolvedPeople
                                    involvedPeople={involvedPeople}
                                    setInvolvedPeople={setInvolvedPeople}
                                    actingBodyId={occurrence.actingBodyId} />;
                            }

                            if (selectedTab === 'involvedObjects') {
                                return <InvolvedObjects
                                    involvedObjects={involvedObjects}
                                    setInvolvedObjects={setInvolvedObjects}
                                    actingBodyId={occurrence.actingBodyId} />;
                            }
                        }}
                    </Tabs>
                    : <View style={{
                        flex: 1,
                        backgroundColor: getThemedColor(theme, '#FFFFFF'),
                        borderWidth: 1,
                        borderColor: getThemedColor(theme, '#E6E6E6'),
                        borderRadius: 4,
                        padding: 20
                    }}>
                        <Map
                            dynamicMarker={pin}
                            markerChanged={setPin}
                        />
                    </View>
                }
            </View>
        </View>
        <FinishReasonModal
            setModalVisible={setFinishModalVisible}
            isModalVisible={isFinishModalVisible}
            finishCategory={finishCategory}
            finishSubCategory={finishSubCategory}
            setFinishCategory={setFinishCategory}
            setFinishSubCategory={setFinishSubCategory}
            actingBodyId={actingBodyId}
            onSubmit={async () => {
                if (!occurrence?.finishedAt) {
                    await finishEvent();
                }
            }}
        />
        <DeleteModal
            setModalVisible={setConfirmModalVisible}
            isModalVisible={isConfirmModalVisible}
            customMessage={occurrence?.finishedAt ? translate('confirmReopen') : translate('confirm_finish')}
            customConfirm={occurrence?.finishedAt ? translate('reopen') : translate('finish')}
            itemName={String(occurrence?.id) || ''}
            onSubmit={async () => {
                if (occurrence?.finishedAt) {
                    await reopenEvent();
                } else {
                    await finishEvent();
                }
            }}
        />
        <Modal visible={isTransferModalVisible} animationType='fade' onRequestClose={() => setTransferModalVisible(false)} transparent={true}>
            <View style={styles.modalContainer}>
                <View style={styles.body}>
                    <View style={{ rowGap: 5, flex: 1 }}>
                        <MyAppText style={styles.label}>{translate('eventOperators')}</MyAppText>
                        <MyDropDownPicker
                            items={eventOperators.map(item => {
                                return {
                                    label: item.warName || item.name,
                                    value: item.id
                                };
                            })}
                            searchable={true}
                            open={isEventOperatorDropdownOpen}
                            setOpen={setEventOperatorDropdownOpen}
                            setValue={setSelectedEventOperator}
                            value={selectedEventOperator}
                            multiple={false}
                        />
                    </View>
                    <FormActions
                        onSubmit={transferOccurrence}
                        onClose={() => {
                            setSelectedEventOperator(0);
                            setTransferModalVisible(false);
                        }}
                        disabled={!selectedEventOperator} />
                </View>
            </View>
        </Modal>
    </View>);
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        modalContainer: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#31313199',
            flex: 1
        },
        body: {
            minWidth: 300,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flexDirection: 'column',
            borderRadius: 4,
            padding: 25,
            rowGap: 20
        },
        container: {
            flex: 1,
            rowGap: 15
        },
        historicList: {
            flex: 1,
            flexGrow: 80,
        },
        uploadContent: {
            height: 200,
            flexDirection: 'row',
            marginTop: 50,
            flex: 1,
            flexGrow: 20,
            justifyContent: 'space-between',
            gap: 5
        },
        uploadContentInput: {
            width: '50%'
        },
        leftArea: {
            flex: 1,
            borderWidth: 1,
            padding: 15,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            overflowY: 'scroll',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 4,
            rowGap: 20
        },
        rightArea: {
            flex: 3
        },
        invalid: {
            borderColor: 'red',
        },
        input: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderWidth: 1,
            height: 40,
            padding: 10,
            borderRadius: 4,
        },
        multiline: {
            minHeight: 50,
            height: 200,
            zIndex: -11
        },
        mainHeader: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
            flexWrap: 'wrap',
            minHeight: 65
        },
        header: {
            flexDirection: 'row',
            padding: 10,
            marginRight: 15,
        },
        button: {
            borderRadius: 4,
            borderWidth: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
        },
        buttonSave: {
            backgroundColor: getThemedColor(theme, '#000028'),
        },
        buttonTextSave: {
            fontSize: 16,
            color: getThemedColor(theme, '#FFFFFF'),
            alignSelf: 'center'
        },
        buttonTextTransfer: {
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            alignSelf: 'center'
        },
        disabled: {
            borderWidth: 0,
            backgroundColor: getThemedColor(theme, '#CCCCCC')
        },
        label: {
            zIndex: -11,
            color: getThemedColor(theme, '#58595B'),
        },
        buttonDisabled: {
            borderWidth: 0,
            backgroundColor: getThemedColor(theme, '#CCCCCC')
        },
        textDisabled: {
            color: getThemedColor(theme, '#888888')
        },
        card: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderWidth: 1,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            borderRadius: 4,
            paddingVertical: 10,
            paddingHorizontal: 20,
        },
        cardHeader: {
            fontWeight: 'bold',
            fontSize: 16,
            color: getThemedColor(theme, '#58595B'),
        },
        cardSubtitle: {
            fontSize: 14,
            color: getThemedColor(theme, '#888888'),
        },
        cardContent: {
            fontSize: 16,
            color: getThemedColor(theme, '#58595B'),
        },
    });
}
