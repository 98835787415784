import React, { useState, useEffect, useContext } from 'react';
import { View, StyleSheet, TouchableOpacity, Modal, TextInput } from 'react-native';
import { translate } from '../../services/translate';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import MyAppText from '../../components/MyAppText';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import { AlreadyActiveUnitWithName, gcmAgentService } from '../../services/central-api/gcmAgent';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../services/central-api/base-service';
import moment from 'moment';
import FormActions from '../../components/formActions';

export default function AgentUnits({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    const [units, setUnits] = useState<SimplifiedUnit[]>([]);
    const [unitId, setUnitId] = useState<number>(0);
    const [unitPickerOpen, setUnitPickerOpen] = useState(false);
    const [earlyFinishModal, setEarlyFinishModal] = useState(false);
    const [reason, setReason] = useState<string>('');
    const [alreadyActive, setAlreadyActive] = useState(false);

    const [assignments, setAssignments] = useState<DropdownResource[]>([]);
    const [assignmentId, setAssignmentId] = useState<number>(0);
    const [assignmentPickerOpen, setAssignmentPickerOpen] = useState(false);

    async function getPageInformation() {
        try {
            const units = await gcmAgentService.getAgentUnits();
            setUnits(units);
            const assignments = await gcmAgentService.getAssignments();
            setAssignments(assignments);
            const agentActive = await gcmAgentService.getAgentActiveUnit();
            if (agentActive.unit) {
                setUnitId(agentActive.unit.id);
                setAlreadyActive(true);
            }
            if (agentActive.assignment) {
                setAssignmentId(agentActive.assignment.id);
            }

        } catch (err) {
            console.error(err);
        }
    }

    async function setActiveUnit() {
        try {
            if (alreadyActive) {
                await gcmAgentService.setAgentActiveAssignmentUnit({ assignmentId });
            } else {
                await gcmAgentService.setAgentActiveUnit({ unitId, assignmentId });
            }
            setAlreadyActive(true);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });

        } catch (err) {
            if (err instanceof AlreadyActiveUnitWithName) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message, { actingBody: err.actingBody }),
                    visibilityTime: 10000,
                });
            } else if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    async function checkResetUnit() {
        const unitToLeave = units.find(unit => unit.id == unitId);
        if (unitToLeave && moment(unitToLeave.estimatedFinish).toDate().getTime() > moment().toDate().getTime()) {
            setEarlyFinishModal(true);
        } else {
            await resetUnit();
        }
    }

    async function resetUnit() {
        try {
            await gcmAgentService.resetAgentActiveUnit(reason);
            setUnitId(0);
            setAssignmentId(0);
            setAlreadyActive(false);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('SUCCESS_RESET_UNIT_FOR_AGENT'),
            });
            getPageInformation();

        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }

    }

    function isFormValid() {
        return unitId && assignmentId;
    }

    useEffect(() => {
        getPageInformation();
    }, []);

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} lateralMenu={'gcmAgent'} selectedMenu='units' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={{ justifyContent: 'flex-end' }}>
                        <TouchableOpacity
                            onPress={() => {
                                checkResetUnit();
                            }}
                            style={[styles.resetUnitButton, !alreadyActive ? styles.disabled : {}]}
                            disabled={!alreadyActive}
                        >
                            <MyAppText style={[{ color: '#FE0127', alignSelf: 'center', fontSize: 16 }, !alreadyActive ? styles.textDisabled : {}]}>
                                {translate('RESET_UNIT_FOR_AGENT')}
                            </MyAppText>
                        </TouchableOpacity>
                    </View>


                    <View style={styles.cardList}>
                        <View style={{ rowGap: 20, zIndex: 2 }}>
                            <View style={{ zIndex: 3, rowGap: 5 }}>
                                <MyAppText style={styles.label}>{translate('team')}</MyAppText>
                                <MyDropDownPicker
                                    open={unitPickerOpen}
                                    setOpen={setUnitPickerOpen}
                                    value={unitId}
                                    setValue={(value) => {
                                        const selectedUnitId = value(unitId);
                                        const selectedUnit = units.find(unit => unit.id == selectedUnitId);
                                        setUnitId(selectedUnitId);

                                        if (selectedUnit) {
                                            const selectedAssignment = selectedUnit.UnitUsers.find(user => user.UnitUser.unitId == selectedUnit.id);
                                            if (selectedAssignment && selectedAssignment.UnitUser.assignmentId) {
                                                setAssignmentId(selectedAssignment.UnitUser.assignmentId);
                                            }
                                        }
                                    }}

                                    items={units.map((item: SimplifiedUnit) => {
                                        return {
                                            label: `${item.unitName.name} - ${moment(item.estimatedStart).format('DD/MM/YYYY HH:mm')}`, value: item.id
                                        };
                                    })}
                                    searchable={true}
                                    disabled={alreadyActive}
                                />
                            </View>
                            <View style={{ zIndex: 2, rowGap: 5 }}>
                                <MyAppText style={styles.label}>{translate('assignment')}</MyAppText>
                                <MyDropDownPicker
                                    open={assignmentPickerOpen}
                                    setOpen={setAssignmentPickerOpen}
                                    value={assignmentId}
                                    setValue={setAssignmentId}
                                    items={assignments.map((item: DropdownResource) => {
                                        return {
                                            label: item.name, value: item.id
                                        };
                                    })}
                                    searchable={true}
                                />
                            </View>
                        </View>
                        <TouchableOpacity onPress={() => setActiveUnit()} style={[styles.new, !isFormValid() ? styles.disabled : null]} disabled={!isFormValid()}>
                            <MyAppText style={styles.newText}>{translate('save')}</MyAppText>
                        </TouchableOpacity>
                    </View>
                </View>
                <Modal transparent={true} visible={earlyFinishModal} animationType='fade' onRequestClose={() => setEarlyFinishModal(false)}>
                    <View style={styles.modalContainer}>
                        <View style={styles.centeredView}>
                            <View style={styles.modalBody}>
                                <MyAppText>{translate('earlyFinishAgentReason')}:</MyAppText>
                                <TextInput
                                    placeholder={translate('reason')}
                                    multiline={true}
                                    value={reason}
                                    style={[
                                        styles.input,
                                        styles.multiline,
                                        { color: reason ? getThemedColor(theme, '#222222') : getThemedColor(theme, '#CCCCCC') }
                                    ]}
                                    onChangeText={setReason}
                                />
                                <View style={styles.row}>
                                    <FormActions
                                        onSubmit={() => {
                                            resetUnit();
                                            setEarlyFinishModal(false);
                                        }}
                                        onClose={() => {
                                            setEarlyFinishModal(false);
                                            setReason('');
                                        }}
                                        disabled={!reason} />
                                </View>
                            </View>
                        </View>
                    </View>
                </Modal>
            </DefaultPageLayout>
        </DefaultPageContainer>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1,
            padding: 24,
            gap: 20,
            justifyContent: 'space-between'
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
        disabled: {
            borderWidth: 0,
            backgroundColor: getThemedColor(theme, '#CCCCCC')
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        input: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderWidth: 1,
            height: 40,
            padding: 10,
            borderRadius: 4,
        },
        multiline: {
            minHeight: 50,
            height: 150,
        },
        modalBody: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flexDirection: 'column',
            borderRadius: 8,
            rowGap: 20,
            minWidth: 300,
            minHeight: 300,
            maxHeight: 560,
            padding: 30,
            width: '95%'
        },
        row: {
            flexDirection: 'column',
            columnGap: 30,
            justifyContent: 'flex-end'
        },
        resetUnitButton: {
            borderWidth: 1,
            borderRadius: 2,
            borderColor: '#FE0127',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            height: 40,
            justifyContent: 'center',
        },
        textDisabled: {
            color: getThemedColor(theme, '#888888')
        }
    });
}
