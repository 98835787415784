import React, { useCallback, useContext, useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { translate } from '../../services/translate';
import FormInput from '../../components/formInput';
import FormActions from '../../components/formActions';
import { SelectableItem, SelectableList } from '../../components/SelectableList';
import { Tabs } from '../../components/Tabs';
import { MyCheckbox } from '../../components/MyCheckbox';
import MyAppText from '../../components/MyAppText';
import Toast from 'react-native-toast-message';
import { accessService } from '../../services/central-api/access';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { WindowInformation } from '../../services/window-information';
import { ScrollView } from 'react-native-web-hover';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import { centralAPI } from '../../services/central-api';

export default function PermissionLevelModal({ onClose, permissionLevelId }: { onClose: (success: boolean) => void, permissionLevelId?: number; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [ownUser, setOwnUser] = useState<UserData>();
    const [selectableActingBodies, setSelectableActingBodies] = useState<{ label: string, value: number; }[]>([]);
    const [actingBodyId, setActingBodyId] = useState<number>(0);
    const [isOpenDepartment, setIsOpenDepartment] = useState(false);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [selectableUsers, setSelectableUsers] = useState<SelectableItem[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<SelectableItem[]>([]);
    const [permissionLevelUserIds, setPermissionLevelUserIds] = useState<number[]>([]);
    const [permissions, setPermissions] = useState<UserPermissions>({
        facial_see_faces: false,
        facial_edit_faces: false,
        lpr_edit_denylist: false,
        lpr_see_denylist: false,
        lpr_module_access: false,
        lpr_edit_detection: false,
        facial_module_access: false,
        dispatch_module_access: false,
        dispatch_manage_occurrence_tags: false,
        dispatch_manage_report_tags: false,
        dispatch_manage_units: false,
        dispatch_manage_vehicle_relations: false,
        dispatch_manage_person_relations: false,
        dispatch_manage_objects_categories: false,
        reopen_occurrence: false,
        transfer_occurrence: false,
        events_module_access: false,
        camera_management: false,
        camera_download_video: false,
        camera_download_report: false,
        camera_management_point_of_interest: false,
        camera_manage_download_tags: false,
        create_camera_maintenance_requests: false,
        create_emergency_camera_maintenance_requests: false,
        camera_watch_download: false,
        dashboard_module_access: false,
        smart_search_module_access: false,
        logs_module_access: false,
        crm_module_access: false,
        get_camera_password: false,
        search_people_by_picture: false,
        search_people_cortex: false,
        receive_occurrences: false,
        access_edit_platform_settings: false,
        access_edit_user_settings: false,
        access_management_user_tags: false,
        access_edit_group_settings: false,
        access_edit_permission_level_settings: false,
        access_edit_turnstile: false,
        dispatch_generate_report: false,
        dispatch_download_report: false,
        dispatch_manage_team_names: false,
        camera_video_analysis: false,
        camera_watch_video_analysis: false,
        dispatch_manage_acting_body_units: false,
        dispatch_manage_all_acting_bodies_occurrences: false,
        dispatch_manage_all_acting_bodies_occurrence_types: false,
        dispatch_manage_all_acting_bodies_occurrence_tags: false,
        dispatch_manage_all_acting_bodies_equipments: false,
        dispatch_manage_all_acting_bodies_reports: false,
        dispatch_manage_all_acting_bodies_report_tags: false,
        dispatch_manage_finish_categories: false,
        dispatch_pause_units: false,
        dispatch_manage_equipments: false,
        dispatch_manage_all_acting_bodies_skills: false,
        dispatch_manage_skills: false,
        dispatch_manage_all_acting_bodies_assignments: false,
        dispatch_manage_assignments: false,
        dispatch_manage_all_acting_bodies_objects_categories: false,
        dispatch_manage_all_acting_bodies_person_relations: false,
        dispatch_manage_all_acting_bodies_vehicle_relations: false,
        dispatch_manage_all_acting_bodies_unit_names: false,
        dispatch_manage_all_acting_bodies_finish_categories: false,
        guardian_app_module_access: false
    });
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);

    const [selectableUnits, setSelectableUnits] = useState<{ label: string, value: number; }[]>([]);
    const [unitId, setUnitId] = useState<number>(0);
    const [isOpenUnit, setIsOpenUnit] = useState(false);

    async function getPermissionLevel(id: number) {
        const permissionLevel = await accessService.getPermissionLevel(id);
        setName(permissionLevel.name);
        setDescription(permissionLevel.description);
        setPermissions(permissionLevel.permissionObj);

        if (permissionLevel.actingBodyUnit?.actingBodyId) {
            await loadUnits(permissionLevel.actingBodyUnit.actingBodyId);

            setActingBodyId(permissionLevel.actingBodyUnit.actingBodyId);
            setUnitId(permissionLevel.actingBodyUnitId);

            const users = await loadUsers(permissionLevel.actingBodyUnit.id || 0);
            const selectedUsers = permissionLevel.Users.map((user) => {
                return {
                    id: user.id || 0,
                    name: user.name
                };
            });
            setSelectedUsers(selectedUsers);

            setSelectableUsers((users || [])
                .filter((user) => !selectedUsers.find((permissionLevelUser) => permissionLevelUser.id == user.id))
                .map((user) => {
                    return {
                        id: user.id || 0,
                        name: user.name
                    };
                })
            );
            setPermissionLevelUserIds(permissionLevel.Users.map((user) => user.id || 0));

        }
    }


    async function loadUnits(actingBodyId: number) {
        try {
            setUnitId(0);
            const actingBody = await accessService.getActingBody(actingBodyId);
            if (!actingBody.actingBodyUnits) {
                setSelectableUnits([]);
            } else {
                setSelectableUnits(actingBody.actingBodyUnits.map((role) => {
                    return {
                        value: role.id || 0,
                        label: role.name
                    };
                }));
            }
        } catch (err) {
            console.error(err);
        }
    }

    async function loadPageInfo() {
        try {
            const [ownUser, actingBodies] = await Promise.all([
                centralAPI.getOwnUser({ includeRole: true }),
                accessService.getActingBodies({ page: 0, limit: 9999999 }),
            ]);

            setOwnUser(ownUser);

            setSelectableActingBodies(actingBodies.rows.map((actingBody) => {
                return {
                    value: actingBody.id || 0,
                    label: actingBody.name
                };
            }));

            if (permissionLevelId) {
                await getPermissionLevel(permissionLevelId);
            } else {
                if (!ownUser.isAdmin && ownUser.actingBodyUnitId) {
                    setActingBodyId(ownUser.role?.actingBodyId || 0);
                    loadUsers(ownUser.actingBodyUnitId);
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    async function loadUsers(actingBodyUnitId: number) {
        try {
            setSelectedUsers([]);
            const users = await accessService.getAccessAllUsers({ actingBodyUnitId: actingBodyUnitId });
            setSelectableUsers(users.map((user) => {
                return {
                    id: user.id || 0,
                    name: user.name
                };
            }));
            return users;
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        loadPageInfo();
    }, []);

    function isEmpty(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isEmpty(name) && (!ownUser?.isAdmin || unitId !== 0);
    }

    async function formSubmit() {
        try {
            if (permissionLevelId) {
                const selectedUserIds = selectedUsers.map((user) => Number(user.id));
                const usersToAdd = selectedUserIds.filter((selectedUserId) => !permissionLevelUserIds.includes(selectedUserId));
                const usersToRm = permissionLevelUserIds.filter((permissionLevelUserId) => !selectedUserIds.includes(permissionLevelUserId));

                await accessService.updatePermissionLevel(permissionLevelId, {
                    name,
                    description,
                    usersToAdd,
                    usersToRm,
                    permissions,
                });
                onClose(true);
                return;
            }

            await accessService.createPermissionLevel({
                name,
                description,
                usersToAdd: selectedUsers.map(item => Number(item.id)),
                permissions,
                unitId,
            });
            onClose(true);
        } catch (err) {
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: 635 }]}>
            <Tabs tabs={[{
                key: 'details',
                label: translate('details')
            }, {
                key: 'users',
                label: translate('users')
            }, {
                key: 'permissions',
                label: translate('permissions')
            }]}>
                {({ selectedTab }) => {
                    if (selectedTab == 'details') {
                        return (<>
                            <FormInput
                                label={translate('name')}
                                placeholder={translate('name')}
                                value={name}
                                onChangeText={setName}
                                invalid={isEmpty}
                            />
                            <FormInput
                                label={translate('description')}
                                placeholder={translate('description')}
                                value={description}
                                onChangeText={setDescription}
                                invalid={() => false}
                            />
                            {ownUser?.isAdmin ?
                                <View style={{ flexDirection: 'row', gap: 16, zIndex: 12 }}>
                                    <View style={{ flex: 1, gap: 5 }}>
                                        <MyAppText style={styles.label}>{translate('actingBody')}</MyAppText>
                                        <MyDropDownPicker
                                            items={selectableActingBodies}
                                            multiple={false}
                                            value={actingBodyId}
                                            setValue={async (value) => {
                                                const val = value(0);
                                                setActingBodyId(val);
                                                loadUnits(val);
                                                setSelectedUsers([]);
                                                setSelectableUsers([]);
                                            }}
                                            setOpen={setIsOpenDepartment}
                                            open={isOpenDepartment}
                                            searchable={true}
                                            disabled={!!permissionLevelId}
                                        />
                                    </View>
                                    <View style={{ flex: 1, gap: 5 }}>
                                        <MyAppText style={styles.label}>{translate('unit')}</MyAppText>
                                        <MyDropDownPicker
                                            items={selectableUnits}
                                            multiple={false}
                                            value={unitId}
                                            setValue={async (value) => {
                                                const val = value(0);
                                                setUnitId(val);
                                                loadUsers(val);
                                            }}
                                            setOpen={setIsOpenUnit}
                                            open={isOpenUnit}
                                            searchable={true}
                                            disabled={!!permissionLevelId}
                                        />
                                    </View>
                                </View>
                                : null
                            }
                        </>);
                    }
                    if (selectedTab == 'users') {
                        return <SelectableList
                            selectableLabel={translate('users')}
                            selectableContent={selectableUsers}
                            selectedLabel={translate('participants')}
                            selectedContent={selectedUsers}
                        />;
                    }
                    if (selectedTab == 'permissions') {
                        return (
                            <ScrollView contentContainerStyle={styles.permissionsContainer}>
                                <View style={styles.permissionMainColumn}>
                                    <View style={styles.permissionColumn}>
                                        <MyAppText style={styles.permissionSubTitle}>{translate('facialModule')}</MyAppText>
                                        <MyCheckbox
                                            label={translate('fullModuleAccess')}
                                            checked={permissions.facial_module_access}
                                            setChecked={(value) => {
                                                permissions.facial_module_access = value;
                                                forceUpdate();
                                            }}
                                        />
                                    </View>
                                    <View style={styles.permissionColumn}>
                                        <MyAppText style={styles.permissionSubTitle}>{translate('dispatchModule')}</MyAppText>
                                        <MyCheckbox
                                            label={translate('fullModuleAccess')}
                                            checked={permissions.dispatch_module_access}
                                            setChecked={(value) => {
                                                permissions.dispatch_module_access = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('reopenOccurrence')}
                                            checked={permissions.reopen_occurrence}
                                            setChecked={(value) => {
                                                permissions.reopen_occurrence = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('transferOccurrence')}
                                            checked={permissions.transfer_occurrence}
                                            setChecked={(value) => {
                                                permissions.transfer_occurrence = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('manageOccurrenceTags')}
                                            checked={permissions.dispatch_manage_occurrence_tags}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_occurrence_tags = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('manageReportTags')}
                                            checked={permissions.dispatch_manage_report_tags}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_report_tags = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('manageUnits')}
                                            checked={permissions.dispatch_manage_units}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_units = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('manageActingBodyUnits')}
                                            checked={permissions.dispatch_manage_acting_body_units}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_acting_body_units = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('manageActingBodyOccurrences')}
                                            checked={permissions.dispatch_manage_all_acting_bodies_occurrences}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_all_acting_bodies_occurrences = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('manageActingBodyOccurrenceTypes')}
                                            checked={permissions.dispatch_manage_all_acting_bodies_occurrence_types}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_all_acting_bodies_occurrence_types = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('manageActingBodyOccurrenceTags')}
                                            checked={permissions.dispatch_manage_all_acting_bodies_occurrence_tags}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_all_acting_bodies_occurrence_tags = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('manageActingBodyReports')}
                                            checked={permissions.dispatch_manage_all_acting_bodies_reports}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_all_acting_bodies_reports = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('manageActingBodyReportTags')}
                                            checked={permissions.dispatch_manage_all_acting_bodies_report_tags}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_all_acting_bodies_report_tags = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('manageEquipments')}
                                            checked={permissions.dispatch_manage_equipments}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_equipments = value;
                                                if (!value) {
                                                    permissions.dispatch_manage_all_acting_bodies_equipments = value;
                                                }
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('manageActingBodyEquipments')}
                                            checked={permissions.dispatch_manage_all_acting_bodies_equipments}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_all_acting_bodies_equipments = value;
                                                forceUpdate();
                                            }}
                                            disabled={!permissions.dispatch_manage_equipments}
                                        />
                                        <MyCheckbox
                                            label={translate('manageSkills')}
                                            checked={permissions.dispatch_manage_skills}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_skills = value;
                                                if (!value) {
                                                    permissions.dispatch_manage_all_acting_bodies_skills = value;
                                                }
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('manageActingBodySkills')}
                                            checked={permissions.dispatch_manage_all_acting_bodies_skills}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_all_acting_bodies_skills = value;
                                                forceUpdate();
                                            }}
                                            disabled={!permissions.dispatch_manage_skills}
                                        />
                                        <MyCheckbox
                                            label={translate('manageAssignments')}
                                            checked={permissions.dispatch_manage_assignments}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_assignments = value;
                                                if (!value) {
                                                    permissions.dispatch_manage_all_acting_bodies_assignments = value;
                                                }
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('manageActingBodyAssignments')}
                                            checked={permissions.dispatch_manage_all_acting_bodies_assignments}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_all_acting_bodies_assignments = value;
                                                forceUpdate();
                                            }}
                                            disabled={!permissions.dispatch_manage_assignments}
                                        />
                                        <MyCheckbox
                                            label={translate('manageVehicleRelations')}
                                            checked={permissions.dispatch_manage_vehicle_relations}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_vehicle_relations = value;
                                                if (!value) {
                                                    permissions.dispatch_manage_all_acting_bodies_vehicle_relations = value;
                                                }
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('manageActingBodyVehicleRelations')}
                                            checked={permissions.dispatch_manage_all_acting_bodies_vehicle_relations}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_all_acting_bodies_vehicle_relations = value;
                                                forceUpdate();
                                            }}
                                            disabled={!permissions.dispatch_manage_vehicle_relations}
                                        />
                                        <MyCheckbox
                                            label={translate('managePersonRelations')}
                                            checked={permissions.dispatch_manage_person_relations}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_person_relations = value;
                                                if (!value) {
                                                    permissions.dispatch_manage_all_acting_bodies_person_relations = value;
                                                }
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('manageActingBodyPersonRelations')}
                                            checked={permissions.dispatch_manage_all_acting_bodies_person_relations}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_all_acting_bodies_person_relations = value;
                                                forceUpdate();
                                            }}
                                            disabled={!permissions.dispatch_manage_person_relations}
                                        />
                                        <MyCheckbox
                                            label={translate('manageObjectsCategories')}
                                            checked={permissions.dispatch_manage_objects_categories}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_objects_categories = value;
                                                if (!value) {
                                                    permissions.dispatch_manage_all_acting_bodies_objects_categories = value;
                                                }
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('manageActingBodyObjectsCategories')}
                                            checked={permissions.dispatch_manage_all_acting_bodies_objects_categories}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_all_acting_bodies_objects_categories = value;
                                                forceUpdate();
                                            }}
                                            disabled={!permissions.dispatch_manage_objects_categories}
                                        />
                                        <MyCheckbox
                                            label={translate('manageTeamNames')}
                                            checked={permissions.dispatch_manage_team_names}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_team_names = value;
                                                if (!value) {
                                                    permissions.dispatch_manage_all_acting_bodies_unit_names = value;
                                                }
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('manageActingBodyTeamNames')}
                                            checked={permissions.dispatch_manage_all_acting_bodies_unit_names}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_all_acting_bodies_unit_names = value;
                                                forceUpdate();
                                            }}
                                            disabled={!permissions.dispatch_manage_team_names}
                                        />
                                        <MyCheckbox
                                            label={translate('generateOccurrenceReport')}
                                            checked={permissions.dispatch_generate_report}
                                            setChecked={(value) => {
                                                permissions.dispatch_generate_report = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('downloadOccurrenceReport')}
                                            checked={permissions.dispatch_download_report}
                                            setChecked={(value) => {
                                                permissions.dispatch_download_report = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('manageFinishCategories')}
                                            checked={permissions.dispatch_manage_finish_categories}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_finish_categories = value;
                                                if (!value) {
                                                    permissions.dispatch_manage_all_acting_bodies_finish_categories = value;
                                                }
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('manageActingBodyFinishCategories')}
                                            checked={permissions.dispatch_manage_all_acting_bodies_finish_categories}
                                            setChecked={(value) => {
                                                permissions.dispatch_manage_all_acting_bodies_finish_categories = value;
                                                forceUpdate();
                                            }}
                                            disabled={!permissions.dispatch_manage_finish_categories}
                                        />
                                        <MyCheckbox
                                            label={translate('managePauseUnits')}
                                            checked={permissions.dispatch_pause_units}
                                            setChecked={(value) => {
                                                permissions.dispatch_pause_units = value;
                                                forceUpdate();
                                            }}
                                        />
                                    </View>
                                    <View style={styles.permissionColumn}>
                                        <MyAppText style={styles.permissionSubTitle}>{translate('lprModule')}</MyAppText>
                                        <MyCheckbox
                                            label={translate('fullModuleAccess')}
                                            checked={permissions.lpr_module_access}
                                            setChecked={(value) => {
                                                permissions.lpr_module_access = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('editLprDetection')}
                                            checked={permissions.lpr_edit_detection}
                                            setChecked={(value) => {
                                                permissions.lpr_edit_detection = value;
                                                forceUpdate();
                                            }}
                                        />
                                    </View>
                                    <View style={styles.permissionColumn}>
                                        <MyAppText style={styles.permissionSubTitle}>{translate('cameraModule')}</MyAppText>
                                        <MyCheckbox
                                            label={translate('cameraManagement')}
                                            checked={permissions.camera_management}
                                            setChecked={(value) => {
                                                permissions.camera_management = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('cameraCreateDownload')}
                                            checked={permissions.camera_download_video}
                                            setChecked={(value) => {
                                                permissions.camera_download_video = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('cameraDownloadVideo')}
                                            checked={permissions.camera_watch_download}
                                            setChecked={(value) => {
                                                permissions.camera_watch_download = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('cameraDownloadReport')}
                                            checked={permissions.camera_download_report}
                                            setChecked={(value) => {
                                                permissions.camera_download_report = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('managementPointsOfInterest')}
                                            checked={permissions.camera_management_point_of_interest}
                                            setChecked={(value) => {
                                                permissions.camera_management_point_of_interest = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('manageDownloadTags')}
                                            checked={permissions.camera_manage_download_tags}
                                            setChecked={(value) => {
                                                permissions.camera_manage_download_tags = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('createCameraMaintenanceRequests')}
                                            checked={permissions.create_camera_maintenance_requests}
                                            setChecked={(value) => {
                                                permissions.create_camera_maintenance_requests = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('createEmergencyCameraMaintenanceRequests')}
                                            checked={permissions.create_emergency_camera_maintenance_requests}
                                            setChecked={(value) => {
                                                permissions.create_emergency_camera_maintenance_requests = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('generateVideoAnalysis')}
                                            checked={permissions.camera_video_analysis}
                                            setChecked={(value) => {
                                                permissions.camera_video_analysis = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('watchVideoAnalysis')}
                                            checked={permissions.camera_watch_video_analysis}
                                            setChecked={(value) => {
                                                permissions.camera_watch_video_analysis = value;
                                                forceUpdate();
                                            }}
                                        />
                                    </View>
                                    <View style={styles.permissionColumn}>
                                        <MyAppText style={styles.permissionSubTitle}>{translate('accessModule')}</MyAppText>
                                        <MyCheckbox
                                            label={translate('editUserSettings')}
                                            checked={permissions.access_edit_user_settings}
                                            setChecked={(value) => {
                                                permissions.access_edit_user_settings = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('managementUserTags')}
                                            checked={permissions.access_management_user_tags}
                                            setChecked={(value) => {
                                                permissions.access_management_user_tags = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('editPermissionLevelSettings')}
                                            checked={permissions.access_edit_permission_level_settings}
                                            setChecked={(value) => {
                                                permissions.access_edit_permission_level_settings = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('editGroupSettings')}
                                            checked={permissions.access_edit_group_settings}
                                            setChecked={(value) => {
                                                permissions.access_edit_group_settings = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('editTurnstile')}
                                            checked={permissions.access_edit_turnstile}
                                            setChecked={(value) => {
                                                permissions.access_edit_turnstile = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('editPlatformSettings')}
                                            checked={permissions.access_edit_platform_settings}
                                            setChecked={(value) => {
                                                permissions.access_edit_platform_settings = value;
                                                forceUpdate();
                                            }}
                                        />
                                    </View>
                                </View>
                                <View style={styles.permissionMainColumn}>
                                    <View style={styles.permissionColumn}>
                                        <MyAppText style={styles.permissionSubTitle}>{translate('dashboardModule')}</MyAppText>
                                        <MyCheckbox
                                            label={translate('fullModuleAccess')}
                                            checked={permissions.dashboard_module_access}
                                            setChecked={(value) => {
                                                permissions.dashboard_module_access = value;
                                                forceUpdate();
                                            }}
                                        />
                                    </View>
                                    <View style={styles.permissionColumn}>
                                        <MyAppText style={styles.permissionSubTitle}>{translate('smartSearchModuleAccess')}</MyAppText>
                                        <MyCheckbox
                                            label={translate('fullModuleAccess')}
                                            checked={permissions.smart_search_module_access}
                                            setChecked={(value) => {
                                                permissions.smart_search_module_access = value;
                                                forceUpdate();
                                            }}
                                        />
                                    </View>
                                    <View style={styles.permissionColumn}>
                                        <MyAppText style={styles.permissionSubTitle}>{translate('logsModule')}</MyAppText>
                                        <MyCheckbox
                                            label={translate('fullModuleAccess')}
                                            checked={permissions.logs_module_access}
                                            setChecked={(value) => {
                                                permissions.logs_module_access = value;
                                                forceUpdate();
                                            }}
                                        />
                                    </View>
                                    <View style={styles.permissionColumn}>
                                        <MyAppText style={styles.permissionSubTitle}>{translate('crmModule')}</MyAppText>
                                        <MyCheckbox
                                            label={translate('fullModuleAccess')}
                                            checked={permissions.crm_module_access}
                                            setChecked={(value) => {
                                                permissions.crm_module_access = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('getCamerasPassword')}
                                            checked={permissions.get_camera_password}
                                            setChecked={(value) => {
                                                permissions.get_camera_password = value;
                                                forceUpdate();
                                            }}
                                        />
                                    </View>
                                    <View style={styles.permissionColumn}>
                                        <MyAppText style={styles.permissionSubTitle}>{translate('appAgentModule')}</MyAppText>
                                        <MyCheckbox
                                            label={translate('canSearchPeopleByPicture')}
                                            checked={permissions.search_people_by_picture}
                                            setChecked={(value) => {
                                                permissions.search_people_by_picture = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('canSearchPeopleOnCortex')}
                                            checked={permissions.search_people_cortex}
                                            setChecked={(value) => {
                                                permissions.search_people_cortex = value;
                                                forceUpdate();
                                            }}
                                        />
                                        <MyCheckbox
                                            label={translate('canReceiveOccurrences')}
                                            checked={permissions.receive_occurrences}
                                            setChecked={(value) => {
                                                permissions.receive_occurrences = value;
                                                forceUpdate();
                                            }}
                                        />
                                    </View>
                                    <View style={styles.permissionColumn}>
                                        <MyAppText style={styles.permissionSubTitle}>{translate('eventsModule')}</MyAppText>
                                        <MyCheckbox
                                            label={translate('fullModuleAccess')}
                                            checked={permissions.events_module_access}
                                            setChecked={(value) => {
                                                permissions.events_module_access = value;
                                                forceUpdate();
                                            }}
                                        />
                                    </View>
                                    <View style={styles.permissionColumn}>
                                        <MyAppText style={styles.permissionSubTitle}>{translate('guardianAppModule')}</MyAppText>
                                        <MyCheckbox
                                            label={translate('fullModuleAccess')}
                                            checked={permissions.guardian_app_module_access}
                                            setChecked={(value) => {
                                                permissions.guardian_app_module_access = value;
                                                forceUpdate();
                                            }}
                                        />
                                    </View>
                                </View>
                            </ScrollView>);
                    }
                }}
            </Tabs>
            <FormActions
                onSubmit={formSubmit}
                onClose={() => onClose(false)}
                disabled={!isFormValid()} />
        </View>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        permissionColumn: {
            flexDirection: 'column',
            gap: 5,
        },
        permissionMainColumn: {
            flex: 1,
            flexDirection: 'column',
            gap: 15,
            minWidth: 200
        },
        permissionSubTitle: {
            color: getThemedColor(theme, '#58595B'),
            fontWeight: 'bold',
            fontSize: 14,
        },
        formContainer: {
            minWidth: 300,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 2,
            padding: 20,
            height: 560,
            justifyContent: 'space-between',
            rowGap: 10
        },
        permissionsContainer: {
            rowGap: 20,
            flexDirection: 'row',
            flex: 1,
            flexWrap: 'wrap'
        },
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
    });
}
