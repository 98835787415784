import React from 'react';
import { View } from 'react-native';
import { useStyles, createStyleSheet } from 'react-native-unistyles';
import { translate } from '../../../services/translate';
import MyAppText from '../../../components/MyAppText';
import { ScrollView } from 'react-native-web-hover';

interface Params {
    isLoading: boolean;
    carFromPlate?: CarFromPlate;
}

export default function CarContext({ isLoading, carFromPlate }: Params) {
    const { styles } = useStyles(styleSheet);

    return (
        <ScrollView contentContainerStyle={{ gap: 5 }}>
            {!isLoading && carFromPlate &&
                <View key={carFromPlate.id} style={styles.cardBody}>
                    {carFromPlate.restricao.map(restrictionItem => (
                        <View key={restrictionItem.id} style={[styles.cardBody, styles.restriction]}>
                            <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataRestrictionPoliceReportYear')}:</MyAppText> {restrictionItem.anoBO}</MyAppText>
                            <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataRestrictionReportDate')}:</MyAppText> {restrictionItem.dataOcorrencia}</MyAppText>
                            <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataRestrictionContactAreaCode')}:</MyAppText> {restrictionItem.dddContato}</MyAppText>
                            <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataRestrictionHistoric')}:</MyAppText> {restrictionItem.historico}</MyAppText>
                            <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('id')}:</MyAppText> {restrictionItem.id}</MyAppText>
                            <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataRestrictionPoliceReportCity')}:</MyAppText> {restrictionItem.municipioBO}</MyAppText>
                            <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataRestrictionReportNature')}:</MyAppText> {restrictionItem.naturezaOcorrencia}</MyAppText>
                            <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataRestrictionDeclarantName')}:</MyAppText> {restrictionItem.nomeDeclarante}</MyAppText>
                            <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataRestrictionPoliceReportNumber')}:</MyAppText> {restrictionItem.numeroBO}</MyAppText>
                            <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('plate')}:</MyAppText> {restrictionItem.placa}</MyAppText>
                            <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataRestrictionPhoneExtension')}:</MyAppText> {restrictionItem.ramalContato}</MyAppText>
                            <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataRestrictionSystem')}:</MyAppText> {restrictionItem.sistema}</MyAppText>
                            <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('phone')}:</MyAppText> {restrictionItem.telefoneContato}</MyAppText>
                            <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataRestrictionPoliceReportState')}:</MyAppText> {restrictionItem.ufBO}</MyAppText>
                            <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataRestrictionPoliceUnit')}:</MyAppText> {restrictionItem.unidadePolicial}</MyAppText>
                        </View>
                    ))}
                    <View style={styles.cardBodySplitter}>
                        <MyAppText>{translate('carMetadataOwner')}:</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataDocumentType')}:</MyAppText> {carFromPlate.proprietario.tipoDocumentoProprietario}</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataDocument')}:</MyAppText> {carFromPlate.proprietario.numeroDocumentoProprietario}</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataName')}:</MyAppText> {carFromPlate.proprietario.nomeProprietario}</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataAddress')}:</MyAppText> {carFromPlate.proprietario.enderecoProprietario}</MyAppText>
                    </View>
                    <View style={styles.cardBodySplitter}>
                        <MyAppText>{translate('carMetadataPossessor')}:</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataDocumentType')}:</MyAppText> {carFromPlate.possuidor.tipoDocumentoPossuidor}</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataDocument')}:</MyAppText> {carFromPlate.possuidor.numeroDocumentoPossuidor}</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataName')}:</MyAppText> {carFromPlate.possuidor.nomePossuidor}</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataAddress')}:</MyAppText> {carFromPlate.possuidor.enderecoPossuidor}</MyAppText>
                    </View>
                    <View style={styles.cardBodySplitter}>
                        <MyAppText>{translate('carMetadataTenant')}:</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataDocumentType')}:</MyAppText> {carFromPlate.arrendatario.tipoDocumentoArrendatario}</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataDocument')}:</MyAppText> {carFromPlate.arrendatario.numeroDocumentoArrendatario}</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataName')}:</MyAppText> {carFromPlate.arrendatario.nomeArrendatario}</MyAppText>
                        <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataAddress')}:</MyAppText> {carFromPlate.arrendatario.enderecoArrendatario}</MyAppText>
                    </View>

                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('id')}:</MyAppText> {carFromPlate.id}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataUniqueVehicleIdentifier')}:</MyAppText> {carFromPlate.identificadorUnicoVeiculo}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataLicensePlate')}:</MyAppText> {carFromPlate.placa}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataPreMercosurPlate')}:</MyAppText> {carFromPlate.placaPreMercosul}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataChassis')}:</MyAppText> {carFromPlate.chassi}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataRegistrationState')}:</MyAppText> {carFromPlate.ufEmplacamento}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataPlateMunicipality')}:</MyAppText> {carFromPlate.municipioPlaca}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataRegistrationMunicipalityCode')}:</MyAppText> {carFromPlate.codigoMunicipioEmplacamento}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataRenavam')}:</MyAppText> {carFromPlate.renavam}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataVehicleJurisdictionState')}:</MyAppText> {carFromPlate.ufJurisdicaoVeiculo}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataPreRegistrationDate')}:</MyAppText> {carFromPlate.dataPreCadastro}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataRegistrationDate')}:</MyAppText> {carFromPlate.dataEmplacamento}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataVehicleUpdateDate')}:</MyAppText> {carFromPlate.dataAtualizacaoVeiculo}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataVehicleUpdateDateTime')}:</MyAppText> {carFromPlate.dataHoraAtualizacaoVeiculo}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataInvoicedDocumentType')}:</MyAppText> {carFromPlate.tipoDocumentoFaturado}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataInvoicedIdentificationNumber')}:</MyAppText> {carFromPlate.numeroIdentificacaoFaturado}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataInvoiceState')}:</MyAppText> {carFromPlate.ufFatura}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataOwnerDocumentType')}:</MyAppText> {carFromPlate.tipoDocumentoProprietario}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataVehicleManufacturingMonth')}:</MyAppText> {carFromPlate.mesFabricacaoVeiculo}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataInvoicedVehicleDestinationState')}:</MyAppText> {carFromPlate.ufDestinoVeiculoFaturado}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataImporterIdentificationNumber')}:</MyAppText> {carFromPlate.numeroIdentificacaoImportador}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataImportProcessNumber')}:</MyAppText> {carFromPlate.numeroProcessoImportacao}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataImportDeclarationNumber')}:</MyAppText> {carFromPlate.numeroDeclaracaoImportacao}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataImportDeclarationDate')}:</MyAppText> {carFromPlate.dataDeclaracaoImportacao}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataSrfAgencyCode')}:</MyAppText> {carFromPlate.codigoOrgaoSRF}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataTaxRegionAgencyDescription')}:</MyAppText> {carFromPlate.descricaoOrgaoRegiaoFiscal}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataTaxRestrictionDeadlineDate')}:</MyAppText> {carFromPlate.dataLimiteRestricaoTributaria}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataVehicleTransferCountry')}:</MyAppText> {carFromPlate.paisTransferenciaVeiculo}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataCustomsRecord')}:</MyAppText> {carFromPlate.registroAduaneiro}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataManufacturingYear')}:</MyAppText> {carFromPlate.anoFabricacao}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataModelYear')}:</MyAppText> {carFromPlate.anoModelo}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataMakeAndModel')}:</MyAppText> {carFromPlate.marcaModelo}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataMakeAndModelCode')}:</MyAppText> {carFromPlate.codigoMarcaModelo}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataVehicleGroup')}:</MyAppText> {carFromPlate.grupoVeiculo}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataVehicleType')}:</MyAppText> {carFromPlate.tipoVeiculo}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataVehicleTypeCode')}:</MyAppText> {carFromPlate.codigoTipoVeiculo}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataSpecies')}:</MyAppText> {carFromPlate.especie}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataSpeciesCode')}:</MyAppText> {carFromPlate.codigoEspecie}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataBody')}:</MyAppText> {carFromPlate.carroceria}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataBodyCode')}:</MyAppText> {carFromPlate.codigoCarroceira}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataColor')}:</MyAppText> {carFromPlate.cor}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataColorCode')}:</MyAppText> {carFromPlate.codigoCor}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataFuel')}:</MyAppText> {carFromPlate.combustivel}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataCategory')}:</MyAppText> {carFromPlate.categoria}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataCategoryCode')}:</MyAppText> {carFromPlate.codigoCategoria}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataAssemblyType')}:</MyAppText> {carFromPlate.tipoMontagem}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataPower')}:</MyAppText> {carFromPlate.potencia}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataCubicCapacity')}:</MyAppText> {carFromPlate.cilindrada}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataSeatingCapacity')}:</MyAppText> {carFromPlate.lotacao}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataMaximumLoadCapacity')}:</MyAppText> {carFromPlate.capacidadeMaximaCarga}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataGrossVehicleWeight')}:</MyAppText> {carFromPlate.pesoBrutoTotal}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataMaximumTowingCapacity')}:</MyAppText> {carFromPlate.capacidadeMaximaTracao}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataNationalVehicleIndicator')}:</MyAppText> {carFromPlate.indicadorVeiculoNacional}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataChassisRemarkingIndicator')}:</MyAppText> {carFromPlate.indicadorRemarcacaoChassi}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataBodyNumber')}:</MyAppText> {carFromPlate.numeroCarroceria}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataTransmissionBoxNumber')}:</MyAppText> {carFromPlate.numeroCaixaCambio}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataNumberOfAxles')}:</MyAppText> {carFromPlate.quantidadeEixo}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataRearAxleNumber')}:</MyAppText> {carFromPlate.numeroEixoTraseiro}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataAuxiliaryAxleNumber')}:</MyAppText> {carFromPlate.numeroEixoAuxiliar}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataEngineNumber')}:</MyAppText> {carFromPlate.numeroMotor}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataEngineConfigurationUsageLicenseNumber')}:</MyAppText> {carFromPlate.numeroLicencaUsoConfiguracaoVeiculosMotor}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataOwnerName')}:</MyAppText> {carFromPlate.nomePossuidor}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataOwnerOrigin')}:</MyAppText> {carFromPlate.origemPossuidor}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataProprietorName')}:</MyAppText> {carFromPlate.nomeProprietario}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataLesseeName')}:</MyAppText> {carFromPlate.nomeArrendatario}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataLastCRVIssuanceDate')}:</MyAppText> {carFromPlate.dataEmissaoUltimoCRV}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataCrvSequenceNumber')}:</MyAppText> {carFromPlate.numeroSequenciaCRV}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataCrvNumber')}:</MyAppText> {carFromPlate.numeroCRV}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataCrvCopyNumber')}:</MyAppText> {carFromPlate.numeroViaCRV}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataCrvSecurityCode')}:</MyAppText> {carFromPlate.codigoSegurancaCRV}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataCrvlTypeNumber')}:</MyAppText> {carFromPlate.numeroTipoCRLV}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataCrvlIssuanceDate')}:</MyAppText> {carFromPlate.dataEmissaoCRLV}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataCrvlCopyNumber')}:</MyAppText> {carFromPlate.numeroViaCRLV}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataLastLicensingYear')}:</MyAppText> {carFromPlate.anoUltimoLicenciamnento}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataLicensingValidityMonthYear')}:</MyAppText> {carFromPlate.mesAnoValidadeLicenciamento}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataIpvaValue')}:</MyAppText> {carFromPlate.valorIPVA}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataVehicleRestriction1')}:</MyAppText> {carFromPlate.restricaoVeiculo1}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataVehicleRestriction2')}:</MyAppText> {carFromPlate.restricaoVeiculo2}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataVehicleRestriction3')}:</MyAppText> {carFromPlate.restricaoVeiculo3}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataVehicleRestriction4')}:</MyAppText> {carFromPlate.restricaoVeiculo4}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataVehicleStatus')}:</MyAppText> {carFromPlate.situacaoVeiculo}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataReplicationDate')}:</MyAppText> {carFromPlate.dataReplicacao}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataActiveFlag')}:</MyAppText> {carFromPlate.flagAtivo}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataLicensedVehicleCirculationIndicator')}:</MyAppText> {carFromPlate.indicadorVeiculoLicenciadoCirculacao}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataTheftUpdateDate')}:</MyAppText> {carFromPlate.dataAtualizacaoRouboFurto}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataAlarmUpdateDate')}:</MyAppText> {carFromPlate.dataAtualizacaoAlarme}</MyAppText>
                    <MyAppText><MyAppText style={{ fontWeight: 'bold' }}>{translate('carMetadataNumberOfRegistrationRestrictions')}:</MyAppText> {carFromPlate.quantidadeRestricoesBaseEmplacamento}</MyAppText>
                </View>
            }
        </ScrollView>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    cardBody: {
        gap: 10,
        backgroundColor: theme.colors.backgroundColor,
        padding: 10,
        borderColor: theme.colors.disabledButtonBackground,
        borderWidth: 1,
        borderRadius: 4,
    },
    cardBodySplitter: {
        paddingBottom: 10,
        paddingLeft: 10,
    },
    restriction: {
        borderColor: 'red'
    }
}));
