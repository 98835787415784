import React, { useState, useEffect, useContext } from 'react';
import { View, TouchableOpacity, StyleSheet, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from '../../../services/translate';
import { faPlus, faEdit, faShield } from '@fortawesome/free-solid-svg-icons';
import DefaultPageContainer from '../../../components/DefaultPageContainer';
import DefaultPageLayout from '../../../components/DefaultPageLayout';
import MyAppText from '../../../components/MyAppText';
import moment from 'moment';
import getThemedColor from '../../../services/get-themed-color';
import ThemeContext from '../../../context/Theme';
import { Pagination } from '../../../components/Pagination';
import { dispatchService } from '../../../services/central-api/dispatch';
import MyDataTable from '../../../components/MyDataTable';
import { RouteProp, useRoute } from '@react-navigation/native';
import { DispatchParamList } from '../../../typings/Params';
import { TextMask } from 'react-native-masked-text';
import useFilters from '../../../components/Filter/hooks/useFilters';
import OccurrencesFilter from './Filters';
import FilterInput from '../../../components/Filter/components/FilterInput';

const limit = 25;

export default function Occurrences({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const route = useRoute<RouteProp<DispatchParamList, 'Occurrences'>>();

    const [occurrences, setOccurrences] = useState<PaginatedOccurrence[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pageCount, setPageCount] = useState(1);

    const [filters, setFilters] = useFilters<DispatchParamList['Occurrences']>({ ...route.params, page: Math.max(+route.params.page - 1, 0) });

    const [textFilter, setTextFilter] = useState(filters.textFilter ?? '');

    async function getOccurrences(): Promise<void> {
        try {
            setIsLoading(true);
            const response = await dispatchService.getOccurrences({
                ...filters, limit
            });
            setOccurrences(response.rows);
            setPageCount(Math.ceil(response.count / limit));

        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getOccurrences();
    }, [filters]);

    function getTitle(occurrence: PaginatedOccurrence) {
        if (occurrence.triggerType == null && occurrence.occurrenceType) {
            return occurrence.occurrenceType.name;
        } else if (occurrence.triggerType == 'face_detected') {
            return translate('faceDetectedAlert');
        } else if (occurrence.triggerType == 'mp_face_detected') {
            return translate('mpFaceDetectedAlert');
        } else if (occurrence.triggerType == 'plate_detected') {
            return translate('plateDetectedAlert');
        } else if (occurrence.triggerType == 'mp_plate_detected') {
            return translate('mpPlateDetectedAlert');
        } else if (occurrence.triggerType == 'irregular_vehicle') {
            return translate('irregular_vehicle');
        } else if (occurrence.triggerType == 'area_invasion') {
            return translate('area_invasion');
        } else if (occurrence.triggerType == 'alarm_center') {
            return translate('alarmCenterAlert');
        } else if (occurrence.triggerType == 'guardian_app') {
            return translate('guardianAppAlert');
        } else if (['scenechangedetection', 'defocus'].includes(occurrence.triggerType || '')) {
            return translate('cameraDepredationSuspect');
        }
        return '';
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            setFilters(old => ({
                ...old,
                textFilter
            }));
        }
    }

    const renderUnitContent = (row: PaginatedOccurrence) => {
        const renderUnitInfo = () => {
            if (!row.OccurrenceUnits || row.OccurrenceUnits.length === 0) return null;
            return row.OccurrenceUnits.map((unit, i) => {

                return (
                    <View key={i} style={{ flexDirection: 'row', alignItems: 'center', columnGap: 10 }}>
                        <FontAwesomeIcon icon={faShield} fontSize={16} color={getThemedColor(theme, '#888888')} />
                        <MyAppText>{unit.unitName.name}</MyAppText> / <MyAppText>{unit.actingBodyUnit.name}</MyAppText>
                    </View>
                );
            });
        };

        return (
            <View style={{ paddingVertical: 10, paddingLeft: 50, flexDirection: 'column', rowGap: 10 }}>
                {renderUnitInfo()}
            </View>
        );
    };

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} lateralMenu={'dispatch'} selectedMenu='occurrences' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ width: 500 }}>
                            <FilterInput
                                label={translate('searchOccurrence')}
                                value={textFilter ?? ''}
                                onChange={text => {
                                    setTextFilter(text);
                                }}
                                placeholder={translate('typeToSearch')}
                                onKeyPress={handleKeyDown}

                            />
                        </View>

                        <View style={{ flex: 1 }}></View>

                        <View style={styles.buttonsContainer}>
                            <OccurrencesFilter
                                authenticatedUser={authenticatedUser}
                                filters={filters}
                                setFilters={setFilters}
                                textFilter={textFilter}
                                setTextFilter={setTextFilter} />

                            <TouchableOpacity onPress={() => navigation.navigate('DispatchOccurrenceDetail')} style={styles.new}>
                                <FontAwesomeIcon icon={faPlus} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('create')}</MyAppText>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.cardList}>
                        <MyDataTable
                            columns={[
                                {
                                    name: translate('event'),
                                    selector: row => row.id || '',
                                    grow: 0
                                },
                                {
                                    name: translate('requester'),
                                    selector: row => row.triggerType == null && row.requester ? row.requester : '',
                                    wrap: true,
                                    grow: 2
                                },
                                {
                                    name: translate('phone'),
                                    cell: row => <View style={{ width: '100%' }}>
                                        <TextMask
                                            style={{ color: getThemedColor(theme, '#222222'), fontFamily: 'Open Sans' }}
                                            type={'cel-phone'}
                                            value={row.triggerType == null && row.phone ? row.phone : ''} />
                                    </View>,
                                },
                                {
                                    name: translate('type'),
                                    wrap: true,
                                    grow: 2,
                                    selector: row => getTitle(row),
                                },
                                {
                                    name: translate('priority'),
                                    wrap: true,
                                    cell: row => <MyAppText style={{ color: row.priority === 'maximum' ? '#FF0027' : undefined }}>
                                        {row.priority ? translate(row.priority) : ''}
                                    </MyAppText>,
                                },
                                {
                                    name: translate('start_date'),
                                    selector: row => row?.createdAt ? moment(row?.createdAt).format('DD/MM/YYYY HH:mm:ss') : '',
                                    wrap: true
                                },
                                {
                                    name: translate('end_date'),
                                    selector: row => row?.finishedAt ? moment(row?.finishedAt).format('DD/MM/YYYY HH:mm:ss') : '',
                                    wrap: true
                                },
                                {
                                    name: translate('status'),
                                    cell: row =>
                                        <View style={styles.status}>
                                            <View style={[styles.circle, (row.situation == 'concluded' ? styles.red : styles.green)]} />
                                            <MyAppText>{translate(row.situation)}</MyAppText>
                                        </View>
                                },
                                {
                                    name: translate('tags'),
                                    cell: row =>
                                        <View style={{ flexDirection: 'row', flexWrap: 'wrap', width: '100%', gap: 5, paddingVertical: 5 }}>
                                            {row.tags ?
                                                row.tags.map((tag) =>
                                                    <View key={tag.id} style={[styles.marker, { backgroundColor: tag.color }]}>
                                                        <MyAppText style={[styles.markerText]}>
                                                            {tag.name}
                                                        </MyAppText>
                                                    </View>
                                                ) : null
                                            }</View>
                                },
                                {
                                    name: translate('action'),
                                    button: true,
                                    cell: row =>
                                        <TouchableOpacity onPress={() => navigation.navigate('DispatchOccurrenceDetail', { occurrenceId: row.id })}>
                                            <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                        </TouchableOpacity>
                                }
                            ]}
                            data={occurrences}
                            expandableRowsComponent={({ data }) => renderUnitContent(data)}
                            expandableRows
                            progressPending={isLoading}
                        />
                    </View>
                    <Pagination currentPage={filters.page} totalPages={pageCount} setPage={page => {
                        setFilters(old => ({
                            ...old,
                            page
                        }));

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }} />

                </View>
            </DefaultPageLayout>
        </DefaultPageContainer>
    );
}
function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            minHeight: 65,
            zIndex: 2,
            flexWrap: 'wrap',
            gap: 5,
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
        icon: {
            fontSize: 25,
            color: getThemedColor(theme, '#000028')
        },
        circle: {
            width: 15,
            height: 15,
            borderRadius: 7.5,
        },
        red: {
            backgroundColor: 'red',
        },
        green: {
            backgroundColor: 'green',
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1
        },
        filterText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
            paddingBottom: 5
        },
        status: {
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 10,
            width: '100%'
        },
        marker: {
            borderRadius: 8,
            minHeight: 16,
            paddingLeft: 10,
            paddingRight: 10,
            maxWidth: '100%'
        },
        markerText: {
            fontWeight: '500',
            color: getThemedColor(theme, '#000000'),
            fontSize: 11
        },
        buttonsContainer: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            gap: 10,
        },
    });
}
