import React, { useState } from 'react';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';

import { translate } from '../../../services/translate';
import CustomButton from '../../../components/CustomButton';
import CustomTextFilter from '../../../components/CustomTextFilter';
import { GenericPaginatedParameters } from '../../../services/central-api/base-service';

interface Props {
    onFilter: (filterValues: Omit<GenericPaginatedParameters, 'limit' | 'page'>) => void;
    onCreate: () => void;
    filterValues: Omit<GenericPaginatedParameters, 'limit' | 'page'>;
}

function Filters({ onFilter, filterValues, onCreate }: Props) {
    const { styles } = useStyles(styleSheet);

    const [textFilter, setTextFilter] = useState<string | undefined>(filterValues.textFilter);

    const handleFilters = () => {
        const formattedFilters: Omit<GenericPaginatedParameters, 'limit' | 'page'> = {
            textFilter
        };

        onFilter(formattedFilters);
    };

    return (
        <View style={styles.header}>
            <CustomTextFilter
                textFilter={textFilter}
                setTextFilter={setTextFilter}
                title={'searchRegisteredWoman'}
            />
            <View style={styles.buttonsContainer}>
                <CustomButton
                    icon={faSearch}
                    onPress={() => {
                        handleFilters();
                    }}
                    text={translate('toSearch')}
                />
                <CustomButton
                    icon={faPlus}
                    onPress={() => {
                        onCreate();
                    }}
                    text={translate('create')}
                />
            </View>
        </View>
    );
}

const styleSheet = createStyleSheet(() => ({
    header: {
        flexDirection: {
            xs: 'column',
            md: 'row'
        },
        flexWrap: 'wrap',
        columnGap: 30,
        rowGap: 10,
        justifyContent: 'space-between'
    },
    buttonsContainer: {
        alignItems: 'flex-end',
        flexDirection: 'row',
        gap: 10
    },
}));

export default Filters;
