import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, ActivityIndicator, TextInput, TouchableOpacity } from 'react-native';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { translate } from '../../services/translate';
import MyAppText from '../../components/MyAppText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Toast from 'react-native-toast-message';
import { cameraService } from '../../services/central-api/cameras';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { ClientError } from '../../services/central-api/base-service';
import { ScrollView } from 'react-native-web-hover';

interface CameraDetailsParameters {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
    route: {
        params: {
            id: string;
        };
    };
}

export default function CameraDetails({ navigation, route, authenticatedUser }: CameraDetailsParameters) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    const [isLoading, setIsLoading] = useState(false);
    const [alreadyLoaded, setAlreadyLoaded] = useState(false);
    const [isEditingMarkers, setIsEditingMarkers] = useState(false);
    const [tags, setTags] = useState<Tag[]>([]);
    const [canEditFields, setCanEditFields] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    // Third party cameras cannot be edited beside tags
    const [isThirdParty, setIsThirdParty] = useState<boolean>(false);

    // fields
    const [title, setTitle] = useState('');
    const [address, setAddress] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [cameraTags, setCameraTags] = useState<Tag[]>([]);

    async function getCamera(id: string) {
        try {
            setIsLoading(true);
            const camera = await cameraService.getCamera(id);
            setTitle(camera.title);
            setAddress(camera.address);
            setLatitude(String(camera.latitude));
            setLongitude(String(camera.longitude));
            setCameraTags(camera.tags || []);
            setIsThirdParty(camera.thirdPartyCamera ? true : false);
        } catch (err) {
            console.error(err);
        } finally {
            setAlreadyLoaded(true);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (authenticatedUser) {
            setCanEditFields(authenticatedUser.isAdmin || authenticatedUser.permissions.camera_management);
        }
    }, [authenticatedUser]);

    useEffect(() => {
        if (route.params.id && !alreadyLoaded) {
            getCamera(route.params.id);
        }
    }, [route.params]);

    async function getTags() {
        try {
            setIsLoading(true);
            const tags = await cameraService.getCameraTags();
            setTags(tags);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getTags();
    }, []);

    function removeCameraTag(tag: Tag) {
        const index = cameraTags.indexOf(tag);
        if (index > -1) {
            cameraTags.splice(index, 1);
        }
        setCameraTags([...cameraTags]);
    }

    function addCameraTag(tag: Tag) {
        cameraTags.push(tag);
        setCameraTags([...cameraTags]);
    }

    useEffect(() => {
        if (title === '') {
            return setIsFormValid(false);
        }
        if (address === '') {
            return setIsFormValid(false);
        }
        if (latitude.trim() === '' || Number.isNaN(Number(latitude))) {
            return setIsFormValid(false);
        }
        if (longitude.trim() === '' || Number.isNaN(Number(longitude))) {
            return setIsFormValid(false);
        }
        return setIsFormValid(true);
    }, [title, address, latitude, longitude]);


    async function save() {
        try {
            setIsLoading(true);
            await cameraService.updateCamera(route.params.id, {
                title,
                address,
                latitude: Number(latitude),
                longitude: Number(longitude),
                tags: cameraTags.map((tag) => tag.id || 0)
            });
            navigation.navigate('CamerasList');
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='cameras' lateralMenu='cameras' contentContainerStyle={{ padding: 10 }} authenticatedUser={authenticatedUser}>
                <View style={styles.container}>
                    <View style={styles.header}>
                        <View style={{ alignItems: 'center', flexDirection: 'row', flexGrow: 1, minWidth: 200, columnGap: 10 }}>
                            <TouchableOpacity onPress={() => navigation.canGoBack() ? navigation.goBack() : navigation.navigate('CamerasList')}>
                                <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={getThemedColor(theme, '#000028')} />
                            </TouchableOpacity>
                            <MyAppText style={{ fontSize: 24, color: getThemedColor(theme, '#58595B'), fontWeight: 'bold' }}>
                                {translate('camera')}
                            </MyAppText>
                        </View>
                        <TouchableOpacity
                            onPress={() => {
                                window.open(`/cameras/${route.params.id}`);
                            }}
                            style={[{
                                borderWidth: 1,
                                borderRadius: 2,
                                borderColor: getThemedColor(theme, '#888888'),
                                backgroundColor: getThemedColor(theme, '#FFFFFF'),
                                width: 180,
                                height: 40,
                                justifyContent: 'center',
                            }]}
                        >
                            <MyAppText style={[{ color: getThemedColor(theme, '#222222'), alignSelf: 'center', fontSize: 16 }]}>
                                {translate('watchCamera')}
                            </MyAppText>
                        </TouchableOpacity>

                        {canEditFields ?
                            <TouchableOpacity
                                onPress={save}
                                disabled={!isFormValid}
                                style={[{
                                    borderWidth: 0,
                                    borderRadius: 2,
                                    backgroundColor: getThemedColor(theme, '#000028'),
                                    width: 128,
                                    height: 40,
                                    justifyContent: 'center',
                                }, !isFormValid ? styles.buttonDisabled : null]}
                            >
                                <MyAppText style={[{ color: getThemedColor(theme, '#FFFFFF'), alignSelf: 'center', fontSize: 16 }, !isFormValid ? styles.textDisabled : null]}>
                                    {translate('save')}
                                </MyAppText>
                            </TouchableOpacity>
                            : null
                        }
                    </View>
                    <View style={styles.cardList}>
                        <ScrollView>
                            {isLoading &&
                                <ActivityIndicator size='small' color={getThemedColor(theme, '#000000')} style={{ position: 'absolute', top: 30, right: 20 }} />
                            }

                            <View style={{ alignItems: 'flex-start' }}>
                                <View style={{ rowGap: 15 }}>
                                    {isThirdParty ?
                                        <MyAppText style={{ fontSize: 12 }}>{translate('thirdPartyCameraWarning')}</MyAppText> : null
                                    }
                                    <View style={styles.generalInformationContainer}>
                                        <MyAppText style={styles.generalInformationTitleText}>
                                            {translate('title')}
                                        </MyAppText>
                                        <TextInput
                                            placeholder=''
                                            editable={canEditFields && !isThirdParty}
                                            style={[styles.generalInformationInput, !canEditFields ? styles.disabled : null]}
                                            value={title}
                                            onChangeText={setTitle}
                                        />
                                    </View>
                                    <View style={styles.generalInformationContainer}>
                                        <MyAppText style={styles.generalInformationTitleText}>
                                            {translate('address')}
                                        </MyAppText>
                                        <TextInput
                                            placeholder=''
                                            editable={canEditFields && !isThirdParty}
                                            style={[styles.generalInformationInput, !canEditFields ? styles.disabled : null]}
                                            value={address}
                                            onChangeText={setAddress}
                                        />
                                    </View>
                                    <View style={styles.generalInformationContainer}>
                                        <MyAppText style={styles.generalInformationTitleText}>
                                            {translate('latitude')}
                                        </MyAppText>
                                        <TextInput
                                            placeholder=''
                                            editable={canEditFields && !isThirdParty}
                                            style={[styles.generalInformationInput, !canEditFields ? styles.disabled : null]}
                                            value={latitude}
                                            onChangeText={setLatitude}
                                        />
                                    </View>
                                    <View style={styles.generalInformationContainer}>
                                        <MyAppText style={styles.generalInformationTitleText}>
                                            {translate('longitude')}
                                        </MyAppText>
                                        <TextInput
                                            placeholder=''
                                            editable={canEditFields && !isThirdParty}
                                            style={[styles.generalInformationInput, !canEditFields ? styles.disabled : null]}
                                            value={longitude}
                                            onChangeText={setLongitude}
                                        />
                                    </View>
                                    <View style={styles.generalInformationContainer}>

                                        <MyAppText style={styles.generalInformationTitleText}>
                                            {translate('markAs')}
                                        </MyAppText>

                                        <View style={[{
                                            borderColor: 'grey',
                                            borderWidth: 1,
                                            borderRadius: 5,
                                            minWidth: 376
                                        }]}>

                                            {cameraTags.length > 0 &&

                                                <View
                                                    style={[{
                                                        flexDirection: 'row',
                                                        padding: 10,
                                                        borderRadius: 5,
                                                    }]}>

                                                    {cameraTags.map((tag) =>
                                                        <TouchableOpacity
                                                            key={tag.id}
                                                            style={[styles.marker, { backgroundColor: tag.color }]}
                                                            onPress={async () => {
                                                                if (!isEditingMarkers) {
                                                                    return;
                                                                }
                                                                removeCameraTag(tag);
                                                            }
                                                            }>
                                                            <MyAppText style={styles.markerText}>
                                                                {tag.name}
                                                            </MyAppText>
                                                        </TouchableOpacity>
                                                    )}
                                                </View>
                                            }

                                            <View
                                                style={[{
                                                    padding: 10,
                                                    borderRadius: 5,
                                                    marginTop: -5,
                                                }]}>

                                                {canEditFields && !isEditingMarkers ?
                                                    <TouchableOpacity onPress={() => setIsEditingMarkers(true)}>
                                                        <MyAppText style={{
                                                            fontWeight: '500', color: 'grey', textDecorationLine: 'underline'
                                                        }}>
                                                            {translate('editTags')}
                                                        </MyAppText>
                                                    </TouchableOpacity>
                                                    : null
                                                }
                                                {canEditFields && isEditingMarkers ?
                                                    tags.map((tag) =>
                                                        cameraTags.map((item) => item.id).includes(tag.id) ?
                                                            null :
                                                            <TouchableOpacity key={tag.id} onPress={async () => {
                                                                addCameraTag(tag);
                                                            }}>
                                                                <MyAppText style={{ fontWeight: '500', color: 'grey' }}>
                                                                    {tag.name}
                                                                </MyAppText>
                                                            </TouchableOpacity>
                                                    )
                                                    : null
                                                }
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </ScrollView>
                    </View>
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        generalInformationContainer: {
            flexDirection: 'row',
            marginBottom: 10
        },
        container: {
            flex: 1,
            rowGap: 15
        },
        header: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
            flexWrap: 'wrap',
            minHeight: 65
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            padding: 24,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flex: 1,
            flexDirection: 'row',
        },
        disabled: {
            backgroundColor: getThemedColor(theme, '#EEEEEE')
        },
        generalInformationTitleText: {
            width: 260,
            textAlign: 'right',
            marginRight: 15,
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            marginTop: 10
        },
        generalInformationInput: {
            width: 376,
            padding: 10,
            borderColor: getThemedColor(theme, '#888888'),
            borderWidth: 1,
            height: 40,
            borderRadius: 5,
            // font
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            fontFamily: 'Open Sans',
        },
        marker: {
            borderRadius: 8,
            height: 16,
            paddingLeft: 10,
            paddingRight: 10,
            marginRight: 8,
        },
        markerText: {
            fontWeight: '500',
            color: getThemedColor(theme, '#000000'),
            fontSize: 11
        },
        buttonDisabled: {
            borderWidth: 0,
            backgroundColor: getThemedColor(theme, '#CCCCCC')
        },
        textDisabled: {
            color: getThemedColor(theme, '#888888')
        }
    });
}
