import React, { useState } from 'react';
import Filters from '../../../components/Filter';
import FilterInput from '../../../components/Filter/components/FilterInput';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';
import { translate } from '../../../services/translate';
import { NavigationProp, RouteProp, useNavigation } from '@react-navigation/native';
import { DispatchParamList } from '../../../typings/Params';
import FilterDateInput from '../../../components/Filter/components/FilterDateInput';
import ActingBodiesDropdown from './ActingBodiesDropdown';
import ActingBodiesUnitsDropdown from './ActingBodiesUnitsDropdown';
import moment from 'moment';
import ActingBodiesCommandsDropdown from './ActingBodiesCommandsDropdown';

interface Props {
    filters: DispatchParamList['Units'];
    setFilters: React.Dispatch<React.SetStateAction<DispatchParamList['Units']>>;
    authenticatedUser?: AuthenticatedUser;
    teamFilter: string;
    setTeamFilter: (value: string) => void;
}

function UnitsFilter({ authenticatedUser, filters, setFilters, teamFilter, setTeamFilter }: Props) {
    const navigation = useNavigation<NavigationProp<RouteProp<DispatchParamList, 'Units'>>>();

    const [agentFilter, setAgentFilter] = useState(filters.agentFilter ?? '');
    const [status, setStatus] = useState<string>(filters.status ?? '');
    const [beginInDateFilter, setBeginInDateFilter] = useState(filters.beginIn ? moment(filters.beginIn).format('DD/MM/YYYY') : '');
    const [beginInHourFilter, setBeginInHourFilter] = useState(filters.beginIn ? moment(filters.beginIn).format('HH:mm') : '');
    const [endInDateFilter, setEndInDateFilter] = useState(filters.endIn ? moment(filters.endIn).format('DD/MM/YYYY') : '');
    const [endInHourFilter, setEndInHourFilter] = useState(filters.endIn ? moment(filters.endIn).format('HH:mm') : '');
    const [actingBodyId, setActingBodyId] = useState<number>(filters.actingBodyId ?? 0);
    const [actingBodyUnitIds, setActingBodyUnitIds] = useState<number[]>(filters.actingBodyUnitIds ?? []);
    const [actingBodyCommandIds, setActingBodyCommandIds] = useState<number[]>(filters.actingBodyCommandIds ?? []);

    const handleCleanFilter = () => {
        setAgentFilter('');
        setStatus('');
        setTeamFilter('');
        setActingBodyId(0);
        setActingBodyUnitIds([]);
        setActingBodyCommandIds([]);
        setBeginInDateFilter('');
        setBeginInHourFilter('');
        setEndInDateFilter('');
        setEndInHourFilter('');
    };

    const handleFilter = () => {
        const beginIn = moment(`${beginInDateFilter} ${beginInHourFilter}`, 'DD/MM/YYYY HH:mm').valueOf();
        const endIn = moment(endInHourFilter ? `${endInDateFilter} ${endInHourFilter}:59` : `${endInDateFilter} 23:59:59`, 'DD/MM/YYYY HH:mm:ss').valueOf();

        navigation.setParams({
            page: 1,
            status,
            actingBodyId: actingBodyId > 0 ? actingBodyId : undefined,
            actingBodyUnitIds: actingBodyUnitIds.length ? actingBodyUnitIds : undefined,
            actingBodyCommandIds: actingBodyCommandIds.length ? actingBodyCommandIds : undefined,
            agentFilter,
            teamFilter,
            beginIn: beginIn ? new Date(beginIn).toString() : undefined,
            endIn: endIn ? new Date(endIn).toString() : undefined,
        });

        setFilters({
            page: 1,
            status,
            actingBodyId: actingBodyId > 0 ? actingBodyId : undefined,
            actingBodyUnitIds: actingBodyUnitIds.length ? actingBodyUnitIds : undefined,
            actingBodyCommandIds: actingBodyCommandIds.length ? actingBodyCommandIds : undefined,
            agentFilter,
            teamFilter,
            beginIn: beginIn ? new Date(beginIn).toString() : undefined,
            endIn: endIn ? new Date(endIn).toString() : undefined,
        });
    };

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <FilterInput
                label={translate('searchUnit')}
                value={teamFilter}
                placeholder={translate('typeToSearch')}
                onChange={text => {
                    setTeamFilter(text);
                }}
            />

            <FilterInput
                label={translate('searchAgent')}
                value={agentFilter}
                placeholder={translate('typeToSearch')}
                onChange={text => {
                    setAgentFilter(text);
                }}
            />

            <FilterDropdown
                label={translate('status')}
                items={['',
                    'active',
                    'displaced',
                    'operationalPause',
                    'waitingActivation',
                    'waitingActivationBeforeSchedule',
                    'activeAfterSchedule',
                    'finished'
                ].map((item: string) => {
                    return {
                        label: item ? translate(item) : item, value: item
                    };
                })}
                multiple={false}
                setValue={setStatus}
                value={status}
                zIndex={1000}
            />

            <FilterDateInput
                label={translate('beginIn')}
                valueDate={beginInDateFilter}
                valueHour={beginInHourFilter}
                onChangeDate={(value) => {
                    setBeginInDateFilter(value);
                }}
                onChangeHour={value => {
                    setBeginInHourFilter(value);
                }}
            />

            <FilterDateInput
                label={translate('endIn')}
                valueDate={endInDateFilter}
                valueHour={endInHourFilter}
                onChangeDate={value => {
                    setEndInDateFilter(value);
                }}
                onChangeHour={value => {
                    setEndInHourFilter(value);
                }}
            />

            {authenticatedUser?.isAdmin &&
                <ActingBodiesDropdown value={actingBodyId} setValue={setActingBodyId} includeAllOption={true} zIndex={4} disabled={false} />
            }

            {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_acting_body_units) &&
                <ActingBodiesUnitsDropdown
                    value={actingBodyUnitIds}
                    actingBodyId={authenticatedUser?.isAdmin ? actingBodyId : authenticatedUser?.actingBodyUnit.actingBodyId ?? 0}
                    setValue={setActingBodyUnitIds}
                    zIndex={3}
                />
            }
            <ActingBodiesCommandsDropdown
                value={actingBodyCommandIds}
                actingBodyId={authenticatedUser?.isAdmin ? actingBodyId : authenticatedUser?.actingBodyUnit.actingBodyId ?? 0}
                setValue={setActingBodyCommandIds}
                zIndex={2}
            />
        </Filters>
    );
}


export default UnitsFilter;
