import React, { useRef } from 'react';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyAppText from '../../MyAppText';
import { TextInputMask, TextInputMaskMethods } from 'react-native-masked-text';

interface Props {
    label: string;
    valueDate?: string;
    valueHour?: string;
    onChangeDate?: (text: string) => void;
    onChangeHour?: (text: string) => void;
}

type InputMask = TextInputMask & TextInputMaskMethods;

function FilterDateInput({ label, valueDate, valueHour, onChangeDate, onChangeHour }: Props) {
    const { styles, theme } = useStyles(stylesheet);
    const dateInputRef = useRef<InputMask>(null);
    const hourInputRef = useRef<InputMask>(null);

    return (
        <View style={{ flexGrow: 1, minWidth: 200 }}>
            <MyAppText style={styles.filterText}>{label}</MyAppText>
            <View style={{ flexDirection: 'row', columnGap: 5 }}>
                <TextInputMask
                    value={valueDate}
                    onChangeText={(value: string) => {
                        onChangeDate?.(value);
                    }}
                    keyboardType={'numeric'}
                    type={'datetime'}
                    style={[styles.filterInput, { width: '70%', color: valueDate != '' ? theme.colors.textColor : theme.colors.disabledText }]}
                    options={{
                        format: 'DD/MM/YYYY'
                    }}
                    ref={dateInputRef}
                    placeholder='__/__/____'
                />
                <TextInputMask
                    value={valueHour}
                    onChangeText={(value) => onChangeHour?.(value)}
                    keyboardType={'numeric'}
                    type={'datetime'}
                    style={[styles.filterInput, { width: '30%', color: valueHour != '' ? theme.colors.textColor : theme.colors.disabledText }]}
                    options={{
                        format: 'HH:mm'
                    }}
                    ref={hourInputRef}
                    placeholder='00:00'
                />
            </View>
        </View>
    );
}

const stylesheet = createStyleSheet(theme => ({
    inputContainer: {
        flexGrow: 1,
        minWidth: 200,
    },

    filterText: {
        color: theme.colors.labelColor,
        fontSize: 16,
        height: 25,
    },

    filterInput: {
        fontFamily: 'Open Sans',
        backgroundColor: theme.colors.backgroundColor,
        borderWidth: 1,
        borderRadius: 4,
        borderColor: theme.colors.borderColor,
        height: 40,
        minHeight: 40,
        fontSize: 16,
        color: theme.colors.fieldColor,
        padding: 10
    }
}));

export default FilterDateInput;
