import React, { useCallback, useContext, useEffect, useState } from 'react';
import { TouchableOpacity, View, StyleSheet, TextInput, ScrollView } from 'react-native';
import MyAppText from './MyAppText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ThemeContext from '../context/Theme';
import getThemedColor from '../services/get-themed-color';

export interface SelectableItem {
    id: number | string;
    name: string;
}

interface SelectableListParameters {
    selectableLabel: string;
    selectedLabel: string;
    selectableContent: SelectableItem[];
    selectedContent: SelectableItem[];
    displayFilter?: string[];
}

function removeItem<T>(arr: Array<T>, value: T): Array<T> {
    const index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}

export function SelectableList({ selectableLabel, selectedLabel, selectableContent, selectedContent, displayFilter = undefined }: SelectableListParameters) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);
    const [filteredSelectableContent, setFilteredSelectableContent] = useState<SelectableItem[]>([]);
    const [filteredSelectedContent, setFilteredSelectedContent] = useState<SelectableItem[]>([]);
    const [filteredSelectableTerm, setFilteredSelectableTerm] = useState<string>('');
    const [filteredSelectedTerm, setFilteredSelectedTerm] = useState<string>('');

    useEffect(() => {
        setFilteredSelectableContent([...selectableContent]);
    }, [selectableContent]);

    useEffect(() => {
        setFilteredSelectedContent([...selectedContent]);
    }, [selectedContent]);

    useEffect(() => {
        const filteredSelectableContent = [];
        const filterValue = filteredSelectableTerm.toUpperCase();
        for (const item of selectableContent) {
            if (item.name.toUpperCase().includes(filterValue) && (!displayFilter || displayFilter.includes(item?.id?.toString() || '0'))) {
                filteredSelectableContent.push(item);
            }
        }
        setFilteredSelectableContent(filteredSelectableContent);
    }, [filteredSelectableTerm]);

    useEffect(() => {
        const filteredSelectedContent = [];
        const filterValue = filteredSelectedTerm.toUpperCase();
        for (const item of selectedContent) {
            if (item.name.toUpperCase().includes(filterValue) && (!displayFilter || displayFilter.includes(item?.id?.toString() || '0'))) {
                filteredSelectedContent.push(item);
            }
        }
        setFilteredSelectedContent(filteredSelectedContent);
    }, [filteredSelectedTerm]);

    useEffect(() => {
        if (!displayFilter) return;

        setFilteredSelectableContent(selectableContent.filter((item) => {
            return displayFilter.includes(item?.id?.toString() || '0');
        }));
        setFilteredSelectedContent(selectedContent.filter((item) => {
            return displayFilter.includes(item?.id?.toString() || '0');
        }));

    }, [displayFilter]);

    return (
        <View style={styles.container}>
            <View style={{ flex: 1, rowGap: 5 }}>
                <MyAppText style={styles.tableHeaderText}>{selectableLabel}</MyAppText>
                <View style={styles.table}>
                    <TextInput style={styles.input} onChangeText={setFilteredSelectableTerm} />
                    <ScrollView>
                        <View style={{ gap: 5 }}>
                            {filteredSelectableContent.map((element, elementIndex) => (
                                <View key={elementIndex} style={{ gap: 5, paddingRight: 10 }}>
                                    <TouchableOpacity onPress={() => {
                                        selectedContent.push(element);
                                        filteredSelectedContent.push(element);
                                        removeItem(selectableContent, element);
                                        removeItem(filteredSelectableContent, element);
                                        forceUpdate();
                                    }}>
                                        <View style={styles.tableItem}>
                                            <MyAppText style={styles.tableItemText}>{element.name}</MyAppText>
                                            <FontAwesomeIcon fontSize={18} color={getThemedColor(theme, '#58595B')} icon={faAngleRight} />
                                        </View>
                                    </TouchableOpacity>
                                    <View style={styles.tableLine}></View>
                                </View>
                            ))}
                        </View>
                    </ScrollView>
                </View>
            </View>
            <View style={{ rowGap: 20, justifyContent: 'center' }}>
                <TouchableOpacity onPress={() => {
                    for (const item of filteredSelectedContent) {
                        selectableContent.push(item);
                        filteredSelectableContent.push(item);
                        removeItem(selectedContent, item);
                    }
                    filteredSelectedContent.length = 0;
                    forceUpdate();
                }}>
                    <FontAwesomeIcon fontSize={24} color={getThemedColor(theme, '#58595B')} icon={faAngleDoubleLeft} />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {
                    for (const item of filteredSelectableContent) {
                        selectedContent.push(item);
                        filteredSelectedContent.push(item);
                        removeItem(selectableContent, item);
                    }
                    filteredSelectableContent.length = 0;
                    forceUpdate();
                }}>
                    <FontAwesomeIcon fontSize={24} color={getThemedColor(theme, '#58595B')} icon={faAngleDoubleRight} />
                </TouchableOpacity>
            </View>
            <View style={{ flex: 1, rowGap: 5 }}>
                <MyAppText style={styles.tableHeaderText}>{selectedLabel}</MyAppText>
                <View style={styles.table}>
                    <TextInput style={styles.input} onChangeText={setFilteredSelectedTerm} />
                    <ScrollView>
                        <View style={{ gap: 5 }}>
                            {filteredSelectedContent.map((element, elementIndex) => (
                                <View key={elementIndex} style={{ gap: 5, paddingRight: 10 }}>
                                    <TouchableOpacity onPress={() => {
                                        selectableContent.push(element);
                                        filteredSelectableContent.push(element);
                                        removeItem(selectedContent, element);
                                        removeItem(filteredSelectedContent, element);
                                        forceUpdate();
                                    }}>
                                        <View style={styles.tableItem}>
                                            <MyAppText style={styles.tableItemText}>{element.name}</MyAppText>
                                            <FontAwesomeIcon fontSize={18} color={getThemedColor(theme, '#58595B')} icon={faAngleLeft} />
                                        </View>
                                    </TouchableOpacity >
                                    <View style={styles.tableLine}></View>
                                </View>
                            ))}
                        </View>
                    </ScrollView>
                </View>
            </View>
        </View >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flexDirection: 'row',
            columnGap: 25,
            flex: 1
        },
        tableHeaderText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 14,
            fontWeight: 'bold',
        },
        table: {
            paddingTop: 15,
            paddingLeft: 15,
            paddingRight: 15,
            borderWidth: 1,
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderRadius: 4,
            rowGap: 15,
            flex: 1
        },
        input: {
            color: getThemedColor(theme, '#222222'),
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            height: 32,
            padding: 10,
            borderColor: '#ccc',
            borderWidth: 1,
            borderRadius: 4,
        },
        tableItem: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        tableItemText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 13,
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        tableLine: {
            borderBottomColor: getThemedColor(theme, '#E6E6E6'),
            borderBottomWidth: 1,
        },
    });
}
