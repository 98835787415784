import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Modal } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faDownload, faGear } from '@fortawesome/free-solid-svg-icons';
import Mustache from 'mustache';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { useNavigationState } from '@react-navigation/native';

import 'react-calendar-timeline/lib/Timeline.css';

import { centralAPI } from '../../services/central-api';
import TimelineWrapper from '../../components/TimelineWrapper';
import PtzControls from '../../components/PtzControls';
import VideoPlayer from '../../components/VideoPlayer';
import MyAppText from '../../components/MyAppText';
import { cameraService } from '../../services/central-api/cameras';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import EventList from '../../components/lpr/EventList';
import { licensePlateRecognitionService } from '../../services/central-api/license-plate-recognition';
import { translate } from '../../services/translate';
import moment from 'moment';
import FormInput from '../../components/formInput';
import Toast from 'react-native-toast-message';
import { WindowInformation } from '../../services/window-information';
import FormActions from '../../components/formActions';
import { ClientError } from '../../services/central-api/base-service';
import MyDateTimeInput from '../../components/MyDatetimeInput';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import NewAnalysisModal from './VideoAnalysis/NewAnalysisModal';
import MaintenanceRequestModal from './MaintenanceRequest/MaintenanceRequestModal';

interface CameraPlayerProps {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser,
    route: {
        params?: {
            id: string;
        };
    };
}

export default function CameraPlayer({ navigation, authenticatedUser, route }: CameraPlayerProps) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [startInDateFilter, setStartInDateFilter] = useState(moment(new Date().getTime()).format('DD/MM/YYYY'));
    const [startInHourFilter, setStartInHourFilter] = useState(moment(new Date().getTime()).format('HH:mm:ss'));
    const [endInDateFilter, setEndInDateFilter] = useState(moment(new Date().getTime()).format('DD/MM/YYYY'));
    const [endInHourFilter, setEndInHourFilter] = useState(moment(new Date().getTime()).format('HH:mm:ss'));
    const [downloadName, setDownloadName] = useState<string>('');

    const [camera, setCamera] = useState<Camera>();
    const [videoPlayer, setVideoPlayer] = React.useState<VideoPlayer>();
    const [cursorTime, setCursorTime] = useState<number>(new Date().getTime());
    const [recordList, setRecordList] = useState<CameraRecordList>();
    const [isLive, setIsLive] = useState(true);
    const [currentRecord, setCurrentRecord] = useState<CameraRecord>();
    const [watermark, setWatermark] = useState<string>('');
    const [lprDetections, setLprDetections] = useState<LprDetection[]>([]);
    const index = useNavigationState(state => state);
    const [showDropDown, setShowDropDown] = useState(false);

    const [isOpenTags, setIsOpenTags] = useState<boolean>(false);
    const [selectedTagsIds, setSelectedTagsIds] = useState<number[]>([]);
    const [selectableTags, setSelectableTags] = useState<{ label: string, value: number; }[]>([]);

    useEffect(() => {
        if (!authenticatedUser || !route) return;

        if (route?.params?.id) {
            try {
                getCamera(route.params.id);
                getTimeline(route.params.id);
                setWatermark(String(authenticatedUser.id));
                getDownloadTags();
            } catch (err) {
                console.error(err);
                closeAction();
            }

            // ping backend so the token doesn't expire
            const interval = setInterval(async () => {
                // idk why but this effect is being called even when accessing others pages
                // this is a guarantee that the interval will only work when the page is active
                if (!window.location.href.endsWith('cameras/' + route.params?.id)) {
                    return;
                }

                if (camera?.type === 'ptz') {
                    await cameraService.watchingPtzCamera({ cameraId: camera.id });
                }

                await centralAPI.checkAuthentication();
            }, 30000);
            return () => clearInterval(interval);
        }
    }, [route, authenticatedUser]);

    useEffect(() => {
        if (!camera) {
            return;
        }

        cameraService.watchingPtzCamera({ cameraId: camera.id });

        // ping backend so the token doesn't expire
        const interval = setInterval(async () => {
            // idk why but this effect is being called even when accessing others pages
            // this is a guarantee that the interval will only work when the page is active
            if (!window.location.href.endsWith('cameras/' + route.params?.id)) {
                return;
            }

            if (camera?.type === 'ptz') {
                await cameraService.watchingPtzCamera({ cameraId: camera.id });
            }

        }, 30000);
        return () => clearInterval(interval);
    }, [route, camera]);


    async function getCamera(id: string) {
        try {
            const cameraRes = await cameraService.getCamera(id);
            setCamera(cameraRes);
        } catch (err) {
            console.error(err);
        }
    }

    async function getTimeline(id: string) {
        try {
            const timeline = await cameraService.getTimeline(id);
            setRecordList(timeline);
            Mustache.parse(timeline.shotTemplate);
            Mustache.parse(timeline.videoTemplate);
        } catch (err) {
            console.error(err);
        }
    }

    async function getDownloadTags() {
        try {
            const tags = await cameraService.getDownloadTags();
            setSelectableTags(tags.map((tag) => {
                return {
                    value: tag.id || 0,
                    label: tag.name
                };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    const videoPlayerRef = React.useCallback((videoPlayer: VideoPlayer) => {
        setVideoPlayer(videoPlayer);
    }, []);

    useEffect(() => {
        (async () => {
            try {
                if (!camera?.streamUrl) return;
                if (!videoPlayer) return;

                const token = await centralAPI.getToken();

                videoPlayer.setSrc(camera.streamUrl, { Authorization: `Bearer ${token}` });
            } catch (err) {
                console.error(err);
            }
        })();
    }, [camera, videoPlayer]);

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                const now = new Date();
                if (isLive) {
                    setCursorTime(now.getTime());
                } else {
                    if (videoPlayer && currentRecord) {
                        const time = videoPlayer.getTime();
                        const date = new Date((time * 1000) + currentRecord.start);
                        setCursorTime(date.getTime());
                    }
                }
            } catch (err) {
                console.error(err);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [videoPlayer, currentRecord, isLive]);

    function setTimeLineAt(moment: number) {
        setCursorTime(moment);

        if (!videoPlayer) {
            return;
        }

        setIsLive(false);
        videoPlayer.setIsLive(false);

        if (!recordList) {
            return;
        }

        const cameraRecord = recordList.videos.findLast((record: CameraRecord) => {
            return record.finish > moment && record.start <= moment;
        });

        if (!cameraRecord) {
            return;
        }

        setCurrentRecord(cameraRecord);
        videoPlayer.setSrc(Mustache.render(recordList.videoTemplate, cameraRecord));
        videoPlayer.setTimeAt((moment - cameraRecord.start) / 1000);
        videoPlayer.onVideoEnded(async () => {
            try {
                const index = recordList.videos.indexOf(cameraRecord) + 1;
                if (index < recordList.videos.length) {
                    setTimeLineAt(recordList.videos[index].start);
                } else {
                    if (!camera?.streamUrl) {
                        return;
                    }
                    const token = await centralAPI.getToken();

                    setCursorTime(new Date().getTime());
                    videoPlayer.setSrc(camera.streamUrl, { Authorization: `Bearer ${token}` });
                    setCurrentRecord(undefined);
                    setIsLive(true);
                    videoPlayer.setIsLive(true);
                }
            } catch (err) {
                console.error(err);
            }
        });
    }

    function onRecordClick(moment: number) {
        setTimeLineAt(moment);
    }

    function closeAction() {
        if (index.routes.length > 1) {
            return navigation.goBack();
        }
        window.location.href = '/cameras';
    }

    async function getLprDetections() {
        try {
            if (!camera?.id) {
                return;
            }
            const lprDetections = await licensePlateRecognitionService.getDetections({
                page: 0,
                limit: 50,
                irregularSituation: false,
                cameras: [camera.id],
                isMotorcycle: false,
            });
            setLprDetections(lprDetections);
        } catch (err) {
            console.error(err);
        }

    }

    function isDownloadFormValid() {
        return downloadName !== '' &&
            startInDateFilter !== '' &&
            startInHourFilter !== '' &&
            endInDateFilter !== '' &&
            endInHourFilter !== '' &&
            moment(`${startInDateFilter} ${startInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').valueOf() <= moment(`${endInDateFilter} ${endInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').valueOf();
    }

    async function createDownload() {
        try {
            if (!camera?.id) {
                return;
            }

            await cameraService.createCameraDownload({
                cameraId: camera.id,
                name: downloadName,
                msStart: moment(`${startInDateFilter} ${startInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').valueOf(),
                msFinish: moment(`${endInDateFilter} ${endInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').valueOf(),
                tags: selectedTagsIds
            });
            closeDownloadModal();
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('downloadStarted'),
            });
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    function closeDownloadModal() {
        setShowDropDown(false);
        resetDownloadForm();
    }

    function resetDownloadForm() {
        setIsOpenTags(false);
        setDownloadName('');
        setSelectedTagsIds([]);
        setStartInDateFilter(moment(new Date().getTime()).format('DD/MM/YYYY'));
        setStartInHourFilter(moment(new Date().getTime()).format('HH:mm:ss'));
        setEndInDateFilter(moment(new Date().getTime()).format('DD/MM/YYYY'));
        setEndInHourFilter(moment(new Date().getTime()).format('HH:mm:ss'));
    }

    useEffect(() => {
        if (camera?.type != 'lpr') {
            return;
        }

        getLprDetections();

        const interval = setInterval(() => {
            // idk why but this effect is being called even when accessing others pages
            // this is a guarantee that the interval will only work when the page is active
            if (!window.location.href.endsWith(`cameras/${camera?.id}`)) {
                return;
            }

            getLprDetections();
        }, 2000);
        return () => clearInterval(interval);
    }, [camera]);

    if (!camera) {
        return (
            <DefaultPageContainer>
                <DefaultPageLayout navigation={navigation} selectedMenu='map' lateralMenu='cameras'>
                    <></>
                </DefaultPageLayout>
            </DefaultPageContainer >
        );
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='map' lateralMenu='cameras' contentContainerStyle={{ padding: 10 }} authenticatedUser={authenticatedUser}>
                <View style={styles.content}>
                    <View style={styles.header}>
                        <View style={[styles.headerLeft]}>
                            <TouchableOpacity onPress={closeAction}>
                                <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={getThemedColor(theme, '#000028')} />
                            </TouchableOpacity>
                            <View style={{ flexDirection: 'row', columnGap: 20 }}>
                                <View style={{ justifyContent: 'center' }}>
                                    <View style={styles.buttons}>
                                        {!camera.thirdPartyCamera
                                            ? <MaintenanceRequestModal authenticatedUser={authenticatedUser} cameraId={camera?.id} />
                                            : null
                                        }
                                        {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.camera_download_video) && camera.type !== 'lpr' && !camera.thirdPartyCamera ?
                                            <TouchableOpacity style={styles.downloadButton} onPress={() => setShowDropDown(true)}>
                                                <FontAwesomeIcon fontSize={16} icon={faDownload} color={getThemedColor(theme, '#FFFFFF')} />
                                                <MyAppText style={styles.downloadButtonText}>{translate('downloadVideo')}</MyAppText>
                                            </TouchableOpacity>
                                            : null
                                        }
                                        {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.camera_video_analysis) && !camera.thirdPartyCamera ?
                                            <NewAnalysisModal cameraId={camera?.id} /> : null
                                        }
                                    </View>

                                </View>
                                <View style={{ justifyContent: 'center' }}>
                                    {authenticatedUser?.isAdmin || authenticatedUser?.permissions.crm_module_access ?
                                        <TouchableOpacity onPress={() => window.open(`/management/details/${camera.id}`)}>
                                            <FontAwesomeIcon icon={faGear} fontSize={16} color={getThemedColor(theme, '#222222')} />
                                        </TouchableOpacity> : null
                                    }
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.body}>
                        {camera.type == 'lpr' ?
                            <View style={{ flex: 1 }}>
                                <MyAppText>{camera.title}</MyAppText>
                                <View style={{ flexDirection: 'row', margin: 10 }}>
                                    {camera.tags ?
                                        camera.tags.map((tag) =>
                                            <View key={tag.id} style={[styles.marker, { backgroundColor: tag.color }]}>
                                                <MyAppText style={[styles.markerText]}>
                                                    {tag.name}
                                                </MyAppText>
                                            </View>
                                        ) : null
                                    }
                                </View>
                                <EventList
                                    events={lprDetections}
                                    navigation={navigation}
                                    hasMoreResults={false}
                                    isLoading={true}
                                    isPolling={true}
                                    page='map'
                                />
                            </View>
                            :
                            <View style={styles.bodyLeft}>
                                <View style={styles.bodyLeftTop} >
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <View style={{ flexDirection: 'row', gap: 5, minHeight: 30 }}>
                                            {camera.tags ?
                                                camera.tags.map((tag) =>
                                                    <View key={tag.id} style={[styles.marker, { backgroundColor: tag.color }]}>
                                                        <MyAppText style={[styles.markerText]}>
                                                            {tag.name}
                                                        </MyAppText>
                                                    </View>
                                                ) : null
                                            }
                                        </View>
                                        {camera.thirdPartyCamera ?
                                            <View>
                                                <MyAppText>{translate('integration')}: {camera.thirdPartyCamera.origin}</MyAppText>
                                            </View>
                                            : null}
                                    </View>
                                    <VideoPlayer
                                        camera={camera}
                                        watermark={watermark}
                                        onSetLive={async (videoPlayer) => {
                                            try {
                                                if (!camera?.streamUrl) {
                                                    return;
                                                }
                                                const token = await centralAPI.getToken();
                                                videoPlayer.setSrc(camera.streamUrl, { Authorization: `Bearer ${token}` });
                                                setCurrentRecord(undefined);
                                                setIsLive(true);
                                                videoPlayer.setIsLive(true);
                                            } catch (err) {
                                                console.error(err);
                                            }
                                        }}
                                        ref={videoPlayerRef}
                                    />
                                </View>
                                <View style={styles.bodyLeftBottom}>
                                    {cursorTime && recordList ?
                                        <TimelineWrapper
                                            cursor={cursorTime}
                                            recordList={recordList}
                                            onRecordClick={onRecordClick}
                                        />
                                        : null}
                                </View>
                            </View>
                        }
                        {camera.type == 'ptz' && camera.hasPtzPermission ?
                            <View style={styles.bodyRight}>
                                <PtzControls camera={camera} />
                            </View>
                            : null}
                    </View>
                </View>
                <Modal visible={showDropDown} animationType='fade' onRequestClose={() => closeDownloadModal()} transparent={true}>
                    <View style={styles.modalContainer}>
                        <View style={styles.centeredView}>
                            <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : {}]}>
                                <FormInput
                                    label={translate('name')}
                                    placeholder={translate('name')}
                                    value={downloadName}
                                    onChangeText={setDownloadName}
                                    invalid={(value: string) => {
                                        return value == '';
                                    }}
                                />
                                <View style={{ zIndex: 2 }}>
                                    <View style={{ flex: 1, gap: 5 }}>
                                        <MyAppText style={styles.label}>{translate('tags')}</MyAppText>
                                        <MyDropDownPicker
                                            items={selectableTags}
                                            multiple={true}
                                            mode='BADGE'
                                            value={selectedTagsIds}
                                            setValue={setSelectedTagsIds}
                                            setOpen={setIsOpenTags}
                                            open={isOpenTags}
                                        />
                                    </View>
                                </View>
                                <View style={{ flexDirection: 'row', columnGap: 10, flexWrap: 'wrap', zIndex: 1 }}>
                                    <MyDateTimeInput
                                        label={translate('beginIn')}
                                        date={startInDateFilter}
                                        onChangeDate={setStartInDateFilter}
                                        time={startInHourFilter}
                                        onChangeTime={setStartInHourFilter} />
                                    <MyDateTimeInput
                                        label={translate('endIn')}
                                        date={endInDateFilter}
                                        onChangeDate={setEndInDateFilter}
                                        time={endInHourFilter}
                                        onChangeTime={setEndInHourFilter} />
                                </View>
                                <View>
                                    <FormActions
                                        onSubmit={createDownload}
                                        onClose={() => closeDownloadModal()}
                                        disabled={!isDownloadFormValid()} />
                                </View>
                            </View>
                        </View>
                    </View>
                </Modal>
            </DefaultPageLayout>
        </DefaultPageContainer>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        content: {
            flex: 1,
        },
        header: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            columnGap: 10,
            padding: 10
        },
        headerLeft: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        name: {
            fontSize: 20,
            fontWeight: 'bold',
        },
        location: {
            fontSize: 15,
            flex: 1,
            color: getThemedColor(theme, '#888888')
        },
        body: {
            flex: 1,
            flexDirection: 'row',
            flexGrow: 94,
            padding: 16,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            borderWidth: 1,
        },
        bodyLeft: {
            flex: 1,
            flexGrow: 2
        },
        bodyLeftTop: {
            height: '80%'
        },
        bodyLeftBottom: {
            height: '20%'
        },
        bodyRight: {
            width: '19%',
            marginLeft: '1%',
            paddingLeft: '1%',
            borderLeftColor: getThemedColor(theme, '#58595B'),
            borderLeftWidth: 1
        },
        marker: {
            borderRadius: 8,
            height: 16,
            paddingLeft: 10,
            paddingRight: 10,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        markerText: {
            fontWeight: '500',
            color: getThemedColor(theme, '#000000'),
            fontSize: 11
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        formContainer: {
            minWidth: 200,
            minHeight: 150,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 4,
            padding: 20,
            rowGap: 25
        },
        dateInput: {
            fontFamily: 'Open Sans',
            fontSize: 16,
            borderWidth: 1,
            borderRadius: 4,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderColor: getThemedColor(theme, '#CCCCCC'),
            height: 40,
            padding: 10
        },
        dateText: {
            color: getThemedColor(theme, '#58595B'),
        },
        downloadButton: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            justifyContent: 'center'
        },
        downloadButtonText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
        buttons: {
            flexDirection: 'row',
            gap: 10
        }
    });
}
