import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { View, StyleSheet, TextInput, TouchableOpacity, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { translate } from '../../services/translate';
import MyAppText from '../../components/MyAppText';
import { Pagination } from '../../components/Pagination';
import moment from 'moment';
import { faEdit, faPlug, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyCheckbox } from '../../components/MyCheckbox';
import { RouteProp, useFocusEffect, useRoute } from '@react-navigation/native';
import { GetCamerasPaginatedParameters } from '../../services/central-api/cameras';
import { crmService } from '../../services/central-api/crm';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import MyDataTable from '../../components/MyDataTable';
import { CRMParamList } from '../../typings/Params';
import Tooltip from '../../components/Tooltip';
import MyDropDownPicker from '../../components/MyDropDownPicker';

const limit = 100;

export default function Management({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    const [pageCount, setPageCount] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [cameras, setCameras] = useState<CrmPaginatedCamera[]>([]);

    const route = useRoute<RouteProp<CRMParamList, 'Management'>>();

    // filters
    const [filters, setFilters] = useState<GetCamerasPaginatedParameters>({
        page: Math.max(route.params.page - 1, 0),
        textFilter: route.params.textFilter ?? '',
        limit,
        offline: route.params.offline,
        showDisabled: true,
        hideChildren: false,
        type: route.params.type,
        integrationType: route.params.integrationType
    });
    const [page, setPage] = useState(filters.page);

    const [textFilter, setTextFilter] = useState(filters.textFilter);
    const [offlineFilter, setOfflineFilter] = useState(filters.offline ?? false);

    const loaders = useRef({ didPageLoad: false, didTextFilterLoad: false });

    // Camera type picker
    const [isCameraTypePickerOpen, setIsCameraTypePickerOpen] = useState<boolean>(false);
    const [selectedCameraTypeFilter, setSelectedCameraTypeFilter] = useState<CameraType | 'all'>(filters.type ?? 'all');
    const [selectableCamerasTypeFilter, setSelectableCamerasTypeFilter] = useState<{ label: string, value: CameraType | 'all'; }[]>([]);

    // Integration type picker
    const [isIntegrationTypePickerOpen, setIsIntegrationTypePickerOpen] = useState<boolean>(false);
    const [selectedIntegrationTypeFilter, setSelectedIntegrationTypeFilter] = useState<IntegrationType | 'all'>(filters.integrationType ?? 'all');
    const [selectableIntegrationsTypeFilter, setSelectableIntegrationsTypeFilter] = useState<{ label: string, value: IntegrationType | 'all'; }[]>([]);

    useFocusEffect(useCallback(() => {
        setSelectableCamerasTypeFilter([
            { label: translate('all'), value: 'all' },
            { label: translate('ptz'), value: 'ptz' },
            { label: translate('lpr'), value: 'lpr' },
            { label: translate('pinned'), value: 'pinned' },
            { label: translate('panoramic'), value: 'panoramic' }
        ]);
        setSelectableIntegrationsTypeFilter([
            { label: translate('all'), value: 'all' },
            { label: translate('type2'), value: 'type_2' },
            { label: translate('type3'), value: 'type_3' },
        ]);
        getCamerasList();
    }, [navigation, filters]));

    useEffect(() => {
        if (!loaders.current.didPageLoad) {
            loaders.current.didPageLoad = true;
            return;
        }
        setFilters({ ...filters, page });
    }, [page]);

    async function getCamerasList() {
        try {
            setIsLoading(true);
            const response = await crmService.getCamerasPaginated(filters);
            setCameras(response.rows);
            setPageCount(Math.ceil(response.count / limit));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    function handleFilters() {
        setFilters({
            ...filters,
            textFilter,
            offline: offlineFilter,
            type: selectedCameraTypeFilter !== 'all' ? selectedCameraTypeFilter : undefined,
            integrationType: selectedIntegrationTypeFilter !== 'all' ? selectedIntegrationTypeFilter : undefined,
            page: 0
        });

        navigation.setParams({
            textFilter,
            offline: offlineFilter,
            type: selectedCameraTypeFilter !== 'all' ? selectedCameraTypeFilter : undefined,
            integrationType: selectedIntegrationTypeFilter !== 'all' ? selectedIntegrationTypeFilter : undefined,
            page: 1
        });
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            handleFilters();
        }
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='cameras' lateralMenu='crm' contentContainerStyle={{ padding: 10 }} authenticatedUser={authenticatedUser}>
                <View style={{ flex: 1, rowGap: 15 }}>
                    <View style={styles.headerContent}>
                        <View style={{ flexGrow: 1, maxWidth: 500, minWidth: 300 }}>
                            <MyAppText style={styles.filterText}>{translate('searchCamera')}</MyAppText>
                            <TextInput
                                style={[styles.filterInput, {
                                    color: textFilter ? getThemedColor(theme, '#222222') : '#888888'
                                }]}
                                value={textFilter}
                                onChangeText={setTextFilter}
                                placeholder={translate('cameraTitleIdOrSerialNumber')}
                                onKeyPress={handleKeyDown}
                            />
                        </View>
                        <View style={{ justifyContent: 'flex-end', flexGrow: 1, minWidth: 200 }}>
                            <MyCheckbox
                                style={styles.checkbox}
                                label={translate('offline')}
                                checked={offlineFilter}
                                setChecked={(value) => {
                                    setOfflineFilter(value);
                                }}
                            />
                        </View>
                        <View style={{ maxWidth: 230, minWidth: 230, zIndex: 3, rowGap: 5 }}>
                            <MyAppText>{translate('cameraType')}</MyAppText>
                            <MyDropDownPicker
                                open={isCameraTypePickerOpen}
                                value={selectedCameraTypeFilter}
                                items={selectableCamerasTypeFilter}
                                setOpen={setIsCameraTypePickerOpen}
                                setValue={setSelectedCameraTypeFilter}
                                height={40}
                                borderColor={getThemedColor(theme, '#888888')}
                            />
                        </View>
                        <View style={{ maxWidth: 230, minWidth: 230, zIndex: 2, rowGap: 5 }}>
                            <MyAppText>{translate('integrationType')}</MyAppText>
                            <MyDropDownPicker
                                open={isIntegrationTypePickerOpen}
                                value={selectedIntegrationTypeFilter}
                                items={selectableIntegrationsTypeFilter}
                                setOpen={setIsIntegrationTypePickerOpen}
                                setValue={setSelectedIntegrationTypeFilter}
                                height={40}
                                borderColor={getThemedColor(theme, '#888888')}
                            />
                        </View>
                        <View style={{ justifyContent: 'flex-end' }}>
                            <TouchableOpacity style={styles.new} onPress={handleFilters}>
                                <FontAwesomeIcon icon={faSearch} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('toSearch')}</MyAppText>
                            </TouchableOpacity>
                        </View>
                        <View style={{ justifyContent: 'flex-end' }}>
                        </View>
                        <View style={{ justifyContent: 'flex-end' }}>
                            <TouchableOpacity onPress={() => {
                                navigation.navigate('ManagementDetails');
                            }} style={styles.new}>
                                <FontAwesomeIcon icon={faPlus} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('registerCamera')}</MyAppText>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.cardList}>
                        <MyDataTable
                            columns={[
                                {
                                    name: translate('title'),
                                    selector: row => row.title,
                                    grow: 3,
                                    wrap: true,
                                },
                                {
                                    name: translate('installationCompany'),
                                    selector: row => row.installationCompany?.name || '',
                                },
                                {
                                    name: translate('internetType'),
                                    selector: row => row.internetType == '5g' ? '5G' : row.internetType == '4g' ? '4G' : row.internetType == 'fiber' ? translate('fiber') : translate('radio'),
                                },
                                {
                                    name: translate('serialNo'),
                                    selector: row => row.serialNo || '',
                                },
                                {
                                    name: translate('type'),
                                    selector: row => translate(row.type),
                                },
                                {
                                    name: translate('integration'),
                                    selector: row => row.thirdPartyCamera?.origin ?? '',
                                    wrap: true
                                },
                                {
                                    name: translate('status'),
                                    cell: row => {
                                        return row.lastTimeOnline ?
                                            <Tooltip tooltipDirection='right' tooltipContent={translate('offlineSince', {
                                                lastTimeOnline: moment(row.lastTimeOnline).format('DD/MM/YYYY HH:mm')
                                            })} width={300}>
                                                <MyAppText style={[styles.cell, (row.disabledAt && row.type != 'lpr') ? { color: getThemedColor(theme, '#B5B5B5') } : (row.isConnected) ? {} : { color: '#FF0027' }]}>
                                                    {(row.disabledAt && row.type != 'lpr') ? translate('disabled') : row.isConnected ? translate('online') : translate('offline')}
                                                </MyAppText>
                                            </Tooltip>
                                            : <MyAppText style={[styles.cell, (row.disabledAt && row.type != 'lpr') ? { color: getThemedColor(theme, '#B5B5B5') } : (row.isConnected) ? {} : { color: '#FF0027' }]}>
                                                {(row.disabledAt && row.type != 'lpr') ? translate('disabled') : row.isConnected ? translate('online') : translate('offline')}
                                            </MyAppText>;
                                    }
                                },
                                {
                                    name: translate('registeredAt'),
                                    selector: row => moment(row.createdAt).format('DD/MM/YYYY HH:mm'),
                                    wrap: true
                                },
                                {
                                    name: translate('action'),
                                    button: true,
                                    cell: row =>
                                        <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                            <TouchableOpacity onPress={() => navigation.navigate('ManagementDetails', { id: row.id })}>
                                                <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                            </TouchableOpacity>
                                            <TouchableOpacity onPress={() => navigation.navigate('CameraHistory', {
                                                id: row.id,
                                            })}>
                                                <FontAwesomeIcon icon={faPlug} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                            </TouchableOpacity>
                                        </View>
                                }
                            ]}
                            data={cameras}
                            progressPending={isLoading}
                        />
                    </View>
                    <Pagination currentPage={page} totalPages={pageCount} setPage={page => {
                        setPage(page);

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }} />
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        headerContent: {
            minHeight: 65,
            flexDirection: 'row',
            columnGap: 10,
            flexWrap: 'wrap',
            rowGap: 10,
            zIndex: 2
        },
        cell: {
            fontSize: 14,
            color: getThemedColor(theme, '#58595B'),
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            minHeight: 40,
            paddingHorizontal: 30,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            columnGap: 10
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
        },
        filterText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
            paddingBottom: 5
        },
        filterInput: {
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderWidth: 1,
            borderRadius: 4,
            borderColor: '#888',
            height: 40,
            minHeight: 40,
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            padding: 10
        },
        checkbox: {
            paddingHorizontal: 8,
            height: 40
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1
        },
    });
}
