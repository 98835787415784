import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Animated, ScrollView, useWindowDimensions, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import CustomButton from '../CustomButton';
import { translate } from '../../services/translate';
import { faFilter, faX } from '@fortawesome/free-solid-svg-icons';
import MyAppText from '../MyAppText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EdgeInsets, useSafeAreaInsets } from 'react-native-safe-area-context';

interface Props {
    children: React.ReactNode;
    onCleanFilters: () => void;
    onFilters: () => void;
}

function Filters({ children, onCleanFilters, onFilters }: Props) {
    const { styles, breakpoint, theme } = useStyles(stylesheet);
    const insets = useSafeAreaInsets();
    const { width, height } = useWindowDimensions();

    const sidebarWidth = useMemo(() => {
        if (breakpoint === 'xs') {
            return width * 0.8;
        }

        return width * 0.4;
    }, [width, breakpoint]);

    const [isOpen, setIsOpen] = useState(false);

    const animationSidebar = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        Animated.timing(animationSidebar, {
            toValue: isOpen ? sidebarWidth : 0,
            duration: 200,
            useNativeDriver: true
        }).start();
    }, [isOpen, sidebarWidth]);

    return (
        <>
            <CustomButton onPress={() => setIsOpen(!isOpen)} text={translate('filters')} icon={faFilter} />

            <Animated.View
                style={[
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    { position: 'fixed' },
                    styles.container(height, insets),
                    { width: animationSidebar, borderWidth: isOpen ? 1 : 0 },
                ]}
            >
                {isOpen &&
                    <>
                        <View style={styles.header}>
                            <View>
                                <MyAppText style={{ fontSize: 18 }}>{translate('allFilters')}</MyAppText>
                            </View>

                            <FontAwesomeIcon icon={faX} color={theme.colors.iconColor} fontSize={12} cursor='pointer' onClick={() => setIsOpen(!isOpen)} />
                        </View>

                        <View style={[styles.filterContainer]}>
                            <View style={styles.inputsHeight}>
                                <ScrollView contentContainerStyle={styles.scrollContent}>
                                    <View style={styles.children}>
                                        {children}
                                    </View>
                                    <View style={styles.buttonsContainer}>
                                        <CustomButton text={translate('filterResults')} onPress={onFilters} />
                                        <CustomButton text={translate('cleanFilters')} onPress={onCleanFilters} />
                                    </View>
                                </ScrollView>
                            </View>
                        </View>
                    </>
                }

            </Animated.View>
        </>
    );
}


const stylesheet = createStyleSheet(theme => ({
    container: (height: number, insets: EdgeInsets) => {
        const top = 80;
        const heightAndTopInset = top + insets.top;

        return {
            backgroundColor: theme.colors.backgroundColor,
            top: heightAndTopInset,
            height: height - heightAndTopInset - insets.bottom - 15,
            right: 0,
            zIndex: 10,
            margin: 0,
            borderRadius: 4,
            borderColor: theme.colors.borderColor,
        };
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 15
    },

    filterContainer: {
        padding: 15,
        flex: 1
    },

    inputsHeight: {
        flex: 1,
    },

    buttonsContainer: {
        flexDirection: 'column',
        gap: 10,
    },

    children: {
        zIndex: 2,
        gap: 10
    },
    scrollContent: {
        flex: 1,
        gap: 20,
        justifyContent: 'space-between'
    }
}));

export default Filters;
