import React from 'react';
import { ActivityIndicator, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { Hoverable } from 'react-native-web-hover';
import MyAppText from './MyAppText';

interface Props {
    icon?: IconDefinition,
    text: string;
    onPress: VoidFunction;
    /**
     * button style
     */
    style?: ViewStyle;
    /**
     * text style
     */
    textStyle?: TextStyle;
    isLoading?: boolean;
    disabled?: boolean;
}


function CustomButton({ icon, text, onPress, style, textStyle, isLoading = false, disabled = false }: Props) {
    const { styles } = useStyles(stylesheet);


    function buttonContent() {
        if (isLoading) {
            return <ActivityIndicator size='small' color={styles.loadingColor.color} />;
        }
        return (
            <React.Fragment>
                {icon && <FontAwesomeIcon style={styles.buttonIcon} fontSize={16} icon={icon} color='white' />}
                <MyAppText style={[styles.buttonText, textStyle]}>{text}</MyAppText>
            </React.Fragment>
        );
    }

    return (
        <TouchableOpacity onPress={onPress} disabled={disabled || isLoading}>
            <Hoverable>
                {({ hovered }) => (
                    <View style={[styles.buttonContainer(hovered, disabled || isLoading), style]}>
                        {buttonContent()}
                    </View>
                )}
            </Hoverable>
        </TouchableOpacity>
    );
}

const stylesheet = createStyleSheet(theme => ({
    buttonContainer: (hovered: boolean, disabled: boolean) => ({
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 5,
        paddingHorizontal: 30,
        width: '100%',
        columnGap: 10,
        height: 40,
        backgroundColor: disabled ? theme.colors.disabledButtonBackground : hovered ? theme.colors.buttonHover : theme.colors.buttonBackground,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        justifyContent: 'center',
        gap: 10
    }),

    buttonText: {
        color: theme.colors.color,
        fontSize: 16,
    },

    buttonIcon: {
        color: theme.colors.color,
    },

    loadingColor: {
        color: theme.colors.loadingColor
    }
}));

export default CustomButton;
