import { PaginatedResource } from '../../typings/Paginated';
import { BaseService, GenericPaginatedParameters, Unauthorized, UnexpectedError } from './base-service';

export enum Action {
    CREATE_AUDIT_LOGS_REPORT = 'CREATE_AUDIT_LOGS_REPORT',
    CREATE_USER = 'CREATE_USER',
    DELETE_USER = 'DELETE_USER',
    EDIT_CAMERA = 'EDIT_CAMERA',
    GENERATE_CAMERA_DOWNLOAD = 'GENERATE_CAMERA_DOWNLOAD',
    DOWNLOAD_CAMERA = 'DOWNLOAD_CAMERA',
    DISABLE_CAMERA = 'DISABLE_CAMERA',
    ENABLE_CAMERA = 'ENABLE_CAMERA',
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    CREATE_CAMERA = 'CREATE_CAMERA',
    GET_CAMERA = 'GET_CAMERA',
    GET_CAMERA_TIMELINE = 'GET_CAMERA_TIMELINE',
    GET_CAMERAS_REPORT = 'GET_CAMERAS_REPORT',
    GET_CAMERAS_ONLINE_TIME_REPORT = 'GET_CAMERAS_ONLINE_TIME_REPORT',
    DELETE_CAMERA = 'DELETE_CAMERA',
    CAMERA_PTZ_MOVE = 'CAMERA_PTZ_MOVE',
    SET_CAMERA_PTZ_PRESET = 'SET_CAMERA_PTZ_PRESET',
    GO_TO_CAMERA_PTZ_PRESET = 'GO_TO_CAMERA_PTZ_PRESET',
    GET_EVENTS_BY_PICTURE = 'GET_EVENTS_BY_PICTURE',
    GET_PEOPLE_BY_PICTURE = 'GET_PEOPLE_BY_PICTURE',
    ADDED_FACE_TO_PERSON = 'ADDED_FACE_TO_PERSON',
    DELETED_FACE_FROM_PERSON = 'DELETED_FACE_FROM_PERSON',
    EDIT_USER = 'EDIT_USER',
    CREATE_GROUP = 'CREATE_GROUP',
    EDIT_GROUP = 'EDIT_GROUP',
    DELETE_GROUP = 'DELETE_GROUP',
    CREATE_SKILL = 'CREATE_SKILL',
    EDIT_SKILL = 'EDIT_SKILL',
    DELETE_SKILL = 'DELETE_SKILL',
    CREATE_ASSIGNMENT = 'CREATE_ASSIGNMENT',
    EDIT_ASSIGNMENT = 'EDIT_ASSIGNMENT',
    DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT',
    CREATE_PLATE = 'CREATE_PLATE',
    EDIT_PLATE = 'EDIT_PLATE',
    EDIT_DETECTION = 'EDIT_DETECTION',
    DELETE_PLATE = 'DELETE_PLATE',
    CREATE_TAG = 'CREATE_TAG',
    EDIT_TAG = 'EDIT_TAG',
    DELETE_TAG = 'DELETE_TAG',
    CREATE_MOSAIC = 'CREATE_MOSAIC',
    EDIT_MOSAIC = 'EDIT_MOSAIC',
    DELETE_MOSAIC = 'DELETE_MOSAIC',
    WATCH_MOSAIC = 'WATCH_MOSAIC',
    CREATE_PERMISSION = 'CREATE_PERMISSION',
    EDIT_PERMISSION = 'EDIT_PERMISSION',
    DELETE_PERMISSION = 'DELETE_PERMISSION',
    CREATE_ACTING_BODY = 'CREATE_ACTING_BODY',
    EDIT_ACTING_BODY = 'EDIT_ACTING_BODY',
    DELETE_ACTING_BODY = 'DELETE_ACTING_BODY',
    CREATE_OBJECT_CATEGORY = 'CREATE_OBJECT_CATEGORY',
    EDIT_OBJECT_CATEGORY = 'EDIT_OBJECT_CATEGORY',
    DELETE_OBJECT_CATEGORY = 'DELETE_OBJECT_CATEGORY',
    DELETE_OBJECT_SUB_CATEGORY = 'DELETE_OBJECT_SUB_CATEGORY',
    CREATE_DISPATCH_VEHICLE_RELATION = 'CREATE_DISPATCH_VEHICLE_RELATION',
    EDIT_DISPATCH_VEHICLE_RELATION = 'EDIT_DISPATCH_VEHICLE_RELATION',
    DELETE_DISPATCH_VEHICLE_RELATION = 'DELETE_DISPATCH_VEHICLE_RELATION',
    CREATE_DISPATCH_PERSON_RELATION = 'CREATE_DISPATCH_PERSON_RELATION',
    EDIT_DISPATCH_PERSON_RELATION = 'EDIT_DISPATCH_PERSON_RELATION',
    DELETE_DISPATCH_PERSON_RELATION = 'DELETE_DISPATCH_PERSON_RELATION',
    CREATE_INSTALLATION_COMPANY = 'CREATE_INSTALLATION_COMPANY',
    EDIT_INSTALLATION_COMPANY = 'EDIT_INSTALLATION_COMPANY',
    DELETE_INSTALLATION_COMPANY = 'DELETE_INSTALLATION_COMPANY',
    EDIT_TERMS_OF_USE = 'EDIT_TERMS_OF_USE',
    EDIT_SESSION_CONFIG = 'EDIT_SESSION_CONFIG',
    CREATE_DISPATCH_REPORT = 'CREATE_DISPATCH_REPORT',
    CREATE_DISPATCH_EQUIPMENT = 'CREATE_DISPATCH_EQUIPMENT',
    EDIT_DISPATCH_EQUIPMENT = 'EDIT_DISPATCH_EQUIPMENT',
    DELETE_DISPATCH_EQUIPMENT = 'DELETE_DISPATCH_EQUIPMENT',
    CREATE_DISPATCH_OCCURRENCE_TYPE = 'CREATE_DISPATCH_OCCURRENCE_TYPE',
    EDIT_DISPATCH_OCCURRENCE_TYPE = 'EDIT_DISPATCH_OCCURRENCE_TYPE',
    DELETE_DISPATCH_OCCURRENCE_TYPE = 'DELETE_DISPATCH_OCCURRENCE_TYPE',
    EDIT_DISPATCH_VEHICLE = 'EDIT_DISPATCH_VEHICLE',
    CREATE_INTERNET_COMPANY = 'CREATE_INTERNET_COMPANY',
    EDIT_INTERNET_COMPANY = 'EDIT_INTERNET_COMPANY',
    DELETE_INTERNET_COMPANY = 'DELETE_INTERNET_COMPANY',
    DELETE_ROLE = 'DELETE_ROLE',
    DELETE_ACTING_BODY_UNIT = 'DELETE_ACTING_BODY_UNIT',
    DELETE_ACTING_BODY_COMMAND = 'DELETE_ACTING_BODY_COMMAND',
    CREATE_POINT_OF_INTEREST = 'CREATE_POINT_OF_INTEREST',
    EDIT_POINT_OF_INTEREST = 'EDIT_POINT_OF_INTEREST',
    DELETE_POINT_OF_INTEREST = 'DELETE_POINT_OF_INTEREST',
    CREATE_UNIT = 'CREATE_UNIT',
    EDIT_UNIT = 'EDIT_UNIT',
    DELETE_UNIT = 'DELETE_UNIT',
    ACCEPTED_TERMS_OF_USE = 'ACCEPTED_TERMS_OF_USE',
    CHANGE_AGENT_ASSIGNMENT_ON_UNIT = 'CHANGE_AGENT_ASSIGNMENT_ON_UNIT',
    CHANGE_AGENT_UNIT = 'CHANGE_AGENT_UNIT',
    DEACTIVATE_AGENT_UNIT = 'DEACTIVATE_AGENT_UNIT',
    ACTIVATE_AGENT_UNIT = 'ACTIVATE_AGENT_UNIT',
    ACTIVATED_UNIT = 'ACTIVATED_UNIT',
    DEACTIVATED_UNIT = 'DEACTIVATED_UNIT',
    CHANGED_PTZ_PRIORITIES = 'CHANGED_PTZ_PRIORITIES',
    CREATE_FACIAL_PERSON = 'CREATE_FACIAL_PERSON',
    UPDATE_FACIAL_PERSON = 'UPDATE_FACIAL_PERSON',
    DELETE_FACIAL_PERSON = 'DELETE_FACIAL_PERSON',
    CREATE_FACIAL_MARKER = 'CREATE_FACIAL_MARKER',
    UPDATE_FACIAL_MARKER = 'UPDATE_FACIAL_MARKER',
    DELETE_FACIAL_MARKER = 'DELETE_FACIAL_MARKER',
    VEHICLE_INACTIVATED = 'VEHICLE_INACTIVATED',
    VEHICLE_ACTIVATED = 'VEHICLE_ACTIVATED',
    CREATE_ALARM_CENTER = 'CREATE_ALARM_CENTER',
    EDIT_ALARM_CENTER = 'EDIT_ALARM_CENTER',
    DELETE_ALARM_CENTER = 'DELETE_ALARM_CENTER',
    UPDATE_USER_FACE_PICTURE = 'UPDATE_USER_FACE_PICTURE',
    CREATE_TURNSTILE_GROUP = 'CREATE_TURNSTILE_GROUP',
    EDIT_TURNSTILE_GROUP = 'EDIT_TURNSTILE_GROUP',
    DELETE_TURNSTILE_GROUP = 'DELETE_TURNSTILE_GROUP',
    EDIT_TEAM_NAME = 'EDIT_TEAM_NAME',
    CREATE_TEAM_NAME = 'CREATE_TEAM_NAME',
    DELETE_TEAM_NAME = 'DELETE_TEAM_NAME',
    CREATE_LPR_DETECTIONS_REPORT = 'CREATE_LPR_DETECTIONS_REPORT',
    GENERATE_CAMERA_VIDEO_ANALYSIS = 'GENERATE_CAMERA_VIDEO_ANALYSIS',
    CREATE_FINISH_CATEGORY = 'CREATE_FINISH_CATEGORY',
    EDIT_FINISH_CATEGORY = 'EDIT_FINISH_CATEGORY',
    DELETE_FINISH_CATEGORY = 'DELETE_FINISH_CATEGORY',
    DELETE_FINISH_SUB_CATEGORY = 'DELETE_FINISH_SUB_CATEGORY',
    UNIT_DISPATCHED = 'UNIT_DISPATCHED',
    CREATE_OCCURRENCE = 'CREATE_OCCURRENCE',
    CREATE_GUARDIAN_WOMAN = 'CREATE_GUARDIAN_WOMAN',
    EDIT_GUARDIAN_WOMAN = 'EDIT_GUARDIAN_WOMAN',
    DELETE_GUARDIAN_WOMAN = 'DELETE_GUARDIAN_WOMAN',
    SEARCH_ON_CORTEX = 'SEARCH_ON_CORTEX',
    GET_CAMERA_PASSWORD = 'GET_CAMERA_PASSWORD',
    FINISHED_OCCURRENCE = 'FINISHED_OCCURRENCE',
    OCCURRENCE_HANDLED = 'OCCURRENCE_HANDLED',
    OCCURRENCE_CREATED_INVOLVED_OBJECT = 'OCCURRENCE_CREATED_INVOLVED_OBJECT',
    OCCURRENCE_UPDATED_INVOLVED_OBJECT = 'OCCURRENCE_UPDATED_INVOLVED_OBJECT',
    OCCURRENCE_REMOVED_INVOLVED_OBJECT = 'OCCURRENCE_REMOVED_INVOLVED_OBJECT',
    OCCURRENCE_CREATED_INVOLVED_PERSON = 'OCCURRENCE_CREATED_INVOLVED_PERSON',
    OCCURRENCE_UPDATED_INVOLVED_PERSON = 'OCCURRENCE_UPDATED_INVOLVED_PERSON',
    OCCURRENCE_REMOVED_INVOLVED_PERSON = 'OCCURRENCE_REMOVED_INVOLVED_PERSON',
    OCCURRENCE_CREATED_INVOLVED_VEHICLE = 'OCCURRENCE_CREATED_INVOLVED_VEHICLE',
    OCCURRENCE_UPDATED_INVOLVED_VEHICLE = 'OCCURRENCE_UPDATED_INVOLVED_VEHICLE',
    OCCURRENCE_REMOVED_INVOLVED_VEHICLE = 'OCCURRENCE_REMOVED_INVOLVED_VEHICLE',
    UPDATED_OCCURRENCE_POSITION = 'UPDATED_OCCURRENCE_POSITION',
    OCCURRENCE_CREATED_HISTORIC_TITLE = 'OCCURRENCE_CREATED_HISTORIC_TITLE',
    OCCURRENCE_TRANSFER = 'OCCURRENCE_TRANSFER',
    OCCURRENCE_ASSUMED = 'OCCURRENCE_ASSUMED',
    OCCURRENCE_UPDATED_HISTORIC_TITLE = 'OCCURRENCE_UPDATED_HISTORIC_TITLE',
    UNIT_DISASSOCIATED_TO_OCCURRENCE_HISTORIC_TITLE = 'UNIT_DISASSOCIATED_TO_OCCURRENCE_HISTORIC_TITLE',
    UNIT_ASSOCIATED_TO_OCCURRENCE_HISTORIC_TITLE = 'UNIT_ASSOCIATED_TO_OCCURRENCE_HISTORIC_TITLE',
    UNIT_SET_STARTER_HISTORIC_TITLE = 'UNIT_SET_STARTER_HISTORIC_TITLE',
    UNIT_SET_DISPLACED_HISTORIC_TITLE = 'UNIT_SET_DISPLACED_HISTORIC_TITLE',
    UNIT_REMOVE_DISPLACED_HISTORIC_TITLE = 'UNIT_REMOVE_DISPLACED_HISTORIC_TITLE',
    REOPENED_OCCURRENCE = 'REOPENED_OCCURRENCE',
}

export interface Log {
    id: string;
    action: Action;
    /**
     * The log date. Its a UTC date represented as string.
     * You can use `new Date(Log.date)` to cast it as Date.
     */
    date: string;
    userId?: number;
    userName?: string;
    ip?: string;
    detail?: { [key: string]: string | number | boolean; };
}

export interface GetLogsParameters {
    page: number;
    limit: number;
    sort?: 'asc' | 'desc';
    userId?: number;
    dateGte?: string;
    dateLte?: string;
    queryString?: string;
    action?: string;
}

interface CreateReportParameters {
    name: string;
    startDate: string;
    endDate: string;
    userId?: number;
    action?: Action;
}

class AuditLogsService extends BaseService {
    async getLogs({ page, limit, sort, dateGte, dateLte, queryString, userId, action }: GetLogsParameters): Promise<Log[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/audit-logs${this.encodeQueryParams({ page, limit, sort, dateGte, dateLte, queryString, userId, action })}`, {
            method: 'GET',
            headers: this.getHeaders(token),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAllUsers(): Promise<AuditLogsSimplifiedUserData[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/audit-logs/users`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createReport(params: CreateReportParameters) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/audit-logs/report`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getReports(params: GenericPaginatedParameters): Promise<PaginatedResource<Report>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/audit-logs/reports/paginated${this.encodeQueryParams({
            ...params,
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getReport(id: number): Promise<{ resultUrl: string; }> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/audit-logs/report/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }
}

export const auditLogsService = new AuditLogsService();
