import React from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { EventParamList } from '../../typings/Params';
import Filters from '../../components/Filter';
import { useState } from 'react';
import TypeDropdown from './TypeDropdown';
import PriorityDropdown from './PriorityDropdown';
import OccurrenceTypeDropdown from './OccurrenceTypeDropdown';
import TagsDropdown from './TagsDropdown';
import ActingBodiesDropdown from './ActingBodiesDropdown';
import FilterDateInput from '../../components/Filter/components/FilterDateInput';
import { translate } from '../../services/translate';
import moment from 'moment';

interface Props {
    filters: EventParamList['Events'];
    setFilters: React.Dispatch<React.SetStateAction<EventParamList['Events']>>;
    authenticatedUser?: AuthenticatedUser;
}

function EventsFilter({ filters, setFilters, authenticatedUser }: Props) {
    const navigation = useNavigation<NavigationProp<EventParamList, 'Events'>>();
    const beginDate = moment().subtract(12, 'hours');

    const [selectedTriggerTypeFilter, setSelectedTriggerTypeFilter] = useState<TriggerType | 'all' | 'manual'>(filters.triggerType ?? 'all');
    const [selectedPriorityFilter, setSelectedPriorityFilter] = useState<OccurrencePriorities | 'all'>(filters.priority ?? 'all');
    const [selectedOccurrenceTypeFilter, setSelectedOccurrenceTypeFilter] = useState<number>(filters.occurrenceTypeId ?? 0);
    const [selectedTagsIds, setSelectedTagsIds] = useState<number[]>(filters.tags ?? []);
    const [selectedActingBodyId, setSelectedActingBodyId] = useState<number>(filters.actingBodyId ?? 0);
    const [beginInDateFilter, setBeginInDateFilter] = useState(moment(filters.beginIn ?? beginDate).format('DD/MM/YYYY'));
    const [beginInHourFilter, setBeginInHourFilter] = useState(moment(filters.beginIn ?? beginDate).format('HH:mm'));
    const [endInDateFilter, setEndInDateFilter] = useState(filters.endIn ? moment(filters.endIn).format('DD/MM/YYYY') : '');
    const [endInHourFilter, setEndInHourFilter] = useState(filters.endIn ? moment(filters.endIn).format('HH:mm') : '');

    const handleCleanFilter = () => {
        setSelectedOccurrenceTypeFilter(0);
        setSelectedActingBodyId(0);
        setSelectedPriorityFilter('all');
        setSelectedTriggerTypeFilter('all');
        setSelectedTagsIds([]);
        setBeginInDateFilter(moment().subtract(12, 'hours').format('DD/MM/YYYY'));
        setBeginInHourFilter(moment().subtract(12, 'hours').format('HH:mm'));
        setEndInDateFilter('');
        setEndInHourFilter('');
    };

    const handleFilter = () => {
        const beginIn = beginInDateFilter ? moment(beginInHourFilter ? `${beginInDateFilter} ${beginInHourFilter}:00` : `${beginInDateFilter} : 00:00:00`, 'DD/MM/YYYY HH:mm').valueOf() : undefined;
        const endIn = endInDateFilter ? moment(endInHourFilter ? `${endInDateFilter} ${endInHourFilter}:59` : `${endInDateFilter} 23:59:59`, 'DD/MM/YYYY HH:mm:ss').valueOf() : undefined;

        setFilters({
            ...filters,
            priority: selectedPriorityFilter !== 'all' ? selectedPriorityFilter : undefined,
            triggerType: selectedTriggerTypeFilter !== 'all' ? selectedTriggerTypeFilter : undefined,
            occurrenceTypeId: selectedOccurrenceTypeFilter !== 0 ? selectedOccurrenceTypeFilter : undefined,
            tags: selectedTagsIds,
            actingBodyId: selectedActingBodyId !== 0 ? selectedActingBodyId : undefined,
            beginIn: beginIn ?? undefined,
            endIn: endIn ?? undefined
        });

        navigation.setParams({
            priority: selectedPriorityFilter !== 'all' ? selectedPriorityFilter : undefined,
            triggerType: selectedTriggerTypeFilter !== 'all' ? selectedTriggerTypeFilter : undefined,
            occurrenceTypeId: selectedOccurrenceTypeFilter !== 0 ? selectedOccurrenceTypeFilter : undefined,
            tags: selectedTagsIds,
            actingBodyId: selectedActingBodyId !== 0 ? selectedActingBodyId : undefined,
            beginIn: beginIn ?? undefined,
            endIn: endIn ?? undefined
        });

    };

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <TypeDropdown value={selectedTriggerTypeFilter} setValue={setSelectedTriggerTypeFilter} zIndex={8} />
            <PriorityDropdown value={selectedPriorityFilter} setValue={setSelectedPriorityFilter} zIndex={7} />
            <OccurrenceTypeDropdown value={selectedOccurrenceTypeFilter} setValue={setSelectedOccurrenceTypeFilter} zIndex={5} />
            <TagsDropdown value={selectedTagsIds} setValue={setSelectedTagsIds} zIndex={3} />
            <FilterDateInput
                label={translate('beginIn')}
                valueDate={beginInDateFilter}
                valueHour={beginInHourFilter}
                onChangeDate={setBeginInDateFilter}
                onChangeHour={setBeginInHourFilter}
            />
            <FilterDateInput label={translate('endIn')}
                valueDate={endInDateFilter}
                valueHour={endInHourFilter}
                onChangeDate={setEndInDateFilter}
                onChangeHour={setEndInHourFilter}
            />
            {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_occurrences) &&
                <ActingBodiesDropdown value={selectedActingBodyId} setValue={setSelectedActingBodyId} zIndex={2} />
            }
        </Filters>
    );
}

export default EventsFilter;
