import moment from 'moment';
import { BaseService, GenericPaginatedParameters, Unauthorized, UnexpectedError } from './base-service';
import { PaginatedResource } from '../../typings/Paginated';

export interface GetLprDetectionsParameters extends GenericPaginatedParameters {
    plate?: string;
    cameras?: string[];
    irregularSituation: boolean;
    isMotorcycle: boolean;
    beggingIn?: string;
    endIn?: string;
}

interface CreateReportParameters {
    name: string;
    startDate: string;
    endDate: string;
    plate?: string;
    camerasIds?: string[];
}

interface GetPaginatedPlatesParameters extends GenericPaginatedParameters {
    onlyActive: boolean;
}

class LicensePlateRecognitionService extends BaseService {
    async getPlates(filters: GetPaginatedPlatesParameters): Promise<PaginatedResource<PlateData>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/license-plate-recognition/plates${this.encodeQueryParams({ ...filters })}`, {
            method: 'GET',
            headers: this.getHeaders(token)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getCameras(): Promise<LprSimplifiedCamera[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/license-plate-recognition/cameras`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getDetections({ page, plate, limit, cameras, irregularSituation, isMotorcycle, beggingIn, endIn }: GetLprDetectionsParameters): Promise<LprDetection[]> {
        const token = await this.getToken();

        if (!page) {
            page = 0;
        }

        if (!limit) {
            limit = 10;
        }

        let cameraJoin = '';

        if (cameras) {
            cameraJoin = cameras.join();
        }

        const res = await fetch(`${this.centralEndpoint}/license-plate-recognition/detections${this.encodeQueryParams({
            page,
            plate,
            limit,
            cameras: cameraJoin,
            irregularSituation,
            isMotorcycle,
            beggingIn: beggingIn ? moment(beggingIn).valueOf() : undefined,
            endIn: endIn ? moment(endIn).valueOf() : undefined,
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getLprDetection(id: number): Promise<LprDetection> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/license-plate-recognition/detection/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async editLprDetection(id: number, plate: string): Promise<LprDetection> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/license-plate-recognition/detection/${id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ plate }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createPlate({ plate, description, isActive }: { plate: string; description: string; isActive: boolean; }) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/license-plate-recognition/plate`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ plate, description, isActive }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

    }

    async updatePlate(id: number, { description, isActive }: { description: string; isActive: boolean; }) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/license-plate-recognition/plate/${id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ description, isActive }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

    }

    async deletePlate(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/license-plate-recognition/plate/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async createReport(params: CreateReportParameters) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/license-plate-recognition/report`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getReports(params: GenericPaginatedParameters): Promise<PaginatedResource<Report>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/license-plate-recognition/reports/paginated${this.encodeQueryParams({
            ...params,
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getReport(id: number): Promise<{ resultUrl: string; }> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/license-plate-recognition/report/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

}

export const licensePlateRecognitionService = new LicensePlateRecognitionService();
